import React, { Component } from 'react';
import { connect } from 'react-redux';
import BorderlessButton from '../../lib/borderless-button/borderless-button';
import Button from '../../lib/button/button';
import Input from '../../lib/input/input';
import { signIn } from '../../services/authService';
import { ROUTE_FORGOT_PASSWORD, ROUTE_SIGN_UP } from '../../utils/RouteUtils';
import Logo from '../../assets/Tolapp-logo-1.png'
import './page-sign-in.css';


export class SignInPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			errors: {},
			isLoading: false,
			changePassword: true,
			focusOnErrorInput: false
		}

		this.onChange = this.onChange.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
		this.onSignIn = this.onSignIn.bind(this)
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value })
	}

	onSubmit(e) {
		e.preventDefault()
		this.onSignIn()
	}

	onSignIn() {
		this.setState({
			isLoading: true,
			errors: {}
		})
		signIn(this.state)
			.catch((errors) => {
				this.setState({
					isLoading: false,
					errors: errors,
					focusOnErrorInput: true
				})
			})
	}

	componentDidUpdate() {
		let { errors, focusOnErrorInput } = this.state
		if (!focusOnErrorInput || !errors.input) return
		if (errors.input.password) this.setState({ password: '' })
		this.setState({ focusOnErrorInput: false })
	}

	render() {

		const { email, password, errors, isLoading, changePassword } = this.state

		const emailError = (errors.input && errors.input.email)
		const passwordError = (errors.input && errors.input.password)

		return (
			<div className="signInForm">

				<form onSubmit={this.onSubmit}>
					<img src={Logo} style={{ height: '140px', objectFit: 'contain', marginBottom: '20px', marginLeft: '-12px' }} />
					<Input
						type="text"
						name="email"
						placeholder="Sähköposti"
						value={email}
						onChange={this.onChange}
						autoFocus
						errorMessage={emailError}/>
					<Input
						type="password"
						name="password"
						value={password}
						onChange={this.onChange}
						placeholder="Salasana"
						errorMessage={passwordError}/>
					<Button
						id="signInBtn"
						title="Kirjaudu sisään"
						minWidth="100%"
						disabled={isLoading}
						onClick={this.onSignIn}
						submit
					/>
					<BorderlessButton
						id="forgotPasswordBtn"
						title="Unohtuiko salasana?"
						type={BorderlessButton.Type.PRIMARY}
						shadowType={BorderlessButton.ShadowType.NONE}
						tier={4}
						bold={false}
						onClick={() => this.props.history.push(ROUTE_FORGOT_PASSWORD) }
						disabled={isLoading}
					/><br/>
					<BorderlessButton
						id="signUpBtn"
						title="Rekisteröidy"
						type={BorderlessButton.Type.PRIMARY}
						shadowType={BorderlessButton.ShadowType.NONE}
						tier={4}
						bold={false}
						onClick={() => this.props.history.push(ROUTE_SIGN_UP) }
						disabled={isLoading}
					/>
				</form>
			</div>
		)
  	}

}

export default connect(null)(SignInPage)

import $ from 'jquery'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as AngleDownIcon } from '../../assets/angle-down.svg'
import { ReactComponent as EditIcon } from '../../assets/edit.svg'
import { ReactComponent as MinusIcon } from '../../assets/minus.svg'
import { ReactComponent as TrashAltIcon } from '../../assets/trash.svg'
import { COLOR_INDIGO } from '../../constants'
import BorderlessButton from '../../lib/borderless-button/borderless-button'
import Button from '../../lib/button/button'
import { dateToString } from '../../lib/Helper'
import LoadingSpinner from '../../lib/loading-spinner/loading-spinner'

const InfoView = ({point, locPointData, authorInfo, removerInfo, model, showRemoved, showDeleteDialog, onRestorePoint, isLoading, onEdit}) => {
	const [isCollapsed, setCollapsed] = useState(true);

	if (!locPointData) {
		return (
			<div>
				<h5>{point.title || model.name}</h5>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px', paddingLeft: '15px'}}>
					<LoadingSpinner small/>
				</div>
			</div>
		)
	}

	const Item = ({title, value}) => {
		return (
			<div style={{marginTop: '8px'}}>
				<p style={{color: '#989898', fontSize: '0.9rem', marginBottom: '2px'}}>{title}</p>
				<p style={{fontWeight: '400'}}>{value}</p>
			</div>
		)
	}

	return (
		<div style={{minWidth: '200px', marginTop: '12px'}}>
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<h5>{point.title || model.name}</h5>
				{ !showRemoved &&
					<div>
						<BorderlessButton
							style={{marginRight: '12px', color: COLOR_INDIGO}}
							icon={<EditIcon className="icon-medium" />}
							onClick={onEdit}
							tier={3}
							shadowType={BorderlessButton.ShadowType.NONE}
							disabled={isLoading}
						/>
						<BorderlessButton
							style={{color: COLOR_INDIGO}}
							icon={<TrashAltIcon className="icon-medium" />}
							onClick={showDeleteDialog}
							tier={3}
							shadowType={BorderlessButton.ShadowType.NONE}
							disabled={isLoading}
						/>
					</div>
				}
			</div>
			<Item title="Luotu" value={dateToString(new Date(point.dateCreated))}/>
			{(showRemoved && point.dateRemoved) ? <Item title="Poistettu" value={dateToString(new Date(point.dateRemoved))}/> : null}
			<div style={{marginTop: '8px'}}>
				<p style={{color: '#989898', fontSize: '0.9rem', marginBottom: '2px'}}>Luonut</p>
				{ authorInfo &&
					<div>
						<div style={{display: 'flex', alignItems: 'center'}}>
							<p style={{fontWeight: '400', margin: 0}}>{authorInfo.firstName + " " + authorInfo.lastName}</p>
							<BorderlessButton
								style={{marginLeft: '16px', marginBottom: '6px'}}
								icon={ isCollapsed ? <AngleDownIcon className="icon-small" /> : <MinusIcon className="icon-small" />}
								onClick={() => {
									if (isCollapsed) $('#author-additional-info').slideDown()
									else $('#author-additional-info').slideUp()
									setCollapsed(!isCollapsed)
								}}
								tier={3}
								shadowType={BorderlessButton.ShadowType.NONE}
							/>
						</div>
						<div id="author-additional-info" style={{display: 'none'}}>
							<p style={{fontWeight: '400'}}>{authorInfo.email}</p>
							<p style={{fontWeight: '400'}}>{authorInfo.phone}</p>
						</div>
					</div>
				}
				{ removerInfo &&
					<span>
						<p style={{color: '#989898', fontSize: '0.9rem', marginBottom: '2px', marginTop: '6px'}}>Poistanut</p>
						<div>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<p style={{fontWeight: '400', margin: 0}}>{removerInfo.firstName + " " + removerInfo.lastName}</p>
								<BorderlessButton
									style={{marginLeft: '16px', marginBottom: '6px'}}
									icon={ isCollapsed ? <AngleDownIcon className="icon-small" /> : <MinusIcon className="icon-small" />}
									onClick={() => {
										if (isCollapsed) $('#remover-additional-info').slideDown()
										else $('#remover-additional-info').slideUp()
										setCollapsed(!isCollapsed)
									}}
									tier={3}
									shadowType={BorderlessButton.ShadowType.NONE}
								/>
							</div>
							<div id="remover-additional-info" style={{display: 'none'}}>
								<p style={{fontWeight: '400'}}>{removerInfo.email}</p>
								<p style={{fontWeight: '400'}}>{removerInfo.phone}</p>
							</div>
						</div>
					</span>
				}
			</div>
			{	model.fields.map(field => {
					const value = locPointData.data ? locPointData.data[field.id] : undefined
					if (value != null) return <Item key={field.id} title={field.title} value={value}/>
					else return null
				})
			}
			{ locPointData.description && // NOTE: There are types in model (console.log(model) to see) --- lisätiedot are also there! Do corrections if needed
				<Item title="Lisätietoja" value={locPointData.description}/>
			}

			{ locPointData.images &&
				<ImagesView
					images={Object.keys(locPointData.images)}
				/>
			}

			{(showRemoved) &&
				<Button
					title="Palauta"
					onClick={onRestorePoint}
					disabled={isLoading}
				/>
			}
		</div>
	)
}

export default InfoView


const ImagesView = ({images}) => {
	const project = useSelector(state => state.selectedProject)
	const [imageUrls, setImageUrls] = useState([])

	// Runs only once
	useEffect(() => {
		Promise.all(images.map(id => project.getFileDownloadUrl(id)))
			.then(setImageUrls)
	}, [])

	return (
		<div>
			<p style={{color: '#989898', fontSize: '0.9rem', marginBottom: '2px'}}>Kuvat</p>
			{ imageUrls.map((url, index) =>
				<a key={index} style={{paddingRight: '4px'}}
					href={url}
					target="_blank"
				>
					{`Kuva ${index + 1}`}
				</a>
			)}
		</div>
	)
}

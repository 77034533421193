import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCompany } from '../../actions/appStateActions';
import { setCompanies } from '../../actions/dataActions';
import { ReactComponent as LockAltIcon } from '../../assets/lock-alt.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { showMessageDialog } from '../../lib/dialog-generator/dialog-generator';
import { sortByPropertysProperty } from '../../lib/Helper';
import LoadingSpinner from '../../lib/loading-spinner';
import SelectableCard from '../../lib/selectable-card';
import { ROUTE_NEW_COMPANY } from '../../utils/RouteUtils';

class CompanySelectContainer extends Component {

	constructor(props) {
		super(props)

		this.state = {
			companiesLoaded: false
		}

		this.onSelect =	this.onSelect.bind(this)
		this.newCompany = this.newCompany.bind(this)

		this.loadCompanies()
	}

	loadCompanies() {
		const { authUser, setCompanies } = this.props

		authUser.getCompanies()
			.then((companies) => {
				sortByPropertysProperty(companies, 'info', 'name')
				setCompanies(companies)
				this.setState({ companiesLoaded: true })
			})
			.catch(() => {
				showMessageDialog(null, "Jotain meni vikaan, yritä myöhemmin uudelleen.", "OK")
			})
	}

	onSelect(businessId) {
		var selectedCompany
		for (var company of this.props.companies) {
			if (company.info.businessId === businessId) {
				selectedCompany = company
				break
			}
		}
		this.props.setCompany(selectedCompany)
	}

	newCompany() {
		this.props.setCompany(null)
		this.props.history.push(ROUTE_NEW_COMPANY)
	}

	render() {
		const { admin, companies } = this.props
		const companyCards = []
		companies.forEach(company => {
			companyCards.push(
				<SelectableCard key={company.info.businessId} id={company.info.businessId} text={company.info.name} selected={this.props.selectedCompany ? this.props.selectedCompany.businessId === company.info.businessId : false} rightIcon={company.rules.locked && <LockAltIcon className="icon-medium" />} onSelect={this.onSelect}/>
			)
		})

		if (this.state.companiesLoaded) {
			return (
				<div id="company-select">
					<div className="companies">
						{ companyCards }
						<SelectableCard
							leftIcon={<PlusIcon className="icon-small" />}
							text={"Uusi Yritys"}
							highlighted={true}
							onSelect={this.newCompany}
							selected={this.props.history.location.pathname === ROUTE_NEW_COMPANY}
						/>
					</div>
				</div>
			)
		} else {
			return <LoadingSpinner/>
		}

	}

}

function mapStateToProps(state) {
	return {
		authUser: state.auth.user,
		stateType: state.state,
		selectedCompany: state.selectedCompany,
		admin: state.admin,
		companies: state.companies,
	}
}

export default withRouter(connect(mapStateToProps, { setCompanies, setCompany })(CompanySelectContainer))

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from '../../lib/container';
import { guid, trimStartAndEnd } from '../../lib/Helper';
import Switch from "../../lib/switch";
import Button from "../../lib/button";
import SelectableCard from "../../lib/selectable-card";
import SegmentedControls from "../../lib/segmented-controls";
import LocationPointType from "../../models/LocationPointType";
import './container-edit-location-point-type-field.css';

export default class EditLocationPointTypeFieldContainer extends Component {

    constructor(props) {
        super(props)

        const field = props.locationPointTypeField ? props.locationPointTypeField : LocationPointType.newField()
        this.state = {
            id: field.id,
            title: field.title,
            dataType: field.dataType,
            required: field.required,
            type: field.type,
            editing: props.locationPointTypeField ? true : false,
            errorTitleRequired: false,
        }
        
        this.daysTillOldInput = null
        
        this.saveField = this.saveField.bind(this)
        this.onChange = this.onChange.bind(this);
        this.resetFieldEdit = this.resetFieldEdit.bind(this);
        this.editingCancelled = this.editingCancelled.bind(this);
	}

    componentWillReceiveProps(props) {
        if (props.locationPointTypeField) {
            const field = props.locationPointTypeField
            this.setState({
                id: field.id,
                title: field.title,
                dataType: field.dataType,
                required: field.required,
                type: field.type,
                editing: true,
                errorTitleRequired: false
            })
        } else this.resetFieldEdit()
    }

    editingCancelled() {
        this.props.onCancel();
        this.resetFieldEdit()
    }

    resetFieldEdit() {
        const field = LocationPointType.newField()
        this.setState({
            id: field.id,
            title: field.title,
            dataType: field.dataType,
            required: field.required,
            type: field.type,
            editing: false,
            errorTitleRequired: false
        })
    }

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	} 

    saveField() {
        const { id, title, dataType, required, type } = this.state
        
        const trimmedTitle = trimStartAndEnd(title)
        if (!trimmedTitle) {
            return this.setState({errorTitleRequired: true});
        }
        const locationPointTypeField = new LocationPointType.Field(id ? id : guid(), title, dataType, required, type)
        this.props.onSave(locationPointTypeField)
        this.resetFieldEdit()
    }

	render() {

        const { id, title, dataType, required, type, errorTitleRequired, editing } = this.state
        
		return (
            <Container id="newLocationPointTypeFieldsCard" title={editing ? "Muokkaa kenttää" : "Uusi Kenttä"} tier={3} small={true}>
                <div className="form-group">
                    <label htmlFor="newLocationPointTypeFieldName">Kentän nimi</label>
                    <input
                        id="newLocationPointTypeFieldName"
                        name="title"
                        onChange={this.onChange}
                        value={title}
                        className={classnames("form-control", {"is-invalid": errorTitleRequired})}
                        type="text"
                        autoComplete="off"/>
                </div>

                <center>
                    <SegmentedControls
                        ids={[LocationPointType.Field.DataType.STRING, LocationPointType.Field.DataType.NUMBER]}
                        selectedComponent={dataType}
                        names={["Teksti", "Numero"]}
                        width="90%"
                        onChange={(dataType)=>{this.setState({dataType})}} />
                </center>

                <Switch
                    label="Vaaditaan"
                    selected={required}
                    onChange={(required) => {this.setState({required})}}
                    />

                <Button title={editing ? "Tallenna kenttä" : "Luo kenttä"} float="right" onClick={this.saveField}/>
                <Button title="Peruuta" type={Button.Type.SECONDARY} float="right" onClick={this.editingCancelled} marginEnd="8px" hidden={!editing}/>

            </Container>
        )
        
	}

    static propTypes = {
        locationPointTypeField: PropTypes.object,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    }

}
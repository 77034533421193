import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BorderlessButton from '../../lib/borderless-button';
import { signOut } from '../../services/authService';
import Input from '../../lib/input/input';
import Button from '../../lib/button/button';
import './page-join-company.css'
import {setCurrentUser} from '../../actions/authActions'
import { cloneDeep } from 'lodash'


export class JoinCompanyPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			businessId: '',
			password: '',
			isLoading: false,
			errors: this.noErrorsObj()
		}

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.joinCompany = this.joinCompany.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value })
	}

	onSubmit(e) {
		e.preventDefault()
		this.joinCompany()
	}

	joinCompany() {
		this.setState({
			isLoading: true,
			errors: this.noErrorsObj()
		})
		const { businessId, password } = this.state
		const user = cloneDeep(this.props.user)

		user.joinCompany(businessId, password)
			.then(() => {
				user.loadCompany()
					.then(() => {
						this.props.setCurrentUser(user)
					})
					.catch(signOut)
			})
			.catch(errors => {
				this.setState({
					isLoading: false,
					errors: errors
				})
			})
	}

	noErrorsObj() {
		return {
			businessId: null,
			password: null,
			somethingWentWrong: false
		}
	}

	render() {

		const { businessId, password, isLoading, errors } = this.state;

		return (
			<form id="joinCompanyForm" onSubmit={this.onSubmit}>
				<h2 id="joinTitle">Mihin yritykseen kuulut?</h2>

				<Input
					placeholder="Y-tunnus"
					name="businessId"
					regex="^([0-9]|-)*$"
					type="text"
					value={businessId}
					onChange={this.onChange}
					autoFocus
					autoComplete="off"
					errorMessage={errors.businessId}
					disabled={isLoading}/>
				<Input
					placeholder="Salasana"
					name="password"
					value={password}
					onChange={this.onChange}
					autoComplete="off"
					errorMessage={errors.password}
					disabled={isLoading}
					type="password"/>
					{ errors.somethingWentWrong && (
						<p id="errorMessage">Jotain meni vikaan, yritä myöhemmin uudelleen.</p>
					)}
				<Button
					title="Liity yritykseen"
					minWidth="100%"
					onClick={this.joinCompany}
					disabled={isLoading}
					submit
				/>
				<BorderlessButton
					id="signOutBtn"
					title="Kirjaudu Ulos"
					type={BorderlessButton.Type.PRIMARY}
					shadowType={BorderlessButton.ShadowType.NONE}
					tier={4}
					bold={false}
					onClick={signOut}
					disabled={isLoading}
				/>
			</form>
		)
  	}

}

function mapStateToProps(state) {
	return {
		user: state.auth.user
	}
}

export default connect(mapStateToProps, { setCurrentUser })(JoinCompanyPage);
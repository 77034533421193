import { combineReducers } from 'redux';
import auth from './auth';
import selectedCompany from './selectedCompany';
import selectedProject from './selectedProject';
import userInfos from './userInfos';
import companyInfos from './companyInfos';
import companies from './companiesReducer';

export default combineReducers({
   auth,
   selectedProject,
   selectedCompany,
   userInfos,
   companyInfos,
   companies,
})
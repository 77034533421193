import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signOut } from '../../services/authService';
import Button from '../../lib/button';
import Project from '../../models/Project';
import { showMessageDialog, showConfirmDialog } from '../../lib/dialog-generator/dialog-generator';
import './navbar.css';
import { ROUTE_JOIN_COMPANY } from '../../utils/RouteUtils';


class NavBar extends Component {

    constructor(props) {
        super(props)
        this.leaveCompany = this.leaveCompany.bind(this)
    }

	leaveCompany() {
		showConfirmDialog(null,
			"Haluatko varmasti vaihtaa yritystä?",
			"Vaihda",
			"Peruuta",
			() => {
                this.props.user.leaveCompany()
                    .then(() => {
                        this.props.history.push(ROUTE_JOIN_COMPANY)
                    })
                    .catch((err) => {
                        console.error(err)
                        showMessageDialog(null, "Jotain meni vikaan, yritä myöhemmin uudelleen", "OK")
                    })
			}
		)

	}

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark justify-content-between">
                <a className="navbar-brand">Tolapp Hallintapaneeli</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item"></li>
                    </ul>
                    <form className="navbar-buttons">
                        { !this.props.user.isAdministrator && (<Button title="Vaihda Yritystä" marginEnd="16px" noMargin onClick={this.leaveCompany}/>) }
                        <Button title="Kirjaudu Ulos" noMargin onClick={signOut}/>
                    </form>
                </div>
            </nav>
        )
    }

}

function mapStateToProps(state) {
	return {
		user: state.auth.user
	}
}

export default withRouter(connect(mapStateToProps)(NavBar))
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SelectableCard from '../../lib/selectable-card';
import PropTypes from 'prop-types';
import Container from '../../lib/container';
import { setCompany } from '../../actions/appStateActions';
import { setCompanies } from '../../actions/dataActions';
import LoadingSpinner from '../../lib/loading-spinner';
import { guid, trimStartAndEnd } from '../../lib/Helper';
import Button from '../../lib/button';
import LocationPointType from '../../models/LocationPointType';
import Project from '../../models/Project';
import Company from '../../models/Company';
import Input from '../../lib/input/input';
import Switch from '../../lib/switch';
import './container-create-company.css';
import { showMessageDialog } from '../../lib/dialog-generator/dialog-generator';
import sha1 from "sha1";

class CreateCompanyContainer extends Component {

	constructor(props) {
		super(props)

		const { company } = props

		this.state = {
			businessId: company ? company.info.businessId : '',
			name: company ? company.info.name : '',
			oldPwd: '',
			newPwd: '',
			pwdConfirm: '',
			loading: false,
			errors: {
				invalidBusinessId: false,
				oldPasswordRequired: false,
				wrongPassword: false,
				nameRequired: false,
				weakPassword: false,
				passwordsDoNotMatch: false,
				identicalPasswords: false,
			},
		}

		this.rules = company ? company.rules : new Company.Rules(false, true, true)

		this.saveCompany = this.saveCompany.bind(this)
		this.onChange = this.onChange.bind(this)
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
    }


	saveCompany() {
		const { businessId, name, rules, oldPwd, newPwd, pwdConfirm } = this.state
		const { authUser, company, setState } = this.props
		var error = false
		const errors = {
			invalidBusinessId: false,
			nameRequired: false,
			weakPassword: false,
			passwordsDoNotMatch: false,
			oldPasswordRequired: false,
			wrongPassword: false,
			identicalPasswords: false
		}
		const trimmedBusinessId = trimStartAndEnd(businessId)

		if (!trimmedBusinessId.match("^(^[0-9]{7})-([0-9])$")) {
			errors.invalidBusinessId = true
			error = true
		}
		const trimmedName = trimStartAndEnd(name)
		if (!trimmedName) {
			errors.nameRequired = true
			error = true
		}

		if (((oldPwd || newPwd || pwdConfirm) && company) || !company) {
			if (newPwd.length < 8) {
				errors.weakPassword = true
				error = true
			}
			if (newPwd !== pwdConfirm) {
				errors.passwordsDoNotMatch = true
				error = true
			}
			if (company && !authUser.isAdministrator) {
				if (!oldPwd) {
					errors.oldPasswordRequired = true
					error = true
				} else if (oldPwd === newPwd) {
					errors.identicalPasswords = true
					error = true
				}
			}
		}

		this.setState({errors})
		if (error) return
		this.setState({loading: true})

		if (company) {
			company.editCompany(trimmedName, oldPwd, newPwd, authUser.isAdministrator ? this.rules : null).then(() => {
				this.setState({loading: false})
				/* for (c of companies) {
					if (c.info.businessId === company.info.businessId) {
						c.info.name = trimmedName
						break
					}
				}*/
				company.info.name = trimmedName
				company.rules = this.rules
				this.props.history.push('/')
			}).catch((errors) => {
				this.setState({loading: false})
				if (!errors) showMessageDialog(null, "Jotain meni vikaan, yritä myöhemmin uudelleen.", "OK")
				else this.setState({errors: { wrongPassword: !!errors.wrongPassword }})
			})
		} else {
			Company.createCompany(businessId, trimmedName, newPwd, this.rules, (company) => {
				this.setState({loading: false})
				if (company) {
					const { setCompanies, companies } = this.props
					const _companies = [...companies]
					_companies.push(company)
					setCompanies(_companies)
					this.props.setCompany(company)
					this.props.history.push('/')
				} else {
					showMessageDialog(null, "Jotain meni vikaan, yritä myöhemmin uudelleen.", "OK")
				}
			})
		}
	}

	render() {
		const { businessId, name, newPwd, oldPwd, pwdConfirm, errors, loading } = this.state
		const { company, authUser } = this.props

		return (
			<Container className='new-company-container' tier={2}>
				{ !company && (
					<Input
						title="Y-Tunnus"
						name="businessId"
						value={businessId}
						onChange={this.onChange}
						error={errors.invalidBusinessId}
						errorMessage={errors.invalidBusinessId ? "Tarkista Y-tunnus" : null}
						disabled={loading}
						placeholder="1234567-0"
					/>
				)}
				<Input
					title="Nimi"
					name="name"
					value={name}
					onChange={this.onChange}
					error={errors.nameRequired}
					errorMessage={errors.nameRequired ? "Nimi vaaditaan" : null}
					disabled={loading}
				/>
				<br/>
				{ company ? (
					<Container title="Vaihda salasana" tier={3}>
						{ !authUser.isAdministrator &&
                     <Input
                        title="Vanha Salasana"
                        name="oldPwd"
                        value={oldPwd}
                        type="password"
                        onChange={this.onChange}
                        errorMessage={errors.oldPasswordRequired ? "Syötä vanha salasana" : (errors.wrongPassword ? "Väärä salasana" : null)}
                        disabled={loading}
                     />
                  }
						<Input
							title={ company ? "Uusi Salasana" : "Salasana" }
							name="newPwd"
							value={newPwd}
							type="password"
							onChange={this.onChange}
							errorMessage={errors.weakPassword ? "Väh 8 merkkiä" :  (errors.identicalPasswords ? "Salasana ei voi olla sama" : null)}
							disabled={loading}
						/>
						<Input
							title="Vahvista Salasana"
							name="pwdConfirm"
							type="password"
							value={pwdConfirm}
							onChange={this.onChange}
							errorMessage={errors.passwordsDoNotMatch ? "Salasanat eivät täsmää" : null}
							disabled={loading}
						/>
					</Container>
				) : (
					<span>
						<Input
							title={ company ? "Uusi Salasana" : "Salasana" }
							name="newPwd"
							value={newPwd}
							type="password"
							autoComplete="new-password"
							onChange={this.onChange}
							errorMessage={errors.weakPassword ? "Väh 8 merkkiä" : null}
							disabled={loading}
							// large bold
						/>
						<Input
							title="Vahvista Salasana"
							name="pwdConfirm"
							type="password"
							autoComplete="new-password"
							value={pwdConfirm}
							onChange={this.onChange}
							errorMessage={errors.passwordsDoNotMatch ? "Salasanat eivät täsmää" : null}
							disabled={loading}
							// large bold
						/>
					</span>
				)}
				{ authUser.isAdministrator && (<br/>) }
				{ authUser.isAdministrator && (
					<Container title="Oikeudet" tier={3}>
						{ company && (
							<Switch
								label="Ohjelmiston käyttöoikeus"
								selected={!this.rules.locked}
								onChange={(selected) => {this.rules.locked = !selected}}
								disabled={loading}
							/>
						)}
						<Switch
							label="Hallintapaneelin käyttö"
							selected={this.rules.controlPanelAccess}
							onChange={(selected) => {this.rules.controlPanelAccess = selected}}
							disabled={loading}
						/>
						<Switch
							label="Uusien projektien luonti"
							selected={this.rules.createProject}
							onChange={(selected) => {this.rules.createProject = selected}}
							disabled={loading}
						/>
					</Container>
				)}

				<br/>
				<Button
					title={company ? "Tallenna" : "Luo Yritys"}
					// type={Button.Type.SUCCESS}
					float="right"
					minWidth="30%"
					onClick={this.saveCompany}
					disabled={loading}/>
			</Container>
		)
	}

}

function mapStateToProps(state) {
	return {
		authUser: state.auth.user,
		admin: state.admin,
		company: state.selectedCompany,
		companies: state.companies,
	}
}

export default withRouter(connect(mapStateToProps, { setCompany, setCompanies })(CreateCompanyContainer))

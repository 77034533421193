import { fService } from "../services/firebaseService"

export default class File {

	constructor(id, name, downloadUrl = undefined) {
		this.id = id
		this.name = name
		this.url = downloadUrl
	}

	loadData(projectId) {
		return new Promise((resolve, reject) => {
			if (this.url) return resolve()
			fService.SREF_BASE.child(projectId).child('files').child(this.id).getDownloadURL().then((url) => {
				this.url = url
				resolve()
			}).catch(e => resolve())
		})
	}

}
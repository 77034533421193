import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setProject } from '../../actions/appStateActions';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import LoadingSpinner from '../../lib/loading-spinner';
import SelectableCard from '../../lib/selectable-card';
import { ROUTE_NEW_PROJECT } from '../../utils/RouteUtils';
import './container-project-select.css';


class ProjectSelectContainer extends Component {

	static propTypes = {
		projectChangeAllowed: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.state = {
			projectsLoaded: false
		}

		if (props.currentUser.isAdministrator) {
			/* props.selectedCompany.getAnalyticProjects().then(() => {
				this.setState({projectsLoaded: true})
			}) */
			props.selectedCompany.loadProjectsInfo().then(() => {
				if (!this.mounted) return
				this.setState({projectsLoaded: true})
			})
		} else {
			props.currentUser.company.loadProjectsInfo().then(() => {
				if (!this.mounted) return
				this.setState({projectsLoaded: true})
			})
		}

		this.mounted = true

		this.onSelect = this.onSelect.bind(this)
	}

	newProject = () => {
		this.props.setProject(null)
		this.props.history.push(ROUTE_NEW_PROJECT)
	}

	onSelect(id) {
		const { selectedCompany } = this.props
		// const { isAdministrator } = this.props.currentUser

		/* if (isAdministrator) {
			const { analyticProjects } = this.props.selectedCompany
			for (const project of analyticProjects) {
				if (project.id == id) return this.props.onSelectProject(project)
			}
		} else { */
			const { ownProjects, otherProjects } = selectedCompany

			for (const project of ownProjects) {
				if (project.id === id) {
					this.props.history.push('/')
					return this.props.setProject(project)
				}
			}
			for (const project of otherProjects) {
				if (project.id === id) {
					this.props.history.push('/')
					return this.props.setProject(project)
				}
			}
		// }
	}

	componentWillUnmount() {
		this.mounted = false
	}

	render() {
		const analyticProjectCards = []
		const removedAnalyticProjectCards = []
		const ownProjectCards = []
		const otherProjectCards = []

		const { currentUser, selectedCompany, projectChangeAllowed, stateType } = this.props
		const { projectsLoaded } = this.state

		/* if (currentUser.isAdministrator) {
			const { analyticProjects } = selectedCompany
			for (const project of selectedCompany.analyticProjects) {
				if (!project.removed) {analyticProjectCards.push(
					<SelectableCard
						key={project.id}
						id={project.id}
						text={project.name ? project.name : project.info.name}
						selected={this.props.selectedProject ? this.props.selectedProject.id === project.id : false}
						onSelect={this.onSelect}/>
				)} else {removedAnalyticProjectCards.push(
					<SelectableCard
						key={project.id}
						id={project.id}
						text={project.name}
						selected={this.props.selectedProject ? this.props.selectedProject.id === project.id : false}
						onSelect={this.onSelect}/>
				)}
			}
		} else { */

			if (!selectedCompany) return null
			const { ownProjects, otherProjects } = selectedCompany

			for (const project of ownProjects) {
				ownProjectCards.push(
					<SelectableCard
						key={project.id}
						id={project.id}
						text={project.name ? project.name : project.info.name}
						selected={this.props.selectedProject ? this.props.selectedProject.id === project.id : false}
						onSelect={id =>{ projectChangeAllowed(() => { this.onSelect(id) })} }
					/>
				)
			}
			for (const project of otherProjects) {
				otherProjectCards.push(
					<SelectableCard
						key={project.id}
						id={project.id}
						text={project.name ? project.name : project.info.name}
						selected={this.props.selectedProject ? this.props.selectedProject.id === project.id : false}
						onSelect={id => { projectChangeAllowed(() => { this.onSelect(id) })} }
					/>
				)
			}
		// }

		if (projectsLoaded) {
			return (
				<div id="project-select">
					{
						<div className="projects">
							{ ownProjectCards.length !== 0 ? <p className="mini-title">Omat</p> : null }
							{ ownProjectCards.map(component => component) }
							{(currentUser.isAdministrator || currentUser.company.rules.createProject) && (
								<SelectableCard
									key={"newProject"}
									leftIcon={<PlusIcon className="icon-small" />}
									text={"Uusi Projekti"}
									highlighted={true}
									onSelect={() => { projectChangeAllowed(this.newProject) }}
									selected={this.props.history.location.pathname === ROUTE_NEW_PROJECT}
								/>
							)}
							{ otherProjectCards.length !== 0 && <br/> }
							{ otherProjectCards.length !== 0 && <p className="mini-title">Muitten yritysten</p> }
							{ otherProjectCards.map(component => component) }
						</div>
					}
				</div>
			);
		} else {
			return <LoadingSpinner/>
		}


	}

}

function mapStateToProps(state) {
	return {
		stateType: state.state,
		currentUser: state.auth.user,
		selectedCompany: state.selectedCompany,
		selectedProject: state.selectedProject
	}
}

export default withRouter(connect(mapStateToProps, { setProject })(ProjectSelectContainer))

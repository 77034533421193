import { fService } from "../services/firebaseService"
export default class UserInfo {

	constructor(id, firstName, lastName, email, phone, businessId) {
		this.id = id
		this.firstName = firstName
		this.lastName = lastName
		this.email = email
		this.phone = phone
		this.businessId = businessId
	}

	static getInfo(userId) {
		return new Promise((resolve, reject) => {
			fService.FREF_USERS.child(userId).child(fService.PATH_INFO).once('value', snap => {
				const data = snap.val()
				if (!data) return reject("Failed to get user info")
				resolve(new UserInfo(userId, data.first_name, data.last_name, data.email, data.phone, data.business_id))
			});
		});
	}
}
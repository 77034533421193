import React, { InputHTMLAttributes } from 'react';

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  color?: string;
}

const CustomRadio: React.FC<RadioProps> = ({ color = '#ff0000', ...props }) => {
  return (
    <div style={{ position: 'relative'}}>
      <input
        type="radio"
        {...props}
        style={{
          appearance: 'none',
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          width: '20px',
          height: '20px',
          border: '1px solid #333',
          borderRadius: '50%',
          outline: 'none',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      />
      {props.checked && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            backgroundColor: color,
          }}
        />
      )}
    </div>
  );
};

export default CustomRadio;

import classNames from 'classnames';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ReactComponent as AngleDownIcon } from '../../assets/angle-down.svg';
import { ReactComponent as MinusIcon } from '../../assets/minus.svg';
import BorderlessButton from '../borderless-button';
import { guid } from '../Helper';
import './container.css';
import { ReactComponent as CrossIcon } from '../../assets/times.svg'

export default class Container extends Component {

	constructor(props) {
		super(props)
		this.state = {
			collapsed: props.collapsedOnStart
		}
		this.id = props.id ? props.id : guid()
		this.collapseButtonClicked = this.collapseButtonClicked.bind(this)
	}

	collapseButtonClicked() {
		const { onCollapse } = this.props
		const { collapsed } = this.state
		if (collapsed) $(`#${this.id} > .container-body > .container-children`).slideDown()
		else $(`#${this.id} > .container-body > .container-children`).slideUp()
		if (onCollapse) onCollapse(!collapsed)
		this.setState({collapsed: !collapsed})
	}

	render() {
		const { collapsed } = this.state
		const { tier, title, subTitle, onClose, closeIcon, small, className, style, children, maxHeight, collapsedOnStart, collapsable, onCollapse, topButtonIcon, onTopButtonClick } = this.props

		switch (tier) {
			case 1:
				return (
					<div id={this.id} className={classNames('card custom-container', { [className]: className })} style={style} data-no-title={!title} data-tier={tier} data-small={small} data-collapses={collapsable} data-collapsed={collapsable && (collapsed)}>
						<div className={classNames('container-body', { collapsable })}>
							{
							(title || onClose || collapsable) &&
								<div className="custom-container-top-bar"
									onClick={collapsable ? this.collapseButtonClicked : undefined}
								>
									{ onClose &&
										<BorderlessButton
											className="close-btn"
											tier={2}
											icon={closeIcon}
											shadowType={BorderlessButton.ShadowType.TEXT_SHADOW}
											onClick={e => {
												e.stopPropagation()
												onClose()
											}}
										/>
									}
									{title && (title)}
									{ collapsable &&
										<BorderlessButton
											className="collapse-btn"
											icon={collapsed ? <AngleDownIcon className="icon-medium" /> : <MinusIcon className="icon-medium" />}
											tier={1}
											onClick={() => {}}
											shadowType={BorderlessButton.ShadowType.NONE}
										/>
									}
									{ topButtonIcon && (
										<BorderlessButton
											className="top-btn"
											tier={1}
											icon={topButtonIcon}
											shadowType={BorderlessButton.ShadowType.TEXT_SHADOW}
											onClick={e => {
												e.stopPropagation()
												onTopButtonClick()
											}}
										/>
									)}
								</div>
							}
							<div className='container-children'>
								{ children }
							</div>
						</div>
					</div>
				)
			default:
				return (
					<div id={this.id} className={classNames('card custom-container', { [className]: className })} style={style} data-no-title={!title} data-tier={tier} data-small={small} data-collapses={collapsable} data-collapsed={collapsable && (collapsed)}>
						<div className={classNames('container-body', { collapsable })}>
							{ (title || onClose || collapsable) &&
								<div className="custom-container-top-bar"
									onClick={collapsable ? this.collapseButtonClicked : undefined}
								>
									{title && (title)}
									{ onClose &&
										<BorderlessButton
											className="close-btn"
											tier={2}
											icon={closeIcon}
											onClick={e => {
												e.stopPropagation()
												onClose()
											}}
											shadowType={BorderlessButton.ShadowType.TEXT_SHADOW}
										/>
									}
									{ collapsable &&
										<BorderlessButton
											className="collapse-btn"
											icon={ collapsed ? <AngleDownIcon className="icon-medium" /> : <MinusIcon className="icon-medium" />}
											tier={1}
											onClick={() => {}}
											shadowType={BorderlessButton.ShadowType.NONE}
										/>
									}
								</div>
							}
							{ subTitle && <p className="custom-container-sub-title">{subTitle}</p> }
							<div className='container-children'>
								{ children }
							</div>
						</div>
					</div>
				)
		}
	}

	static Collapsable = Object.freeze({
		SM: "sm",
		MD: "md",
		LG: "lg",
		XL: "xl"
	})

	static propTypes = {
		title: PropTypes.string,
		subTitle: PropTypes.string,
		closeIcon: PropTypes.any,
		onClose: PropTypes.func,
		tier: PropTypes.number,
		small: PropTypes.bool,
		maxHeight: PropTypes.string,
		collapsedOnStart: PropTypes.bool,
		collapsable: PropTypes.string,
		onCollapse: PropTypes.func,
		topButtonIcon: PropTypes.any,
		onTopButtonClick: PropTypes.func,
	}

	static defaultProps = {
		tier: 1,
		small: false,
		collapsable: '',
		collapsedOnStart: true,
		closeIcon: <CrossIcon className="icon-medium" />
	}

}

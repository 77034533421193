import * as $ from 'jquery';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as InfoCircleIcon } from '../../assets/info.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import BorderlessButton from '../../lib/borderless-button';
import Container from '../../lib/container';
import Dialog from '../../lib/dialog';
import { getItemByPropertysValue } from '../../lib/Helper';
import LoadingSpinner from '../../lib/loading-spinner';
import SelectableCard from '../../lib/selectable-card/selectable-card';
import LocationPointType from '../../models/LocationPointType';
import './container-project-analytics.css';

export class ProjectAnalyticsContainer extends Component {

	constructor(props) {
		super(props)

		this.state = {
			locationPointTypes: null,
			locationPoints: null,
			removedLocationPoints: null,
			locationPointsData: null,
			removedLocationPointsData: null,
			selectedMonths: [], // List of selectedMonths that are displayed in analytics section
			pointDetailsDialogContent: undefined, /** @type {{ type: string, data: any }} */ //  If data, display moreInfoDialog - Dialog displays (required) number field values e.g. lukumäärä
		}

		this.loadProjectLocationPointTypes()
		this.loadProjectLocationPoints()
		this.loadProjectRemovedLocationPoints()

	}

	/* Location Points and Types load */
	//#region
	loadProjectLocationPointTypes() {
		this.props.project.getLocationPointTypes().then((locationPointTypes) => {
			this.setState({ locationPointTypes })
		}).catch(console.error)
	}

	loadProjectLocationPoints() {
		this.props.project.getLocationPoints().then((locationPoints) => {
			this.setState({ locationPoints })
		}).catch(console.error)
	}

	loadProjectRemovedLocationPoints() {
		this.props.project.getRemovedLocationPoints().then((removedLocationPoints) => {
			this.setState({ removedLocationPoints })
		}).catch(console.error)
	}

	/* Location Points Data load */
	//#region
	loadLocationPointsData() {
		this.props.project.getLocationPointsData().then((locationPointsData) => {
			this.setState({ locationPointsData })
		}).catch(console.error)
	}

	loadRemovedLocationPointsData() {
		this.props.project.getRemovedLocationPointsData().then((removedLocationPointsData) => {
			this.setState({ removedLocationPointsData })
		}).catch(console.error)
	}


	/**
	 * This is copy of the function above. // TODO: When done, remove the other one
	 * @param {string} type
	 *	@param {*} customFields Custom fields
	 * @param {Date} [timeStart] timeframe start
	 * @param {Date} [timeEnd] timeFrame End
	 */
	generateDataAnalyticsForTimeframe(type, customFields, timeStart, timeEnd) {
		const { locationPoints, removedLocationPoints, locationPointsData, removedLocationPointsData } = this.state

		const selectedTypeData = {}

		const locPoints = [...locationPoints, ...removedLocationPoints]
			.filter(locPoint => locPoint.type === type)
			.filter((locPoint) => {
				if (!timeStart && timeEnd) return true // If timeframe is not provided use all data
				if (locPoint.dateCreated > timeEnd || locPoint.dateCreated < timeStart) return false // Skip points that are out of timeframe
				return true
			})
		const locPointsData = {...locationPointsData, ...removedLocationPointsData}

		for (const locPoint of locPoints) {
			const isRemoved = (locPoint.dateRemoved !== undefined)
			const locPointData = (locPointsData[locPoint.id] ? locPointsData[locPoint.id].data : undefined)
			for (let id in customFields[type]) {
				if (!selectedTypeData[id]) selectedTypeData[id] = {
					name: customFields[type][id],
					onMap: 0,
					removed: 0
				}
				if (locPointData !== undefined && locPointData[id] !== undefined) {
					const fieldValue = locPointData[id]
					if (!fieldValue || isNaN(fieldValue)) continue// Filter out corrupted values on undefineds (filters out also zeros, but does not affect the count)
					const numberValue = parseInt(fieldValue)
					if (!isRemoved) selectedTypeData[id].onMap += numberValue
					else selectedTypeData[id].removed += numberValue
				}
			}
		}
		return selectedTypeData
	}


	/**
	 * Show info dialog with info data in timeframe
	 * @param {string} id Data type as string
	 * @param {Date} [timeStart]
	 * @param {Date} [timeEnd]
	 */
	showMoreInfoDialog(id, customFields, timeStart, timeEnd) {
		const fieldDetails = this.generateDataAnalyticsForTimeframe(id, customFields, timeStart, timeEnd)
		this.setState({ pointDetailsDialogContent: { type: id, data: fieldDetails } })
	}


	componentDidMount() {
		this.loadLocationPointsData()
		this.loadRemovedLocationPointsData()
	}

	/**
	 * Create analytics container for month of for all data
	 * @param {Date} [month] First date of the month
	 * @returns *
	 */
	createAnalyticsMonthContainer(month) {

		const { locationPoints, removedLocationPoints, locationPointTypes } = this.state

		const beginDate =  month && new Date(month.getFullYear(), month.getMonth(), 1) // Unnecessary? Use only month. This was copied from similar component in container-month-analytics
		const endDate = beginDate && new Date(beginDate.getFullYear(), beginDate.getMonth() + 1, 0, 23, 59, 59, 999) // End of last day of the month

		let types = {}
		let customFields = {}

		// Generate monthly analytics

		// Get types
		for (const locPointType of locationPointTypes) {
			if (!types[locPointType.id]) {
				types[locPointType.id] = {
					name: locPointType.name,
					onMap: 0,
					removed: 0
				}
			}
		}

		const locPoints = [...locationPoints, ...removedLocationPoints]
		for (const locPoint of locPoints) {

			if (locPoint.dateCreated > endDate || locPoint.dateCreated < beginDate) continue // Skip all loc points that are not in months timeframe

			const isRemoved = (locPoint.dateRemoved !== undefined)

			if (!types[locPoint.type]) { // Copied from generateAnalytics method. Is this necessary???
				let locPointType = getItemByPropertysValue(locationPointTypes, 'id', locPoint.type) // TODO: Tässä jää kiinni
				if (!locPointType) continue
			}
			if (!isRemoved) ++types[locPoint.type].onMap
			else ++types[locPoint.type].removed

			if (!customFields[locPoint.type]) customFields[locPoint.type] = {}
			let locPointType = getItemByPropertysValue(locationPointTypes, 'id', locPoint.type)

			if (!locPointType) continue
			for (const field of locPointType.fields) {
				if (field.required && field.dataType === LocationPointType.Field.DataType.NUMBER) {
					customFields[locPoint.type][field.id] = field.title
				}
			}
		}

		var requiredCustomFields = false
		for (const fieldId in customFields) {
			if (!$.isEmptyObject(customFields[fieldId])) {
				requiredCustomFields = true
				break
			}
		}

		return (
			<table className="table table-striped">
					<thead>
						<tr>
							<th scope="col">Merkki</th>
							<th scope="col" className="center">Kartalla</th>
							<th scope="col" className="center">Poistettu</th>
							<th scope="col" className="center">Yhteensä</th>
							{ requiredCustomFields && <th id="analyticsTableHeadMoreInfo" scope="col"></th> }
						</tr>
					</thead>
					<tbody>
						{
							Object.keys(types).map((id) => {

								const numberInMap = types[id].onMap
								const numberRemoved = types[id].removed

								return <tr key={id} scope="col">
									<th scope="row">{types[id].name}</th>
									<td>{numberInMap}</td>
									<td>{numberRemoved}</td>
									<td>{numberInMap + numberRemoved}</td>
									{ requiredCustomFields && (
										<td>
										{ !$.isEmptyObject(customFields[id]) &&
											<BorderlessButton
												icon={<InfoCircleIcon className="icon-medium" />}
												onClick={() => { this.showMoreInfoDialog(id, customFields, beginDate, endDate) }}
												type={BorderlessButton.Type.PRIMARY}
												tier={2}
												shadowType={BorderlessButton.ShadowType.NONE}
											/>
										}
										</td>
									)}
								</tr>
							})
						}
					</tbody>
				</table>
		)
	}


	render() {

		const { locationPointTypes, locationPoints, removedLocationPoints, pointDetailsDialogContent } = this.state
		const loadingLocationPoints = !locationPointTypes || !locationPoints || !removedLocationPoints

		return (
			<Container className='project-analytics-container' tier={2} title="Analytiikka">
				{ loadingLocationPoints ? (
					<LoadingSpinner />
				) : (
					// All data
					<>
						<h5 style={{ fontWeight: 300 }} >Kaikki</h5>
						{this.createAnalyticsMonthContainer()}
					</>
				)}


				{/* Month containers */}
				{ this.state.selectedMonths.map((month) => {
					return (
						<Container
							className={month && 'month-analytics-container'} // Copied from container-month-analytics
							title={month.toLocaleDateString("fi-FI", { month: 'long', year: 'numeric' })}
							key={`${month || 'details'}-container`}
							onClose={ month ? () => {
								this.setState({
									selectedMonths: this.state.selectedMonths.filter((m) => m.getTime() != month.getTime())
								})
							} : undefined}
							tier={month ? 3 : 2}
						>
						{this.createAnalyticsMonthContainer(month)}
						</Container>
					)
				})}


				{/* Month selector */}
				<SelectableCard
					key={"newAnalytics"}
					type={SelectableCard.Type.MONTH_PICKER}
					dateMin={new Date(2018,0,1)}
					dateMax={new Date()}
					onDateChanged={(date) => {
						this.setState({ selectedMonths: [...this.state.selectedMonths, date] })
					}}
					leftIcon={<PlusIcon className="icon-small" />}
					text="Hae kuukausi"
					tier={2}
				/>

				{/* Details dialog */}
				{ pointDetailsDialogContent && (
					<Dialog
						title={locationPointTypes.find((locPointType) => locPointType.id === pointDetailsDialogContent.type)?.name || ''}
						neutralButtonText="Ok"
						width="600px"
						show={true}
						onClose={() => this.setState({ pointDetailsDialogContent: undefined })}
					>
						<table className="table table-striped">
							<thead>
								<tr>
								<th scope="col">Kenttä</th>
									<th scope="col">Kartalla</th>
									<th scope="col">Poistettu</th>
									<th scope="col">Yhteensä</th>
								</tr>
							</thead>
							<tbody>
								{
									Object.keys(pointDetailsDialogContent.data).map((id) => {
										return <tr key={id} scope="col">
											<th scope="row">{pointDetailsDialogContent.data[id].name}</th>
											<td>{pointDetailsDialogContent.data[id].onMap}</td>
											<td>{pointDetailsDialogContent.data[id].removed}</td>
											<td>{pointDetailsDialogContent.data[id].onMap + pointDetailsDialogContent.data[id].removed}</td>
										</tr>
									})
								}
							</tbody>
						</table>
					</Dialog>
				)}

			</Container>
        )
	}

}

function mapStateToProps(state) {
	return {
		user: state.auth.user.isAdministrator,
		project: state.selectedProject
	}
}

export default connect(mapStateToProps)(ProjectAnalyticsContainer);

import { fService } from "../services/firebaseService"
import UserInfo from "./UserInfo"
import Company from "./Company"
import Project from "./Project"
import { showMessageDialog } from '../lib/dialog-generator/dialog-generator';
import sha1 from "sha1";

export default class CurrentUser {

	constructor(firebaseUser, loadCallback) {
		this.firebaseUser = firebaseUser
		this.isAdministrator = null
		this.isCompanyAdmin = null
		this.company = null
		this.companyId = null
		this.info = null

		var adminLoaded = false;
		var companyLoaded = false;
		const dataLoaded = () => {
			if (adminLoaded && companyLoaded) loadCallback()
		}
		this.loadInfo().then(() => {
			if (!this.info.businessId || !this.companyId) {
				companyLoaded = true
				dataLoaded()
			} else {
				this.loadCompany().then(() => {
					companyLoaded = true
					dataLoaded()
				})
			}

		})
		this.isUserAdministrator().then((boolean) => {
			this.isAdministrator = boolean
			adminLoaded = true
			dataLoaded()
		})
	}

	loadInfo() {
		return new Promise((resolve, reject) => {
			fService.FREF_USERS.child(fService.uid).once('value', snap => {
				const data = snap.val()
				this.companyId = data.company_id
				this.info = new UserInfo(fService.uid, data.info.first_name, data.info.last_name, data.info.email, data.info.phone, data.info.business_id)
				resolve()
			});
		});
	}

	loadCompany() {
		return new Promise((resolve, reject) => {
			Company.getCompany(this.companyId, this.info.businessId).then((company) => {
				var rulesLoaded = false
				var adminsLoaded = false
				var analyticProjectsLoaded = false
				const loaded = () => {
					if (rulesLoaded && adminsLoaded && (analyticProjectsLoaded || !company.rules.controlPanelAccess)) {
						this.company = company
						resolve(company)
					}
				}
				company.loadAnalyticProjects().then(() => {
					analyticProjectsLoaded = true
					loaded()
				})
				company.loadRules().then(() => {
					rulesLoaded = true
					loaded()
				})
				company.loadAdministrators().then(() => {
					adminsLoaded = true
					for (const userId of company.admins) {
						if (this.firebaseUser.uid === userId) {
							this.isCompanyAdmin = true
							break
						}
					}
					if (!this.isCompanyAdmin) this.isCompanyAdmin = false
					loaded()
				})
			})
		})
	}

	isUserAdministrator() {
		return new Promise((resolve, reject) => {
			fService.FREF_ADMINISTRATORS.child(fService.uid).once('value', snap => {
				if (snap.val() === true) {
					resolve(true)
				} else {
					resolve(false)
				}
			})
		})
	}

	getCompanies() {
		return new Promise((resolve, reject) => {
			fService.FREF_COMPANIES.once('value', (snap) => {
				const companiesJson = snap.val();
				const companies = [];
				for (const businessId in companiesJson) {
					let company = companiesJson[businessId]
					const projects = []
					for (const projectId in company.projects) {
						projects.push(projectId)
					}
					companies.push(new Company(null, new Company.Info(businessId, company.info.name), projects))
				}

				var idsToLoad = companies.length
				for (let company of companies) {
					fService.FREF_COMPANY_SECURE.orderByChild('business_id').equalTo(company.info.businessId).once('value', snap => {
						const data = snap.val()
						if (!data) reject()
						const id = Object.keys(data)[0]
						company.id = id
						company.rules = new Company.Rules(data[id].rules.locked, data[id].rules.create_project, data[id].rules.control_panel_access)
						if (--idsToLoad === 0) resolve(companies)
					}).catch((e) => {
						reject()
					})
				}

			}, (error) => {
				reject()
				// console.log(error.code === "PERMISSION_DENIED")
			})
		})
	}

	checkControlPanelAccess() {
		if (this.company && !this.company.rules.controlPanelAccess) {
			//TODO: LAURI: Check the added
			if (this.isAdministrator) return true
			showMessageDialog(null, "Ei oikeuksia käyttää hallintapaneelia!", "OK")
			return false
		} else return true
	}

	joinCompany(businessId, password) {
		return new Promise((resolve, reject) => {
			const errors = {}
			if (!businessId) errors.businessId = "Y-tunnus vaaditaan"
			if (!password) errors.password = "Salasana vaaditaan"
			if (!businessId || !password) return reject(errors)
			const uid = fService.uid ? fService.uid : reject({somethingWentWrong: true})

			fService.FREF_BASE.child(fService.PATH_COMPANY_JOIN_KEYS).child(businessId).child(sha1(password)).once('value', snap => {
				const companyId = snap.val()
				if (companyId) {
					fService.FREF_BASE.update({
						[`${fService.PATH_COMPANY_SECURE}/${companyId}/${fService.PATH_USERS}/${uid}`]: true,
						[`${fService.PATH_USERS}/${uid}/company_id`]: companyId,
						[`${fService.PATH_USERS}/${uid}/${fService.PATH_INFO}/business_id`]: businessId
					}, (error) => {
						if (!error) {
							this.companyId = companyId
							this.info.businessId = businessId
							resolve()
						} else reject({somethingWentWrong: true})
					})
				} else {
					reject({password: "Väärä salasana"})
				}
			})
		})
	}

	leaveCompany() {
		// console.log(this)
		return new Promise((resolve, reject) => {
			const uid = fService.uid
			if (!this.company || !uid) reject()
			fService.FREF_BASE.update({
				[`${fService.PATH_COMPANY_SECURE}/${this.company.id}/${fService.PATH_USERS}/${uid}`]: null,
				[`${fService.PATH_USERS}/${uid}/company_id`]: null,
				[`${fService.PATH_USERS}/${uid}/${fService.PATH_INFO}/business_id`]: null
			}, (error) => {
				if (error) reject()
				else {
					this.company = null
					this.companyId = null
					resolve()
				}
			})
		})
	}

}


/* FREF_XXX.child(XXX).once('value', (snap) => {
	console.log(snap.val())
}, (error) => {
	// console.log(error.code === "PERMISSION_DENIED")
}); */
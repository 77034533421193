// import { guid } from '../lib/Helper'

export const KEY_LATITUDE = "lat"
export const KEY_LONGITUDE = "long"
export const KEY_DATE_CREATED = "date_created"
export const KEY_DATE_REMOVED = "date_removed"
export const KEY_TYPE = "type"
export const KEY_TITLE = "title"
export const KEY_IS_LOCKED = "locked"
export const KEY_REMOVER = "remover"

export default class LocationPoint {


	constructor(id, type, coords, data, dateCreated, dateRemoved, title) {
		this.id = id
		this.type = type
		this.coords = coords
		this.data = data
		this.dateCreated = dateCreated
		this.dateRemoved = dateRemoved
		this.title = title
	}

	getDict = () => {
		let data = {[KEY_LATITUDE]: this.coords[0], [KEY_LONGITUDE]: this.coords[1], [KEY_DATE_CREATED]: this.dateCreated, [KEY_TYPE]: this.type}
		if (this.title) {
			data[KEY_TITLE] = this.title
		}
		return data
	}


	static objectsFromData = (data) => {
		const locPoints = []
		for (const locPointId in data) {
			const locPoint = data[locPointId]
			locPoints.push(new LocationPoint(locPointId, locPoint[KEY_TYPE], [locPoint[KEY_LATITUDE], locPoint[KEY_LONGITUDE]], undefined, locPoint[KEY_DATE_CREATED], locPoint[KEY_DATE_REMOVED], locPoint[KEY_TITLE]))
		}
		return locPoints
	}

}

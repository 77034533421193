import { SET_COMPANY_INFOS } from '../actions/types';

const initialState = {
	infos: {}
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case SET_COMPANY_INFOS:
			state.infos[action.businessId] = action.companyInfo
			return {
				infos: state.infos
			};
		default: return state;
	}
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCompany, setProject } from '../../actions/appStateActions';
import { ReactComponent as CogIcon } from '../../assets/cog.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/left.svg';
import { ReactComponent as CrossIcon } from '../../assets/times.svg';
import Container from '../../lib/container';
import Dialog from '../../lib/dialog';
import { ROUTE_EDIT_COMPANY, ROUTE_EDIT_PROJECT, ROUTE_NEW_COMPANY, ROUTE_NEW_PROJECT } from '../../utils/RouteUtils';
import CompanySelectContainer from '../container-company-select/container-company-select';
import CompanyContainer from '../container-company/container-company';
import CreateCompanyContainer from '../container-create-company/container-create-company';
import CreateProjectContainer from '../container-create-project/container-create-project';
import ProjectSelectContainer from '../container-project-select/container-project-select';
import ProjectContainer from '../container-project/container-project';
import NavBar from '../navbar/navbar';
import './page-main.css';

class MainPage extends Component {

	constructor(props) {
		super(props)

		this.state = {
			showCancelNewProjectDialog: false,
			cancelNewProjectCallback: null,
		}
	}

	componentDidMount() {
		const { user } = this.props
		if (!user.isAdministrator && user.company) {
			this.props.setCompany(user.company)
			this.props.history.push('/')
		}
	}

	componentDidUpdate(prevProps) {
		const { selectedCompany, history, user } = this.props

		if (!user.isAdministrator && user.company && user.company.i) {
			if (!selectedCompany) {
				this.props.setCompany(user.company)
			}
		}

	}

	getPageState = () => {
		const { user, selectedCompany, selectedProject } = this.props
		const { pathname } = this.props.history.location

		let pageState
		switch (pathname) {
			case '/':
				if (selectedCompany && selectedProject) pageState = this.STATE_INSPECTING_PROJECT
				else if (selectedCompany) pageState = this.STATE_INSPECTING_COMPANY
				else pageState = this.STATE_HOME
				break
			case ROUTE_EDIT_COMPANY:
				pageState = this.STATE_EDITING_COMPANY
				break
			case ROUTE_NEW_COMPANY:
				pageState = this.STATE_CREATING_COMPANY
				break
			case ROUTE_NEW_PROJECT:
				pageState = this.STATE_CREATING_PROJECT
				break
			case ROUTE_EDIT_PROJECT:
				pageState = this.STATE_EDITING_PROJECT
				break
			default:
				pageState = this.STATE_HOME
		}

		return pageState
	}

	cancelNewProject = (callback, showWarnDialog = true) => {
		const pageState = this.getPageState()
		if (pageState !== this.STATE_CREATING_PROJECT && pageState !== this.STATE_EDITING_PROJECT) {
			if (callback) callback()
		} else if (showWarnDialog) {
			this.setState({
				showCancelNewProjectDialog: true,
				cancelNewProjectCallback: callback
			})
		} else {
			this.props.history.replace('/')
			this.setState({ cancelNewProjectCallback: null })
		}
	}

	STATE_HOME = "home"
	STATE_INSPECTING_COMPANY = "inspectingCompany"
	STATE_CREATING_COMPANY = "creatingCompany"
	STATE_EDITING_COMPANY = "editingCompany"
	STATE_INSPECTING_PROJECT = "inspectingProject"
	STATE_CREATING_PROJECT = "creatingProject"
	STATE_EDITING_PROJECT = "editingProject"

	render() {
		const { user, selectedCompany } = this.props
		const { showCancelNewProjectDialog, cancelNewProjectCallback } = this.state
		const pageState = this.getPageState()

		if (!user) return null
		if (!user.isAdministrator && !selectedCompany) return null

		let leftContainerTitle
		let leftContainerContent

		let rightContainerTitle
		let rightContainerCloseIcon
		let rightContainerOnClose
		let rightContainerContent
		let rightContainerTopButtonIcon
		let rightContainerOnTopButtonClick

		switch (pageState) {
			case this.STATE_INSPECTING_COMPANY:
			case this.STATE_EDITING_COMPANY:
			case this.STATE_INSPECTING_PROJECT:
			case this.STATE_CREATING_PROJECT:
			case this.STATE_EDITING_PROJECT:
				// if (!user.isAdministrator) {
					leftContainerTitle = "Projektit"
					leftContainerContent = (
						<ProjectSelectContainer
							// ref={(component) => {this.projectSelectComponent = component}}
							projectChangeAllowed={this.cancelNewProject}
						/>
					)
					break
				// }
			case this.STATE_HOME:
			case this.STATE_CREATING_COMPANY:
				leftContainerTitle = "Yritykset"
				leftContainerContent = <CompanySelectContainer creatingNewCompany={pageState === this.STATE_CREATING_COMPANY} />
				break
		}
		switch (pageState) {
			case this.STATE_INSPECTING_COMPANY:
				rightContainerTitle = "Yritys"
				rightContainerCloseIcon = <ArrowLeftIcon className="icon-medium" />
				rightContainerContent = <CompanyContainer />
				rightContainerTopButtonIcon = (user.isAdministrator || user.isCompanyAdmin) ? <CogIcon className="icon-medium" /> : null
				rightContainerOnTopButtonClick = (user.isAdministrator || user.isCompanyAdmin) ? () => {
					this.props.history.push(ROUTE_EDIT_COMPANY)
				} : null
				rightContainerOnClose = user.isAdministrator ? () => {
					this.props.setCompany(null)
					this.props.history.push('/')
				} : null
				break
			case this.STATE_CREATING_COMPANY:
				rightContainerTitle = "Uusi Yritys"
				rightContainerCloseIcon = <CrossIcon className="icon-medium" />
				rightContainerContent = <CreateCompanyContainer />
				rightContainerOnClose = () => {
					this.props.setCompany(null)
					this.props.history.push('/')
				}
				break
			case this.STATE_EDITING_COMPANY:
				rightContainerTitle = "Muokkaa yritystä"
				rightContainerCloseIcon = <ArrowLeftIcon className="icon-medium" />
				rightContainerContent = <CreateCompanyContainer />
				rightContainerOnClose = () => {
					this.props.history.push('/')
				}
				break
			case this.STATE_INSPECTING_PROJECT:
				rightContainerTitle = "Projekti"
				rightContainerCloseIcon = <ArrowLeftIcon className="icon-medium" />
				rightContainerContent = <ProjectContainer />
				rightContainerOnClose = () => {
					this.props.setProject(null)
					this.props.history.push('/')
				}
				break
			case this.STATE_CREATING_PROJECT:
				rightContainerTitle = "Uusi Projekti"
				rightContainerCloseIcon = <CrossIcon className="icon-medium" />
				rightContainerContent = <CreateProjectContainer />
				rightContainerOnClose = () => {
					this.props.history.push('/')
				}
				break
			case this.STATE_EDITING_PROJECT:
				rightContainerTitle = "Muokkaa Projektia"
				rightContainerCloseIcon = <ArrowLeftIcon className="icon-medium" />
				rightContainerContent = <CreateProjectContainer />
				rightContainerOnClose = () => {
					this.props.history.push('/')
				}
				break
		}


		return (
			<div className="main-page">
				<NavBar />

				<div className="center-container">
					<div className="left-container">
						<Container title={leftContainerTitle} collapsable={Container.Collapsable.LG}>
							{ leftContainerContent }
						</Container>
					</div>
					<div className="right-container">
						{(pageState !== this.STATE_HOME) && (
							<Container
								title={rightContainerTitle}
								closeIcon={rightContainerCloseIcon}
								topButtonIcon={rightContainerTopButtonIcon}
								onTopButtonClick={rightContainerOnTopButtonClick}
								onClose={rightContainerOnClose}>
								{ rightContainerContent }
							</Container>
						)}
					</div>
				</div>

				<Dialog
					message={pageState === this.STATE_EDITING_PROJECT ? "Haluatko varasti hylätä projektin muokkaukset?" : "Haluatko varmasti hylätä projektin luonnin?"}
					positiveButtonText="Hylkää"
					neutralButtonText="Peruuta"
					onPositiveButtonClick={() => {
						if (cancelNewProjectCallback) cancelNewProjectCallback()
						this.setState({cancelNewProjectCallback: null})
					}}
					primaryButtonNegative={true}
					show={showCancelNewProjectDialog}
					onClose={() =>
						this.setState({showCancelNewProjectDialog: false})
					}
				/>
			</div>
        )

	}


}

function mapStateToProps(state) {
	return {
		user: state.auth.user,
		selectedCompany: state.selectedCompany, // OBJECT (NOT ID)
		selectedProject: state.selectedProject,
		companies: state.companies, // ONLY FOR ADMIN
	}
}

export default connect(mapStateToProps, { setCompany, setProject })(MainPage)

import React, { useEffect, useState } from 'react'
// @ts-ignore
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import { useTypedSelector } from '../../hooks/redux-hooks'
import { openUrlInNewTab } from '../../lib/Helper'
import Button from '../../lib/button/button'
import Input from '../../lib/input/input'
import SelectableCard from '../../lib/selectable-card/selectable-card'

// Modal for creating and editing location points on the map
const NewPointModal = ({ type, onClose, onDoneEditing, coordinates, inEdit, locPointData }) => { // In edit is location poitn that is in edit. locPointData is its "data"
	const [isLoading, setLoading] = useState(false)
	const [inputValues, setInputValues] = useState({})
	const [title, setTitle] = useState<string>()
	const [images, setImages] = useState({}) // imageId: downloadURL
	const [imagesToRemove, setImagesToRemove] = useState<string[]>([])
	const [imageFilesToUpload, setImagesFilesToUpload] = useState<File[]>([])
	const [isRequiredHighlighted, setRequiredHighlighted] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const project = useTypedSelector(state => state.selectedProject)
	const user = useTypedSelector(state => state.auth.user?.info)

	// Runs only once
	useEffect(() => {
		if (inEdit) {
			if (inEdit.title) setTitle(inEdit.title)
			let _data = {}
			if (locPointData.data) _data = {...locPointData.data}
			setInputValues(_data)
			if (locPointData.description) {_data["lisatiedot"] = locPointData.description} //TODO: Improve. Currently very error prone since lisatiedot string is hardcoded and description is added to fields even though it is not in fields e.g. in swift code! Description should not be saved to data but to separate description property in location point
			if (locPointData.images) {
				Object.keys(locPointData.images).forEach(id => images[id] = null)
				Promise.all(Object.keys(locPointData.images).map(id => project?.getFileDownloadUrl(id)))
					.then(urls => {
						const images = {}
						Object.keys(locPointData.images).forEach((id, i) => images[id] = urls[i])
						setImages(images)
					})
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!user) return null

	let imageCounter = 0

	return (
		<div className="new-point-modal-container">
			<div className="new-point-modal">
				<h4 style={{marginBottom: '12px'}}>{type.iconId === 0 ? "Huomio" : type.name}</h4>
				{ type.iconId === 0 &&
					// Exception: There is always an option to add title to notice marker even though its not defined in fields
					<>
					<Input
						title="Huomio"
						value={title || ""}
						errorMessage={(isRequiredHighlighted) ? "vaaditaan" : undefined}
						onChange={(e) => {
							if (e.target.value.length <= 60) { setTitle(e.target.value) }
						}}
					/>
					<p style={{ marginBottom: '12px', marginTop: '-8px', fontSize: '11px', color: 'gray'}} >{`${title?.length || 0}/60`}</p>
					</>
				}
				{ type.fields && type.fields.map(field => {
					const inputValue = inputValues[field.id]
					return (
						<Input
							key={field.id}
							rows={field.type === 1 ? 3 : 0}
							title={field.title}
							value={inputValue !== null ? (isNaN(inputValue) ? inputValue : String(inputValue)) : ""}
							type={(field.dataType && field.dataType === "num") ? "number" : "text"}
							errorMessage={(field.required && isRequiredHighlighted) ? "vaaditaan" : undefined}
							onChange={(e) => {
								const values = {...inputValues}
								const value = e.target.value
								if (field.dataType && field.dataType === "num") {
									values[field.id] = Number(value)
									if (Number(value) >= 0) setInputValues(values)
								} else {
									values[field.id] = value
									setInputValues(values)
								}
							}}
						/>
					)
				})}
				{ Object.keys(images).map((id: string) => {
					if (imagesToRemove.includes(id)) return null
					return <SelectableCard
						key={id}
						id={id}
						text={`Kuva ${++imageCounter}`}
						disabled={isLoading}
						onDelete={id => setImagesToRemove([ ...imagesToRemove, id ])}
						onSelect={id => {
							if (id !== null) openUrlInNewTab(images[id])
						}}
						tier={2}
					/>
				})}
				{ imageFilesToUpload.map((file: File) =>
					<SelectableCard
						key={file.name}
						id={file.name}
						text={file.name}
						disabled={isLoading}
						onDelete={() => {
							const index = imageFilesToUpload.findIndex((imageFile: File) => (imageFile.name === file.name))
							const imagesToUpload = [...imageFilesToUpload]
							imagesToUpload.splice(index, 1)
							setImagesFilesToUpload(imagesToUpload)
						}}
						onSelect={() => {
							const reader = new FileReader()
							reader.onload = (e) => {
								const newTab = window.open()
								if (newTab) newTab.document.body.innerHTML = `<img src="${e.target?.result}">`
							}
							reader.readAsDataURL(file)
						}}
						tier={2}
					/>
				)}
				<SelectableCard
					text="Uusi Kuva"
					highlighted={true}
					leftIcon={<PlusIcon className="icon-small" />}
					type={SelectableCard.Type.FILE_UPLOAD}
					accept="image/*" maxFileSize={10}
					onFileUpload={(file: File) => {
						if (imageFilesToUpload.find(imageFile => (imageFile.name === file.name))) return
						setImagesFilesToUpload([...imageFilesToUpload, file])
					}}
					disabled={isLoading}
					tier={2}
				/>
				<div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
					<Button type={Button.Type.SECONDARY} title="Peruuta" onClick={onClose} style={{marginRight: '16px'}} disabled={isLoading}/>
					<Button
						disabled={isLoading}
						title="Tallenna"
						onClick={() => {
							setLoading(true)
							let valid = true
							type.fields.forEach(field => {
								// validate that required fields have values
								if (field.dataType === "num") { // validate data type
									if ( inputValues[field.id] && isNaN(inputValues[field.id]) ) valid = false
									else if ( field.required && inputValues[field.id] == null ) valid = false
								} else {
									if (field.required && (!inputValues[field.id] || inputValues[field.id].trim() === "")) valid = false
								}
							})
							if (type.iconId === 0 && (!title || title.trim() === "")) valid = false
							if (!valid) {
								setRequiredHighlighted(true)
								setError("Täytä vaaditut kentät!")
								setLoading(false)
								return
							} else setRequiredHighlighted(false)

							if (inEdit) {
								project?.editLocationPoint(inEdit.id, title, inputValues, Object.keys(images), imagesToRemove, imageFilesToUpload)
									.then(onDoneEditing)
									.catch(err => {
										setError(err)
										setLoading(false)
									})
							} else {
								// FIXME: Why is businessId missing?
								project?.saveNewPoint(coordinates.lat, coordinates.lng, type.id, user, title, inputValues, imageFilesToUpload)
									.then(onClose)
									.catch((err) => {
										setError(err)
										setLoading(false)
									})
							}
						}}
					/>
				</div>
				{ error && <p style={{color: 'red', fontWeight: '400', fontSize: '0.9rem'}}>{error}</p> }
			</div>
		</div>
	)
}

export default NewPointModal

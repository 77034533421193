import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';

import { setCurrentUser } from './actions/authActions';
import { resetAppState } from './actions/appStateActions';
import { listenToAuthenticationChanges, signOut } from './services/authService';
import MainPage from './components/page-main/page-main';
import SignInPage from './components/page-sign-in/page-sign-in';
import SignUpPage from './components/page-sign-up/page-sign-up';
import ForgotPasswordPage from './components/page-forgot-password/page-forgot-password';
import JoinCompanyPage from './components/page-join-company/page-join-company';
import CompanyLockedPage from './components/page-company-locked/page-company-locked';
import RouterScrollTop from './utils/RouterScrollTop'
import CurrentUser from './models/CurrentUser';
import LoadingSpinner from './lib/loading-spinner';
import { ROUTE_LOGIN, ROUTE_SIGN_UP, ROUTE_JOIN_COMPANY, ROUTE_FORGOT_PASSWORD, ROUTE_NEW_COMPANY } from './utils/RouteUtils';
import MapPage from './components/page-map/page-map.tsx'
import './App.css';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fi from './locale/fi'


class App extends Component {

	constructor(props) {
		super(props)

		this.state = {
			isLoading: true,
		}

		listenToAuthenticationChanges(firebaseUser => {
			if (firebaseUser) {
				const currentUser = new CurrentUser(firebaseUser, () => {
					this.props.setCurrentUser(currentUser)
				})
			} else {
				this.setState({ isLoading: false })
				this.props.setCurrentUser(null)
				this.props.resetAppState()
				signOut()
			}
		})

	}

	componentDidMount() {
		// Set react-datepicker locale
		registerLocale('fi', fi);
		setDefaultLocale('fi');
	}


	componentDidUpdate(prevProps) {
		const { user } = this.props.auth
		if (!prevProps.auth.user && user) {
			if (!user.checkControlPanelAccess()) signOut()

			this.setState({ isLoading: false })
			this.props.history.push('/')
		}
	}

	render() {
		const { isLoading } = this.state

		const isLoggedIn = !!(!isLoading && this.props.auth.isAuthenticated)
		const currentUser = this.props.auth.user


		let isAdmin
		let isInCompany
		let isCompanyLocked

		if (currentUser) {
			isAdmin = !!(currentUser.isAdministrator)
			isInCompany = !!currentUser.company
			isCompanyLocked = (isInCompany && currentUser.company.rules.locked)
		}

		return (
			<RouterScrollTop>
				<Switch>
					{ isLoading && <Route path="/" component={() => <LoadingSpinner/>}/> }

					{ !isLoggedIn && <Route exact path={ROUTE_LOGIN} component={SignInPage}/> }
					{ !isLoggedIn && <Route path={ROUTE_SIGN_UP} component={SignUpPage}/> }
					{ !isLoggedIn && <Route path={ROUTE_FORGOT_PASSWORD} component={ForgotPasswordPage}/> }
					{ !isLoggedIn && <Redirect to={ROUTE_LOGIN}/> }

					{(!isInCompany && !isAdmin) && <Route exact path={ROUTE_JOIN_COMPANY} component={JoinCompanyPage}/> }
					{(!isInCompany && !isAdmin) && <Redirect to={ROUTE_JOIN_COMPANY}/> }

					{(isCompanyLocked && !isAdmin) && <Route exact path="/" component={CompanyLockedPage}/> }

					<Route exact path="/" component={MainPage}/>
					<Route exact path={ROUTE_NEW_COMPANY} component={MainPage}/>
					<Route exact path="/newCompany" component={MainPage}/>
					<Route exact path="/editCompany" component={MainPage}/>
					<Route exact path="/newProject" component={MainPage}/>
					<Route exact path="/editProject" component={MainPage}/>
				 	<Route exact path="/map" component={MapPage}/>
					<Redirect to="/"/>
				</Switch>
			</RouterScrollTop>
		)
  	}

}

function mapStateToProps(state) {
	return {
		auth: state.auth,
		stateType: state.state
	}
}


export default withRouter(connect(mapStateToProps, { setCurrentUser, resetAppState })(App))

import { SET_SELECTED_COMPANY} from '../actions/types';
import { RESET_APP_STATE } from '../actions/types';

const initialState = null

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case SET_SELECTED_COMPANY:
			return action.company

		case RESET_APP_STATE:
			return null

		default:
			return state

	}
}
import React, { ReactNode } from "react"
import { Popup } from "react-map-gl/maplibre"

type InfoWindowProps = {
  children: ReactNode
  position: { lat: number, lng: number }
  onCloseClick: () => void
}

/** Replaces Google Maps' info window with Popup from react-map-gl */
const InfoWindow: React.FC<InfoWindowProps> = ({ children, position, onCloseClick }) => (
  <Popup
    latitude={position.lat}
    longitude={position.lng}
    anchor="bottom"
    onClose={onCloseClick}
  >
    {children}
  </Popup>
)

export default InfoWindow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import Container from '../../lib/container';
import { guid } from '../../lib/Helper';
import SelectableCard from '../../lib/selectable-card';
import MonthAnalyticsContainer from '../container-month-analytics/container-month-analytics';

export class CompanyAnalyticsContainer extends Component {

	constructor(props) {
		super(props)

		this.state = {
			date: new Date(),
			monthAnalyticsContainers: []
		}

	}

	componentDidMount() {
		const lastMonth = new Date()
		lastMonth.setDate(1)
		lastMonth.setMonth(lastMonth.getMonth() - 1)

		this.addMonthAnalyticsContainer()
		this.addMonthAnalyticsContainer(lastMonth)
	}

	addMonthAnalyticsContainer(beginMonth, endMonth) {
		const length = this.state.monthAnalyticsContainers.length
		const { monthAnalyticsContainers } = this.state

		const uuid = guid()
		monthAnalyticsContainers.push(<MonthAnalyticsContainer id={uuid} key={uuid} beginMonth={beginMonth} endMonth={endMonth} onClose={() => this.closeMonthAnalyticsContainer(uuid)}/>)

		this.setState({monthAnalyticsContainers: monthAnalyticsContainers})
	}

	closeMonthAnalyticsContainer(uuid) {
		const { monthAnalyticsContainers } = this.state

		window.$(`#${uuid}`).slideUp(() => {
			var i = 0
			for (const container of this.state.monthAnalyticsContainers) {
				if (container.key === uuid) {
					monthAnalyticsContainers.splice(i, 1)
					break
				}
				++i
			}
			this.setState({monthAnalyticsContainers: monthAnalyticsContainers})
		})
	}

	render() {

		const { monthAnalyticsContainers, refresher } = this.state

		return (
			<Container className='company-analytics-container' tier={2} title={ this.props.isAdministrator ? "Laskutus" : "Analytiikka" }>
				{ monthAnalyticsContainers.map((component, i) => { return component }) }
				<SelectableCard
					key={"newAnalytics"}
					type={SelectableCard.Type.MONTH_PICKER}
					dateMin={new Date(2018,0,1)}
					dateMax={new Date()}
					onDateChanged={(date) => {
						this.addMonthAnalyticsContainer(date)
					}}
					leftIcon={<PlusIcon className="icon-small" />}
					text="Hae kuukausi"
					tier={2}/>
			</Container>
		)
	}

}

function mapStateToProps(state) {
	return {
		user: state.auth.user.isAdministrator
	}
}

export default connect(mapStateToProps)(CompanyAnalyticsContainer);

import classNames from "classnames"
import 'maplibre-gl/dist/maplibre-gl.css'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import "react-datepicker/dist/react-datepicker.css"
import ReactGoogleAutocomplete from "react-google-autocomplete"
import MapLibreMap, { MapRef, Marker, NavigationControl } from 'react-map-gl/maplibre'
// @ts-ignore // These work properly, so safe to ignore
import { ReactComponent as CompressIcon } from "../../assets/compress.svg"
// @ts-ignore
import { ReactComponent as ExpandIcon } from "../../assets/expand.svg"
import satelliteStyles from '../../assets/satelliittikartta.json'
import baseStyles from '../../assets/taustakartta.json'
// @ts-ignore
import { ReactComponent as ArrowLeftIcon } from "../../assets/left.svg"
import terrainStyles from '../../assets/maastokartta.json'
import terrainNoPropertiesStyles from '../../assets/maastokartta-ilman-kiinteistorajoja.json'
// @ts-ignore
import { ReactComponent as SearchIcon } from "../../assets/search.svg"
import usePrevious from '../../hooks/usePrevious'
import { isSafari, setFullscreen } from "../../lib/Helper"
import BorderlessButton from "../../lib/borderless-button"
import Dialog from "../../lib/dialog/dialog"
import LocationPoint from "../../models/LocationPoint.js"
import LocationPointType from "../../models/LocationPointType.js"
import Project from "../../models/Project.js"
import UserInfo from "../../models/UserInfo"
import InfoWindow from './InfoWindow'
import MapMarkers from './MapMarkers'
import NewPointInfo from './NewPointInfo'
import NewPointModal from "./NewPointModal"
import InfoView from "./info-view.js"
import CustomRadio from "../../lib/custom-radio"

const KEY_MAP_TYPE = 'MAP_TYPE'

enum MapType {
  BASE = "BASE",
  TERRAIN = "TERRAIN",
  SATELLITE = "SATELLITE",
  TERRAIN_NO_PROPERTIES = "TERRAIN_NO_PROPERTIES"
}

type MapProps = {
  locationPoints: LocationPoint[]
  initialCoordinates: number[]
  locationPointTypes: Record<string, LocationPointType>
  project: Project
  showRemoved: boolean
  userId: string
  isLoading: boolean
  setLoading: (value: boolean) => void
}

const Map: React.FC<MapProps> = ({
  locationPoints,
  initialCoordinates,
  locationPointTypes,
  project,
  showRemoved,
  userId,
  isLoading,
  setLoading,
}) => {
  const [selectedPoint, setSelectedPoint] = useState<LocationPoint | null>(null)
  const [locPointData, setLocPointData] = useState(null);
  const [authorInfo, setAuthorInfo] = useState(null);
  const [removerInfo, setRemoverInfo] = useState(null);
  const [newCoordinates, setNewCoordinates] = useState<{ lat: number, lng: number } | null>(null)
  const [isDeleteDialogVisible, showDeleteDialog] = useState(false);
  const [newPointType, setNewPointType] = useState<string | null>(null)
  const [isEditing, setEditing] = useState(false);
  const [isSearching, setSearching] = useState(false); // Affects page mobile version only
  const [isFullscreen, _setFullscreen] = useState(false); // mobile version only
  const [searchLocation, setSearchLocation] = useState<any>(null)

  const [mapType, setMapType] = useState(MapType.TERRAIN_NO_PROPERTIES)

  // Handle "caching" of mapType

  // Load mapType on component mount
  useEffect(() => {
    const t = localStorage.getItem(KEY_MAP_TYPE) as MapType | undefined
    setMapType(t || MapType.TERRAIN_NO_PROPERTIES)
  }, [])

  // Store mapType
  useEffect(() => {
    localStorage.setItem(KEY_MAP_TYPE, mapType)
  }, [mapType])


  const selectedStyle = useMemo(() => {
    switch (mapType) {
      case MapType.BASE: return baseStyles
      case MapType.TERRAIN: return terrainStyles
      case MapType.SATELLITE: return satelliteStyles
      case MapType.TERRAIN_NO_PROPERTIES: return terrainNoPropertiesStyles
      default: return terrainStyles
    }
  }, [mapType])

  const mapRef = useRef<MapRef>(null) // Used to control the map's pan and zoom
  const prevPoints = usePrevious(locationPoints)

  useEffect(() => {
    // Clear selection when data changes
    if (prevPoints !== locationPoints) resetSelection()
  }, [prevPoints, locationPoints])

  const resetSelection = () => {
    setSelectedPoint(null)
    setLocPointData(null)
    setAuthorInfo(null)
    setRemoverInfo(null)
  }

  const locPointDataReceived = (data: any) => {
    UserInfo.getInfo(data.author).then(setAuthorInfo).catch(console.error);
    if (showRemoved) {
      UserInfo.getInfo(data.remover).then(setRemoverInfo).catch(console.error);
    }
    setLocPointData(data)
  }

  const getPlaceZoom = (place) => {
    let zoom = 0
    place.address_components?.[0].types.forEach((type) => {
      switch (type) {
        case "country":
          zoom = 6
          break;
        case "administrative_area_level_1":
          zoom = 9
          break;
        case "locality":
        case "administrative_area_level_2":
        case "establishment":
          zoom = 13
          break;
        case "route":
          zoom = 16
          break;
        case "administrative_area_level_3":
        case "street_address":
        case "street_number":
          zoom = 17
          break
      }
    })
    return zoom
  }

  function moveToPlace(place: google.maps.places.PlaceResult) {
    const lat = place?.geometry?.location?.lat()
    const lng = place?.geometry?.location?.lng()

    if (lat && lng) {
      const coordinates = [lat, lng]
      const name = place.formatted_address
      setSearchLocation({ coordinates, name })
      mapRef.current?.setZoom(getPlaceZoom(place))
      mapRef.current?.panTo({ lat, lng })
    }
  }

  return (
    <>
      <ReactGoogleAutocomplete
        apiKey="AIzaSyDxEPjK3OeXbWudv8kLsxCCelMwrNukF7E"
        className={classNames({
          "map-search-bar": true,
          "map-search-bar--searching": isSearching,
        })}
        onPlaceSelected={moveToPlace}
        options={{
          // @ts-ignore
          types: "locality",
          bounds: {
            south: 59.65422,
            west: 19.27571,
            north: 70.09558,
            east: 31.58836
          },
          strictBounds: true,
        }}
        language="fi"
        placeholder="Hae sijainti"
      />
      <MapLibreMap
        ref={mapRef}
        // maxBounds={[-12, 54.8085, 62, 75.0925]} // w, s, e, n
        onError={() => {}} // MapLibre gives loads of errors due to the map not being able to load tiles outside Finland, which can be ignored
        // @ts-ignore
        mapStyle={selectedStyle}
        maplibreLogo={false}
        initialViewState={{
          latitude: initialCoordinates[0],
          longitude: initialCoordinates[1],
          zoom: 4.5,
        }}
        minZoom={4.5}
        onClick={(e) => {
          setSearchLocation(undefined)
          if (selectedPoint || newCoordinates) {
            resetSelection()
            setNewCoordinates(null)
          } else {
            const { lat, lng } = e.lngLat
            setNewCoordinates({ lat, lng })
          }
        }}
      >
        <div className="layer-controls">
          <div className="layer-control-row" onClick={() => setMapType(MapType.BASE)}>
            <CustomRadio checked={mapType === MapType.BASE} color="var(--color-primary)" />
            <label>Kartta</label>
          </div>
          <div className="layer-control-row" onClick={() => setMapType(MapType.TERRAIN_NO_PROPERTIES)}>
            <CustomRadio checked={mapType === MapType.TERRAIN_NO_PROPERTIES} color="var(--color-primary)" />
            <label>Maastokartta</label>
          </div>
          <div className="layer-control-row" onClick={() => setMapType(MapType.TERRAIN)}>
            <CustomRadio checked={mapType === MapType.TERRAIN} color="var(--color-primary)" />
            <label>Maastokartta (kiinteistörajoilla)</label>
          </div>
          <div className="layer-control-row" onClick={() => setMapType(MapType.SATELLITE)}>
            <CustomRadio checked={mapType === MapType.SATELLITE} color="var(--color-primary)" />
            <label>Satelliittikuva</label>
          </div>
        </div>
        <NavigationControl position="bottom-right" showZoom showCompass />
        <MapMarkers
          locationPoints={locationPoints}
          locationPointTypes={locationPointTypes}
          onClickMarker={async (point) => {
            resetSelection()
            setSelectedPoint(point)
            const data = showRemoved
              ? await project.getRemovedLocationPointData(point.id)
              : await project.getLocationPointData(point.id)
            locPointDataReceived(data)
          }}
        />
        {newCoordinates && (
          <span>
            <InfoWindow
              position={newCoordinates}
              onCloseClick={() => {
                setNewCoordinates(null);
              }}
            >
              <NewPointInfo
                types={locationPointTypes}
                onTypeSelected={(type) => setNewPointType(type)}
              />
            </InfoWindow>
          </span>
        )}
        {selectedPoint && (
          <InfoWindow
            onCloseClick={resetSelection}
            position={{
              lat: selectedPoint.coords[0],
              lng: selectedPoint.coords[1],
            }}
          >
            <InfoView
              isLoading={isLoading}
              point={selectedPoint}
              locPointData={locPointData}
              model={locationPointTypes[selectedPoint.type]}
              showRemoved={showRemoved}
              authorInfo={authorInfo}
              removerInfo={removerInfo}
              onEdit={() => setEditing(true)}
              showDeleteDialog={() => showDeleteDialog(true)}
              onRestorePoint={() => {
                setLoading(true);
                project
                  .restorePoint(selectedPoint, locPointData)
                  .then(() => {
                    setLoading(false)
                    resetSelection()
                  })
                  .catch((err) => {
                    setLoading(false)
                    console.error(err)
                  })
              }}
            />
          </InfoWindow>
        )}
        {((newPointType && newCoordinates) ||
          (isEditing && selectedPoint && locPointData)) && (
          <NewPointModal
            locPointData={isEditing ? locPointData : undefined}
            inEdit={isEditing ? selectedPoint : undefined}
            type={
              isEditing
                ? locationPointTypes[selectedPoint?.type]
                : locationPointTypes[newPointType!]
            }
            coordinates={newCoordinates}
            onDoneEditing={() => {
              setLocPointData(null);
              project
                .getLocationPointData(selectedPoint?.id)
                .then(locPointDataReceived)
              setEditing(false);
            }}
            onClose={() => {
              setNewPointType(null);
              setNewCoordinates(null);
              setEditing(false);
            }}
          />
        )}
        {isSearching && (
          <BorderlessButton
            className="map-search-cancel-button"
            icon={<ArrowLeftIcon className="icon-medium" />}
            onClick={() => setSearching(false)}
            type={BorderlessButton.Type.WHITE}
            shadowType={BorderlessButton.ShadowType.NONE}
          />
        )}
        <BorderlessButton
          className="map-search-button"
          icon={<SearchIcon className="icon-medium" />}
          onClick={() => setSearching(true)}
          type={BorderlessButton.Type.WHITE}
          shadowType={BorderlessButton.ShadowType.NONE}
        />
        {!isSafari() && (
          <BorderlessButton
            className={classNames("map-fullscreen-button", {
              "map-is-fullscreen": isFullscreen,
            })}
            icon={isFullscreen ? <CompressIcon className="icon-medium" /> : <ExpandIcon className="icon-medium" />}
            onClick={() => {
              const fullscreen = isFullscreen;
              setFullscreen(!fullscreen)
                .then(() => _setFullscreen(!fullscreen))
                .catch(() => {});
            }}
            type={BorderlessButton.Type.WHITE}
            shadowType={BorderlessButton.ShadowType.NONE}
          />
        )}

        {searchLocation && <Marker latitude={searchLocation.coordinates[0]} longitude={searchLocation.coordinates[1]} />}
        <Dialog
          title="Poista piste"
          message="Haluatko varmasti poistaa pisteen?"
          neutralButtonText="Peruuta"
          negativeButtonText="Poista"
          onClose={() => showDeleteDialog(false)}
          onNegativeButtonClick={() => {
            //TODO: If listener is set instead of single get - deleted points will disappear automatically ----- Might cause other problems (when image is removed updates the object here?)
            setLoading(true);
            project
              .movePointToRemoved(selectedPoint, locPointData, userId)
              .then(() => {
                setLoading(false)
                resetSelection()
                showDeleteDialog(false)
              })
              .catch(() => {
                alert("Pisteen poistaminen epäonnistui");
                showDeleteDialog(false)
                setLoading(false)
              });
          }}
          show={isDeleteDialogVisible}
        />
      </MapLibreMap>
    </>

  );
}

export default Map

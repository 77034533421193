import React, { useState } from "react"
import { getBeginningOfDate, getEndOfDate } from "../../lib/Helper"
import DatePicker from 'react-datepicker'
import Button from "../../lib/button/button"

const RemovedModal = ({ showRemoved, onDone, onCancel }) => {
  const [dateFrom, setDateFrom] = useState(showRemoved.from);
  const [dateTo, setDateTo] = useState(showRemoved.to);

  return (
    <div className="bg-fade-view-12345">
      <div className="map-inner-view removed-points-modal">
        <h4>Hae poistettuja aikaväliltä:</h4>
        <div className="removed-points-date-pickers">
          <div>
            <p>Alkaen:</p>
            <DatePicker
              style={{ width: "100%" }}
              selected={dateFrom}
              onChange={(date) => setDateFrom(getBeginningOfDate(date, true))}
              dateFormat="dd.MM.yyyy"
            />
          </div>
          <div>
            <p>Päättyen:</p>
            <DatePicker
              style={{ width: "100%" }}
              selected={dateTo}
              onChange={(date) => setDateTo(getEndOfDate(date, true))}
              dateFormat="dd.MM.yyyy"
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            noMargin
            style={{ marginTop: "20px" }}
            title="Hae"
            onClick={() => {
              onDone({ from: dateFrom, to: dateTo });
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type={Button.Type.SECONDARY}
            noMargin
            style={{ marginTop: "12px" }}
            title="Peruuta"
            onClick={onCancel}
          />
        </div>
      </div>
    </div>
  );
};

export default RemovedModal

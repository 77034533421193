import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTypedSelector } from '../../hooks/redux-hooks';
import {
  calculateAge,
  getBeginningOfDate,
  getEndOfDate,
  isDated,
  isSafari,
  setFullscreen
} from "../../lib/Helper";
import LocationPoint from "../../models/LocationPoint";
import LocationPointType from "../../models/LocationPointType";
import { exportPointsToCsv } from "../../utils/exportPointsToCsv";
import Map from './Map';
import MapNavBar from "./MapNavBar";
import MarkerFilters from "./MarkerFilters";
import RemovedModal from "./RemovedModal";
import "./page-map.css";

const MapPage = ({ history }) => {

  const { auth, selectedCompany, selectedProject } = useTypedSelector((state) => state)
  const user = auth.user
  const userId = user?.firebaseUser?.uid
  const [locationPointTypes, setLocationPointTypes] = useState<Record<string, LocationPointType>>({})
  const [locationPoints, setLocationPoints] = useState<LocationPoint[] | undefined>()
  const [filters, setFilters] = useState({})
  const [showRemoved, setShowRemoved] = useState<{ from: number, to: number } | undefined>()
  const [isRemovedDialogVisible, setIsRemovedDialogVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [sliderValue, setSliderValue] = useState<number[]>()
  const [sliderRange, setSliderRange] = useState<number[]>()

  const locationPointsRef = useRef<any>(null)

  const back = () => {
    if (!isSafari())
      setFullscreen(false).catch((err) => {
        console.log(err)
      })
    history.push("/")
  }

  useEffect(() => {
    if (!selectedCompany || !user) back()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, user])

  const initialCoordinates = [62.74, 25.4]

  useEffect(() => {
    selectedProject
      ?.getLocationPointTypes()
      .then((typesArray) => {
        // convert to objects for easier access
        const locationPointTypes = typesArray.reduce((obj, data) => {
          obj[data.id] = data;
          return obj;
        }, {});
        setLocationPointTypes(locationPointTypes)
      })
      .catch((err) => {
        console.error(err);
        alert("Pisteiden lataaminen epäonnistui");
      });

      getLocationPoints()

    return () => {
      if (selectedProject) selectedProject.removeListeners()
      handleSliderChange.cancel()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getLocationPoints = () => {
    const handleResult = (error, locationPoints) => {
      if (error) {
        console.error(error);
        alert("Pisteiden lataaminen epäonnistui")
      } else {
        locationPointsRef.current = locationPoints
        // Set slider range and initial values
        let oldestPointAge: number | undefined
        locationPoints.forEach((point) => {
          const age = calculateAge(new Date(point.dateCreated));
          if (!oldestPointAge || age > oldestPointAge) oldestPointAge = age;
        });
        const initialValues = [0, oldestPointAge || 0]
        setSliderRange(initialValues)
        setSliderValue(initialValues)
      }
    }

    selectedProject?.removeListeners()
    setLocationPoints(undefined)

    if (showRemoved) {
      selectedProject?.listenRemovedLocationPoints(
        showRemoved.from,
        showRemoved.to,
        handleResult
      )
    } else {
      selectedProject?.listenLocationPoints(handleResult);
    }
  }

  useEffect(() => {
    getLocationPoints()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRemoved, isRemovedDialogVisible])

  // Filters points and then sets to state
  // Previously "setLocationPoints"
  const handleLocationPoints = () => {
    const locationPoints = locationPointsRef.current
    if (!filters) {
      setIsLoading(false)
      return
    }
    const points = locationPoints?.filter((point) => {
      // Detect oldest point
      const age = calculateAge(new Date(point.dateCreated))
      // Filter by range
      if (sliderValue?.[0] && age < sliderValue[0]) return false
      if (sliderValue?.[1] && age > sliderValue[1]) return false

      //Filter by toggle filters
      const isAging =
        locationPointTypes && locationPointTypes[point.type]
          ? locationPointTypes[point.type].aging || false
          : false;
      if (
        isAging &&
        isDated(locationPointTypes[point.type].aging, point.dateCreated)
      )
        return filters[`aging_${point.type}`] ? false : true; // Remove if filter exists
      return filters[point.type] ? false : true;
    })
    setLocationPoints(points)
    setIsLoading(false)
  }

  const handleSliderChange = debounce(() => {
    handleLocationPoints()
  }, 100)

  useEffect(() => {
    handleLocationPoints()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    handleSliderChange()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderValue])

    return (
      <div>
        <MapNavBar
          onBack={back}
          title={selectedProject?.info.name}
          onExportCSV={() => {
            setIsLoading(true)
            exportPointsToCsv(
              locationPoints,
              locationPointTypes,
              selectedProject,
              !!showRemoved
            )
              .catch((err) => {
                console.error("Failed to export data: ", err);
                alert("Tiedoston lataus epäonnistui");
              })
              .finally(() => {
                setIsLoading(false)
              })
          }}
        />
        <div className="map-container">
          <Map
            locationPoints={locationPoints!}
            locationPointTypes={locationPointTypes!}
            initialCoordinates={initialCoordinates}
            project={selectedProject!}
            showRemoved={!!showRemoved}
            userId={userId}
            isLoading={isLoading}
            setLoading={setIsLoading}
          />

          <MarkerFilters
            types={locationPointTypes}
            onChange={(filters) => {
              setFilters(filters)
            }}
            filters={filters}
            onShowRemoved={(removed) => {
              if (removed) {
                const to = getEndOfDate(new Date(), true);
                const lastMonth = new Date();
                lastMonth.setMonth(lastMonth.getMonth() - 1);
                const from = getBeginningOfDate(lastMonth, true)
                setIsRemovedDialogVisible(true)
                setShowRemoved({ from, to })
              } else {
                setShowRemoved(undefined)
              }
            }}
            showRemoved={showRemoved}
            onSliderChange={(sliderValue) => {
              setSliderValue(sliderValue)
            }}
            sliderValue={sliderValue}
            sliderRange={sliderRange}
          />

          {isRemovedDialogVisible && showRemoved && (
            <RemovedModal
              showRemoved={showRemoved}
              onDone={(values) => {
                setShowRemoved(values)
                setIsRemovedDialogVisible(false)
              }}
              onCancel={() => {
                setShowRemoved(undefined)
                setIsRemovedDialogVisible(false)
              }}
            />
          )}
        </div>
      </div>
    )
  }

export default MapPage

// https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi3_hdpi.png

import { SET_CURRENT_USER } from '../actions/types';
import isEmpty from 'lodash/isEmpty'

const initialState = {
	isAuthenticated: null,
	user: null
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case SET_CURRENT_USER:
			return {
				isAuthenticated: (action.user != null && !isEmpty(action.user.firebaseUser)),
				user: action.user,
			};
		default: return state;
	}
}
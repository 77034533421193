import { SET_COMPANIES } from '../actions/types'
import { RESET_APP_STATE } from '../actions/types'

const initialState = []

export default (state = initialState, action = {}) => {
	switch (action.type) {

		case SET_COMPANIES:
			return action.companies ? [...action.companies] : []

		case RESET_APP_STATE:
			return []

		default:
			return state

	}
}
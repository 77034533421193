import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import BorderlessButton from '../borderless-button';
import './selectable-card.css';

export default class SelectableCard extends Component {

	constructor(props) {
		super(props)
		this.deleting = false
		this.fileAdded = this.fileAdded.bind(this)
	}

	fileAdded(e) {
		const file = e.target.files[0]
		if (!file) return
		const { maxFileSize, onFileUpload } = this.props // IN MEGABYTES
		const mbytes = file.size/1000/1000
		if (maxFileSize && mbytes > maxFileSize) {
			alert("Tiedoston koko maksimissaa "+maxFileSize+"MB")
			e.target.value = '';
		} else {
			onFileUpload(file)
		}
	}

	render() {

		const { id, leftIcon, rightIcon, onSelect, selected, tier, text, subText, highlighted, disabled, deleteText, onDelete, value, small, type, onDateChanged, dateMin, dateMax, accept } = this.props

		return (
			<div className="card selectable-card"
				onClick={() => {
					if (!disabled && !selected && onSelect) {
						if (this.deleting) return this.deleting = false
						else onSelect(id)
					}
				}}
				data-selectable={!!onSelect || !!onDateChanged}
				data-selected={selected}
				data-tier={tier}
				data-highlighted={highlighted}
				data-small={small}
				data-disabled={disabled}
				>
				{ type === SelectableCard.Type.FILE_UPLOAD ?
					<input className="file-input" type="file" accept={accept} onChange={this.fileAdded}/>
					: null
				}
				{ type === SelectableCard.Type.MONTH_PICKER ?
					<DatePicker
						onChange={onDateChanged}
						minDetail="year"
						maxDetail="year"
						minDate={dateMin}
						maxDate={dateMax}
					/>
					: null
				}
				<div className="center-content">
					{ text && <p>{text}</p> }
					{ subText && <small>{subText}</small> }
				</div>

				<div className="aside left-content">
					{ leftIcon && leftIcon }
				</div>

				{ onDelete || rightIcon ?
					<div className="aside right-content">
						{ rightIcon && rightIcon }
						{ onDelete && (
						<BorderlessButton
							title={deleteText ? deleteText : null}
							icon={deleteText ? null : <TrashIcon className="icon-medium" />}
							tier={3}
							disabled={disabled}
							onClick={() => {
								this.deleting = true
								onDelete(value ? value : id)
							}}
							style={{marginLeft: '8px'}}
							/>
						)}
					</div>
					: null
				}

            </div>

		)

	}

	static Type = Object.freeze({
		DEFAULT: "default",
		FILE_UPLOAD: "file_upload",
		MONTH_PICKER: "month_picker"
	})

	static propTypes = {
		id: PropTypes.string,
		text: PropTypes.string.isRequired,
		subText: PropTypes.string,
		leftIcon: PropTypes.any,
		rightIcon: PropTypes.any,
		value: PropTypes.any,
		highlighted: PropTypes.bool,
		selected: PropTypes.bool,
		onSelect: PropTypes.func,
		onDelete: PropTypes.func,
		deleteText: PropTypes.string,
		disabled: PropTypes.bool,
		tier: PropTypes.number,
		small: PropTypes.bool,
		type: PropTypes.string,

		accept: PropTypes.string,
		onFileUpload: PropTypes.func,
		maxFileSize: PropTypes.number,

		onDateChanged: PropTypes.func,
		dateMin: PropTypes.instanceOf(Date),
		dateMax: PropTypes.instanceOf(Date)
	}

	static defaultProps = {
		id: null,
		highlighted: null,
		selected: false,
		disabled: false,
		tier: 1,
		value: null,
		small: false,
		type: SelectableCard.Type.DEFAULT,
	}

}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './button.css';


export default class Button extends Component {

	render() {

		const { id, className, title, submit, type, disabled, noMargin, outline, highlightOnFocus, onClick, minWidth, float, marginEnd, hidden, children, style } = this.props

		var classN = "btn btn-"
		if (outline) classN += "outline-"
		switch (type) {
			case Button.Type.PRIMARY:
			classN += "primary"
				break
			case Button.Type.SUCCESS:
			classN += "success"
				break
			case Button.Type.WARNING:
			classN += "warning"
				break
			case Button.Type.DANGER:
			classN += "danger"
				break
		}
		if (className) classN += " "+className;
		if (float) classN += " float-"+float

		const styling = {
			marginTop: !noMargin ? "12px" : null,
			marginBottom: !noMargin ? "8px" : null,
			minWidth: minWidth ? minWidth : null,
			marginRight: marginEnd ? marginEnd : null,
			display: hidden ? "none" : null
		}

		if (style) {Object.assign(styling, style)}

		return (
			<button
				id={id}
				type={submit ? 'submit' : 'button'}
				className={classN}
				disabled={disabled}
				onClick={onClick}
				data-type={type}
				data-highlight-on-focus={highlightOnFocus}
				style={styling}>
				{title}
				{children}
			</button>
		)
	}

	static Type = Object.freeze({
		PRIMARY: "primary",
		SECONDARY: "secondary",
		SUCCESS: "success",
		WARNING: "warning",
		DANGER: "danger"
	})

	static propTypes = {
		id: PropTypes.string,
		title: PropTypes.string,
		outline: PropTypes.bool,
		highlightOnFocus: PropTypes.bool,
		onClick: PropTypes.func.isRequired,
		type: PropTypes.string,
		disabled: PropTypes.bool,
		minWidth: PropTypes.string,
		noMargin: PropTypes.bool,
		marginEnd: PropTypes.string,
		hidden: PropTypes.bool,
		float: PropTypes.string,
		style: PropTypes.object,
		submit: PropTypes.bool,
	}
	
	static defaultProps = {
		type: Button.Type.PRIMARY,
		outline: false,
		noMargin: false,
		highlightOnFocus: true,
		disabled: false,
		hidden: false,
	}

}
import { fService } from "../services/firebaseService"
import AnalyticsProject from "./AnalyticsProject"
import { sortByProperty, guid } from '../lib/Helper'
import LocationPoint, { KEY_DATE_CREATED , KEY_LATITUDE, KEY_LONGITUDE, KEY_TYPE, KEY_TITLE, KEY_IS_LOCKED} from "./LocationPoint"
import LocationPointType from "./LocationPointType"
import LocationPointData from "./LocationPointData"
import File from "./File"
import {isEmpty} from 'lodash'

export default class Project {

	constructor(id, info, analyticsProject, files, isOwnProject) {
		this.id = id
		this.info = info
		this.analyticsProject = analyticsProject
		this.files = files
		this.isOwnProject = isOwnProject
		this.invitedCompanies = undefined
	}

	static loadProject(id, ownCompanyBusinessId) {
		return new Promise((resolve, reject) => {
			// if (this.info) return resolve()
			fService.FREF_PROJECTS.child(id).child(fService.PATH_INFO).once('value', snap => {
				const data = snap.val()
				const info = new Project.Info(data.business_id, data.name, data.description, data.author, data.date_created)
				const files = []
				for (const id in data.files) {
					files.push(new File(id, data.files[id]))
				}
				if (info.businessId === ownCompanyBusinessId) {
					AnalyticsProject.getProject(info.businessId, id).then((analyticsProject) => {
						resolve(new Project(id, info, analyticsProject, files, true))
					})
				} else resolve(new Project(id, info, null, files, false))
			}).catch(console.error)
		})
	}

	getInvitedCompanies(callback, refresh = false) {
		if (this.invitedCompanies && !refresh) {
			return callback(this.invitedCompanies)
		}

		fService.FREF_PROJECTS.child(this.id).child(fService.PATH_INVITED_COMPANIES).once('value', snap => {
			const invitedCompanies = snap.val()
			const companies = []
			for (const companyId in invitedCompanies) {
				companies.push(companyId)
			}
			this.invitedCompanies = companies
			callback(companies, true)
		})
	}


	saveNewPoint = (lat, long, type, author, title, fields, imageFiles) => {
		return new Promise((resolve, reject) => {
			const dateCreated = fService.ServerValue.TIMESTAMP
			const description = fields ? (fields.lisatiedot ? fields.lisatiedot.trim() : undefined) : undefined
			const data = {...fields}
			delete data["lisatiedot"]
			const businessId = this.info.businessId
			if (!businessId) return reject("No business identifier")
			const authorBusinessId = author.businessId
			const authorId = author.id
			// Note: Lisatiedot kenttä on lisätty manuaalisesti hallintajärjestelmän koodiin fieldeihin. Todellisuudessa sitä ei ole loc point typessä. Siksi se myös poistetaan fieldeistä kun data tallennetaan

			let pointDict = {[KEY_LATITUDE]: lat, [KEY_LONGITUDE]: long, [KEY_DATE_CREATED]: dateCreated, [KEY_TYPE]: type}
			if (title) pointDict.title = title
			let dataDict = {"author": authorId}
			if (description) {
				dataDict["description"] = description
			}
			if (data && !isEmpty(data)) {
				dataDict["data"] = data
			}

			Promise.all(
				imageFiles.map(file => {
					const uuid = guid()
					if (!dataDict["images"]) dataDict["images"] = {}
					dataDict["images"][uuid] = true
					fService.SREF_BASE.child(this.id).child(fService.PATH_LOCATION_POINT_IMAGES).child(uuid).put(file)
				})
			)
				.then(() => {
					const analyticsDict = {[KEY_DATE_CREATED]: dateCreated, "project_id": this.id, "author_business_id": authorBusinessId}

					// UPDATE VALUES: https://firebase.google.com/docs/database/web/read-and-write
					var updates = {}
					const pointId = guid()
					updates[`projects/${this.id}/${fService.PATH_LOCATION_POINT_DATA}/${pointId}`] = dataDict
					updates[`projects/${this.id}/${fService.PATH_LOCATION_POINTS}/${pointId}`] = pointDict
					updates[`analytics/${businessId}/${fService.PATH_LOCATION_POINTS}/${pointId}`] = analyticsDict

					fService.FREF_BASE.update(updates)
						.then(resolve)

				})
				.catch(err => {
					console.error(err)
					reject()
				})
		})
	}

	/**
	 *
	 * @param {*} pointId
	 * @param {*} title
	 * @param {*} fields
	 * @param {*} originalImageIds
	 * @param {*} imagesToRemove
	 * @param {*} imageFilesToUpload
	 * @param {String=} isLocked Prevents users from adding new points to the map
	 */
	editLocationPoint = (pointId, title, fields, originalImageIds, imagesToRemove, imageFilesToUpload, isLocked) => {
		return new Promise((resolve, reject) => {
			const description = fields ? (fields.lisatiedot ? fields.lisatiedot.trim() : null) : null
			const data = {...fields}
			delete data["lisatiedot"]

			var updates = {}
			updates[`${this.id}/${fService.PATH_LOCATION_POINT_DATA}/${pointId}/description`] = description
			if (data && !isEmpty(data)) {
				updates[`${this.id}/${fService.PATH_LOCATION_POINT_DATA}/${pointId}/data`] = data
			}
			if (title) {
				updates[`${this.id}/${fService.PATH_LOCATION_POINTS}/${pointId}/${KEY_TITLE}`] = title
			}
			if (isLocked != null ) {
				updates[`${this.id}/${fService.PATH_LOCATION_POINTS}/${pointId}/${KEY_IS_LOCKED}`] = isLocked
			}
			const images = {}
			originalImageIds.forEach(id => {
				if (imagesToRemove.includes(id)) return
				images[id] = true
			})
			updates[`${this.id}/${fService.PATH_LOCATION_POINT_DATA}/${pointId}/images`] = images

			Promise.all([
				...imagesToRemove.map(imageId =>
					fService.SREF_BASE.child(this.id).child(fService.PATH_LOCATION_POINT_IMAGES).child(imageId).delete()
				),
				...imageFilesToUpload.map(file => {
					const uuid = guid()
					images[uuid] = true
					fService.SREF_BASE.child(this.id).child(fService.PATH_LOCATION_POINT_IMAGES).child(uuid).put(file)
				})
			])
				.then(() => {
					fService.FREF_PROJECTS.update(updates)
						.then(resolve)
				})
				.catch(err => {
					console.error(err)
					reject()
				})
		})
	}

	getLocationPointTypes() {
		return new Promise((resolve, reject) => {
			fService.FREF_PROJECTS.child(this.id).child(fService.PATH_LOCATION_POINT_TYPES).once('value', snap => {
				const locationPointTypes = snap.val()
				const locPointTypes = []
				for (const typeId in locationPointTypes) {
					const type = locationPointTypes[typeId]
					const fields = []
					for (const fieldId in type[fService.PATH_DATA]) {
						const field = type[fService.PATH_DATA][fieldId]
						fields.push(new LocationPointType.Field(fieldId, field.title, LocationPointType.Field.getDataTypeFromDataTypeString(field.type), field.required ? true : false, LocationPointType.Field.Type.GET_FROM_DATABASE))
					}
					fields.push(LocationPointType.descriptionField())
					const locPointType = new LocationPointType(typeId, type.name, type.icon, type.aging ? type.aging : false, fields, type.locked)
					locPointTypes.push(locPointType)
				}
            sortByProperty(locPointTypes, 'iconId')

				resolve(locPointTypes)
			})
		})
	}


	//LISTEN
	listenLocationPoints(callback) {
		fService.FREF_PROJECTS.child(this.id).child(fService.PATH_LOCATION_POINTS).on('value', snap => {
			const locPoints = LocationPoint.objectsFromData(snap.val())
			callback(null, locPoints)
		})
	}
	listenRemovedLocationPoints(dateFrom, dateTo, callback) {
		fService.FREF_PROJECTS.child(this.id).child(fService.PATH_REMOVED_LOCATION_POINTS).orderByChild("date_removed").startAt(dateFrom).endAt(dateTo).on('value', snap => {
			const locationPoints = snap.val()
			const locPoints = []
			for (const locPointId in locationPoints) {
				const locPoint = locationPoints[locPointId]
				locPoints.push(new LocationPoint(locPointId, locPoint.type, [locPoint.lat, locPoint.long], undefined, locPoint.date_created, locPoint.date_removed || 0, locPoint.title))
			}
			callback(null, locPoints)
		})
	}
	removeListeners() {
		fService.FREF_PROJECTS.child(this.id).child(fService.PATH_LOCATION_POINTS).off()
		fService.FREF_PROJECTS.child(this.id).child(fService.PATH_REMOVED_LOCATION_POINTS).off()
	}




	// GET ONCE
	getLocationPoints() {
		return new Promise((resolve, reject) => {
			fService.FREF_PROJECTS.child(this.id).child(fService.PATH_LOCATION_POINTS).once('value', snap => {
				const locPoints = LocationPoint.objectsFromData(snap.val())
				resolve(locPoints)
			})
		})
	}
	getRemovedLocationPoints() {
		return new Promise((resolve, reject) => {
			fService.FREF_PROJECTS.child(this.id).child(fService.PATH_REMOVED_LOCATION_POINTS).once('value', snap => {
				const locationPoints = snap.val()
				const locPoints = []
				for (const locPointId in locationPoints) {
					const locPoint = locationPoints[locPointId]
					locPoints.push(new LocationPoint(locPointId, locPoint.type, [locPoint.lat, locPoint.long], undefined, locPoint.date_created, locPoint.date_removed || 0, locPoint.title))
				}
				resolve(locPoints)
			})
		})
	}

	/* LOCATION POINT DATA */
	// SINGLE POINT
	getLocationPointData = (pointId) => {
		return new Promise((resolve, reject) => {
			fService.FREF_PROJECTS.child(this.id).child(fService.PATH_LOCATION_POINT_DATA).child(pointId).once('value', snap => {
				const data = snap.val()
				if (!data) reject()
				const locPointData = LocationPointData.objectFromData(data)
				resolve(locPointData)
			})
		})
	}
	getRemovedLocationPointData = (pointId) => {
		return new Promise((resolve, reject) => {
			fService.FREF_PROJECTS.child(this.id).child(fService.PATH_REMOVED_LOCATION_POINT_DATA).child(pointId).once('value', snap => {
				const data = snap.val()
				if (!data) reject()
				const locPointData = LocationPointData.objectFromData(data)
				resolve(locPointData)
			})
		})
	}

	// ALL POINTS IN PROJECT
	getLocationPointsData() {
		return new Promise((resolve, reject) => {
			fService.FREF_PROJECTS.child(this.id).child(fService.PATH_LOCATION_POINT_DATA).once('value', snap => {
				const locationPointsData = snap.val()
				const locPointsData = {}
				for (const locPointId in locationPointsData) {
					const locPointData = locationPointsData[locPointId]
					locPointsData[locPointId] = LocationPointData.objectFromData(locPointData)// new LocationPointData(locPointId, locPointData.author, locPointData.description, locPointData.data)
				}
				resolve(locPointsData)
			})
		})
	}

	getRemovedLocationPointsData() {
		return new Promise((resolve, reject) => {
			fService.FREF_PROJECTS.child(this.id).child(fService.PATH_REMOVED_LOCATION_POINT_DATA).once('value', snap => {
				const locationPointsData = snap.val()
				const locPointsData = {}
				for (const locPointId in locationPointsData) {
					const locPointData = locationPointsData[locPointId]
					locPointsData[locPointId] = LocationPointData.objectFromData(locPointData)//new LocationPointData(locPointId, locPointData.author, locPointData.description, locPointData.data)
				}
				resolve(locPointsData)
			})
		})
	}


	/* Removes location point and loc point data and creates item to deleted location point data. */
	movePointToRemoved = (locationPoint, locationPointData, userId) => {
		return new Promise((resolve, reject) => {
			const pointId = locationPoint.id
			let pointDict = locationPoint.getDict()
			const timestamp = fService.ServerValue.TIMESTAMP
			pointDict.date_removed = timestamp
			let dataDict = locationPointData.getDict()
			dataDict.remover = userId
			// Remove image files
			if (locationPointData.images) {
				for (const imageId in locationPointData.images) {
					fService.SREF_BASE.child(this.id).child(fService.PATH_LOCATION_POINT_IMAGES).child(imageId).delete()
				}
			}

			// UPDATE VALUES: https://firebase.google.com/docs/database/web/read-and-write
			var updates = {}
			updates[`${this.id}/${fService.PATH_REMOVED_LOCATION_POINT_DATA}/${pointId}`] = dataDict
			updates[`${this.id}/${fService.PATH_REMOVED_LOCATION_POINTS}/${pointId}`] = pointDict
			updates[`${this.id}/${fService.PATH_LOCATION_POINT_DATA}/${pointId}`] = null
			updates[`${this.id}/${fService.PATH_LOCATION_POINTS}/${pointId}`] = null

			fService.FREF_PROJECTS.update(updates)
				.then(() => {
					resolve()
				})
				.catch(err => {
					console.error(err)
					reject()
				})
		});
	}

	restorePoint = (locationPoint, locationPointData) => {
		return new Promise((resolve, reject) => {
			const pointId = locationPoint.id
			const pointDict = locationPoint.getDict()
			const dataDict = locationPointData.getDict()

			var updates = {}
			updates[`${this.id}/${fService.PATH_REMOVED_LOCATION_POINT_DATA}/${pointId}`] = null
			updates[`${this.id}/${fService.PATH_REMOVED_LOCATION_POINTS}/${pointId}`] = null
			updates[`${this.id}/${fService.PATH_LOCATION_POINT_DATA}/${pointId}`] = dataDict
			updates[`${this.id}/${fService.PATH_LOCATION_POINTS}/${pointId}`] = pointDict

			fService.FREF_PROJECTS.update(updates)
				.then(result => {
					resolve()
				})
				.catch(err => {
					console.error(err)
					reject()
				})
		})
	}




	inviteCompany(businessId, callback) {
		if (businessId === this.info.businessId) return callback(false)
		fService.FREF_BASE.update({
			[`${fService.PATH_PROJECTS}/${this.id}/${fService.PATH_INVITED_COMPANIES}/${businessId}`]: true,
			[`${fService.PATH_COMPANIES}/${businessId}/${fService.PATH_PROJECTS}/${this.id}`]: true
		}, (error) => {
			if (!error) callback(true)
			else callback(false)
		})
	}

	kickCompany(businessId, callback) {
		fService.FREF_BASE.update({
			[`${fService.PATH_PROJECTS}/${this.id}/${fService.PATH_INVITED_COMPANIES}/${businessId}`]: null,
			[`${fService.PATH_COMPANIES}/${businessId}/${fService.PATH_PROJECTS}/${this.id}`]: null
		}, (error) => {
			if (!error) callback(true)
			else callback(false)
		})
	}

	refreshData() {
		// return new Promise((resolve, reject) => {
		this.getInvitedCompanies((success) => {}, true)

		fService.FREF_PROJECTS.child(this.id).child(fService.PATH_INFO).once('value', snap => {
			const data = snap.val()
			var dataChanged = false
			if (this.info.businessId !== data.business_id) {
				this.info.businessId = data.business_id
				dataChanged = true
			}
			if (this.info.name !== data.name) {
				this.info.name = data.name
				dataChanged = true
			}
			if (this.info.description !== data.description) {
				this.info.description = data.description
				dataChanged = true
			}
			if (this.info.author !== data.author) {
				this.info.author = data.author
				dataChanged = true
			}

			var refreshFiles = false
			for (const id in data.files) {
				let contains = false
				for (const file of this.files) {
					if (id === file.id) {
						contains = true
						break
					}
				}
				if (!contains) {
					refreshFiles = true
					break
				}
			}
			if (!refreshFiles) {
				for (const file of this.files) {
					let contains = false
					for (const id in data.files) {
						if (file.id === id) {
							contains = true
						}
					}
					if (!contains) {
						refreshFiles = true
						break
					}
				}
			}
			if (refreshFiles) {
				const files = []
				for (const id in data.files) {
					files.push(new File(id, data.files[id]))
				}
				this.files = files
				dataChanged = true
			}

			// resolve(dataChanged)
		})
		// })
	}

	uploadFile(file, fileName, onProgress, callback) {
		const uuid = guid()

		const task = fService.SREF_BASE.child(this.id).child(fService.PATH_FILES).child(uuid).put(file)
		task.on('state_changed',
			(snapshot) => {
				onProgress(Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100))
			},
			() => {
				callback(false)
			},
			() => {
				fService.FREF_PROJECTS.child(this.id).child(fService.PATH_INFO).child(fService.PATH_FILES).update({[uuid]: fileName})
				task.snapshot.ref.getDownloadURL()
					.then(downloadURL => {
						this.files.push(new File(uuid, fileName, downloadURL))
						callback(true)
					})
			}
		)
	}

	getFileDownloadUrl = (imageId) => {
		return new Promise((resolve, reject) => {
			fService.SREF_BASE.child(this.id).child(fService.PATH_LOCATION_POINT_IMAGES).child(imageId).getDownloadURL()
				.then((url) => {
					resolve(url)
				})
				.catch(err => {
					console.error(err)
					reject()
				})
		});
	}

	deleteFile(fileId, callback) {
		fService.SREF_BASE.child(this.id).child(fService.PATH_FILES).child(fileId)
			.delete()
			.then(() => {
				fService.FREF_PROJECTS.child(this.id).child(fService.PATH_INFO).child(fService.PATH_FILES).child(fileId).remove()
				callback(true)
			})
			.catch(() => {
				callback(false)
			})
	}

	// Removes all images from project
	deleteLocationPointImages(callback) {
		fService.FREF_PROJECTS.child(this.id).child(fService.PATH_LOCATION_POINTS).once('value', snap => {
			const locationPoints = snap.val()
			if (!locationPoints) return callback(true)
			for (const locPointId in locationPoints) {

				fService.FREF_PROJECTS.child(this.id).child(fService.PATH_LOCATION_POINT_DATA).child(locPointId).child(fService.PATH_IMAGES).once('value', snap => {
					const images = snap.val()

					for (const imageId in images) {
						fService.SREF_BASE.child(this.id).child(fService.PATH_LOCATION_POINT_IMAGES).child(imageId).delete()
						fService.FREF_PROJECTS.child(this.id).child(fService.PATH_LOCATION_POINT_DATA).child(locPointId).child(fService.PATH_IMAGES).child(imageId).remove()
					}
					callback(true)
				})
			}
		})
	}


	// NOT IN USE
	deleteLocPointImageFile = (imageId, locPointId) => {
		fService.SREF_BASE.child(this.id).child(fService.PATH_LOCATION_POINT_IMAGES).child(imageId).delete()
		////Next line commented since: There is no point removing image url from locationpointdata since when project is removed,
		fService.FREF_PROJECTS.child(this.id).child(fService.PATH_LOCATION_POINT_DATA).child(locPointId).child(fService.PATH_IMAGES).child(imageId).remove()
		/* const del = () => {
			fService.FREF_PROJECTS.child(this.id).child(fService.PATH_LOCATION_POINT_DATA).child(locationPointId).child(fService.PATH_IMAGES).child(imageId).removeValue()
		}
		fService.SREF_BASE.child(this.id).child(fService.PATH_LOCATION_POINT_IMAGES).child(imageId).delete().then(() => {
			del()
		}).catch((e) => {
			if (e.code === 'storage/object_not_found') del()
		}) */
	}

	/* static loadAnalyticsProject(id, businessId) {
		return new Promise((resolve, reject) => {
			// if (this.analytics) return resolve()
			AnalyticsProject.getProject(businessId, id).then((analyticsProject) => {
				this.analytics = analyticsProject
				resolve(new Project())
			})
		})
	} */

}

Project.Info = class {

	constructor(businessId, name, description, author, dateCreated) {
		this.businessId = businessId
		this.name = name
		this.description = description
		this.author = author
		this.dateCreated = dateCreated
	}

}

import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import { COLOR_PRIMARY } from '../../constants';

const color = COLOR_PRIMARY

type RangeSliderProps = {
	min: number
	max: number
	reverse?: boolean
	value?: number[]
	onChange: (value: number[]) => void
}

const RangeSlider: React.FC<RangeSliderProps> = ({ min, max, reverse = false, value, onChange }) => (
	<div style={{width: '100%'}}>
		<Range
			onChange={onChange}
			handleStyle={[{
				borderColor: color,
				backgroundColor: color,
			},
			{
				borderColor: color,
				backgroundColor: color,
			}]}
			allowCross={false}
			trackStyle={[{backgroundColor: color}]}
			reverse={reverse}
			value={value}
			min={min}
			max={max}
		/>
	</div>
)

export default RangeSlider

import { fService } from "../services/firebaseService"
import Project from "./Project"

export default class AnalyticsProject {

	constructor(id, name, dateCreated, removed) {
		this.id = id
		this.name = name
		this.dateCreated = dateCreated
		this.removed = removed
		this.projectInfo = null
	}

	static getProject(businessId, projectId) {
		return new Promise((resolve, reject) => {
			fService.FREF_ANALYTICS.child(businessId).child(fService.PATH_PROJECTS).child(projectId).once('value', snap => {
				const data = snap.val()
				resolve(new AnalyticsProject(this.id, data.name, data.date_created, data.removed))
			})
		})
	}


	getProjectInfo() {
		return new Promise((resolve, reject) => {
			if (this.projectInfo) return resolve()
			fService.FREF_PROJECTS.child(this.id).child(fService.PATH_INFO).once('value', snap => {
				const data = snap.val()
				this.projectInfo = new Project.Info(data.business_id, data.name, data.description, data.author, data.date_created)
				resolve()
			})
		})
	}

}
import React from 'react';
// @ts-ignore
import { ReactComponent as CSVIcon } from "../../assets/csv.svg";
// @ts-ignore
import { ReactComponent as ArrowLeftIcon } from "../../assets/left.svg";
import BorderlessButton from '../../lib/borderless-button/borderless-button';

type Props = {
  onBack: () => void
  title: string
  onExportCSV: () => void
}

const MapNavBar: React.FC<Props> = ({ onBack, title, onExportCSV }) => (
  <div className="map-navbar">
    <div>
      <BorderlessButton
        className="navbar-icon"
        tier={1}
        icon={<ArrowLeftIcon className='icon-medium' />}
        onClick={onBack}
        shadowType={BorderlessButton.ShadowType.TEXT_SHADOW}
      />
      <h4 style={{ marginBottom: 0, color: "#fff", paddingLeft: "24px" }}>
        {title}
      </h4>
      <BorderlessButton
        title="lataa csv"
        className="navbar-icon map-csv-button"
        fontSize={"16px"}
        icon={<CSVIcon className='icon-medium' />}
        onClick={onExportCSV}
      />
    </div>
  </div>
)
export default MapNavBar

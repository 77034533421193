import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setUserInfo } from '../../actions/userInfosActions';
import { ReactComponent as BriefcaseIcon } from '../../assets/briefcase.svg';
import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import { ReactComponent as EnvelopeIcon } from '../../assets/envelope.svg';
import { ReactComponent as InfoCircleIcon } from '../../assets/info.svg';
import { ReactComponent as PhoneIcon } from '../../assets/phone.svg';
import BorderlessButton from '../../lib/borderless-button/borderless-button';
import Container from '../../lib/container';
import { showDangerConfirmDialog, showMessageDialog } from '../../lib/dialog-generator/dialog-generator';
import Dialog from '../../lib/dialog/dialog';
import { arrayRemoveItem, objectGetMissingProperties, promiseMerge, removeItemsByPropertysValue, sortByProperty } from '../../lib/Helper';
import LoadingSpinner from '../../lib/loading-spinner';
import Switch from '../../lib/switch/switch';
import UserInfo from '../../models/UserInfo';
import CompanyAnalyticsContainer from '../container-company-analytics/container-company-analytics';
import './container-company.css';


class CompanyContainer extends Component {

	constructor(props) {
		super(props)
		this.state = {
			showUsers: false,
			users: null,
			selectedUserId: null,
			selectedUserDialogAdminSwitchSelected: false,
			selectedUserSaveCompleted: false,
			savingSelectedUser: false
		}
		this.toggleShowUsers = this.toggleShowUsers.bind(this)
		this.saveSelectedUser = this.saveSelectedUser.bind(this)
	}

	toggleShowUsers() {
		//console.log("Company 1: ", this.props.company) //TODO: Comment
		const showUsers = !this.state.showUsers
		this.setState({ showUsers })
		if (showUsers && this.state.users === null) this.loadUsers()
		if (this.props.company.admins.length === 0) this.props.company.loadAdministrators().then(()=>{
			//console.log("Admin were not loaded: ", this.props.company)
			this.forceUpdate()
		})
	}

	loadUsers() {
		this.props.company.getUsers().then(userIds => {
			const missingIds = objectGetMissingProperties(this.props.userInfos, userIds)
			if (missingIds) {
				promiseMerge(UserInfo.getInfo, missingIds).then(userInfos => {
					for (const i in userInfos) {
						if (!this.props.userInfos[i]) this.props.setUserInfo(userIds[i], userInfos[i])
					}
					this.setUsers(userIds)
				})
			} else this.setUsers(userIds)
		})
	}

	setUsers(userIds) {
		const users = []
		for (const id of userIds) {
			users.push(this.props.userInfos[id])
		}
		sortByProperty(users, "lastName")
		this.setState({ users })
	}

	selectUser(id) {
		this.setState({
			selectedUserId: id,
			selectedUserDialogAdminSwitchSelected: this.props.company.admins.includes(id)
		})
	}

	saveSelectedUser() {
		const { selectedUserId, selectedUserDialogAdminSwitchSelected } = this.state
		const selectedUserIsCompanyAdmin = this.props.company.admins.includes(selectedUserId)

		if (selectedUserIsCompanyAdmin === selectedUserDialogAdminSwitchSelected) return

		this.props.company.setAdministrator(selectedUserId, selectedUserDialogAdminSwitchSelected).then(() => {
			if (selectedUserDialogAdminSwitchSelected) this.props.company.admins.push(selectedUserId)
			else arrayRemoveItem(this.props.company.admins, selectedUserId)
		}).catch((e) => {
			showMessageDialog(null, "Jotain meni vikaan, yritä myöhemmin uudelleen.", "OK")
		}).finally(() => {
			this.setState({
				selectedUserSaveCompleted: true
			})
		})
	}

	removeUser(id) {
		const uid = this.state.selectedUserId
		const user = this.props.userInfos[uid]
		showDangerConfirmDialog(null, `Haluatko varmasti poistaa käyttäjän ${user.firstName} ${user.lastName} yrityksestä?`, "Poista", "Peruuta", ()=>{
			this.props.company.removeUser(uid).then(()=>{
				const users = this.state.users
				removeItemsByPropertysValue(users, "id", uid)
				this.setState({ users })
				if (this.props.company.admins.includes(uid)) this.props.company.setAdministrator(uid, false)
			}).catch(console.error)
		})
	}

	render() {
		const { showUsers, users, selectedUserId, selectedUserSaveCompleted, selectedUserDialogAdminSwitchSelected, savingSelectedUser } = this.state
		const { company, userInfos, currentUser } = this.props
		const { isAdministrator, isCompanyAdmin } = currentUser

		const selectedUser = selectedUserId ? userInfos[selectedUserId] : null
		const selectedUserIsCompanyAdmin = (!company.admins ? false : company.admins.includes(selectedUserId))

		return (
			<div>
				<Container className='company-container' tier={2} title={ company.info.name } subTitle={company.info.businessId && (`Y-Tunnus: ${company.info.businessId}`)}>
					<Container className='users-container' tier={3} title="Henkilöstö" collapsable={Container.Collapsable.XL} onCollapse={this.toggleShowUsers}>
						{ !users || !company.admins ? (
							<LoadingSpinner />
						) : (
							<table className="table table-striped">
								<thead>
									<tr>
										<th scope="col">Nimi</th>
										{ (isCompanyAdmin || isAdministrator) && <th scope="col">Admin</th> }
										<th scope="col">Info</th>
									</tr>
								</thead>
								<tbody>
									{
										users.map((user) => {
											// console.log("USER ID: ", user.id)
											// console.log("IS USER ADMIN: ", company.admins.includes(user.id))
											return (
												<tr key={user.id} scope="col">
													<td>{user.lastName} {user.firstName}</td>
													{ (isCompanyAdmin || isAdministrator) &&
														<td className="td-admin">{
															company.admins.includes(user.id) ? (
																<CheckIcon className="icon-medium" style={{color: 'green'}} />
															) : (
																''
															)}
														</td>
													}
													<td className="td-info"><BorderlessButton icon={<InfoCircleIcon className="icon-medium" />} onClick={()=>this.selectUser(user.id)} type={BorderlessButton.Type.PRIMARY} tier={2} shadowType={BorderlessButton.ShadowType.NONE}/></td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
						)}
					</Container>
				</Container>
				<br/>
				<CompanyAnalyticsContainer />
				<br/>
				{ selectedUser && (
					<Dialog
						title={`${selectedUser.firstName} ${selectedUser.lastName}`}
						className="user-info-dialog"
						show={!!selectedUser}
						neutralButtonText="OK"
						closeOnNeutralButtonClick={ (selectedUserIsCompanyAdmin === selectedUserDialogAdminSwitchSelected) }
						onNeutralButtonClick={this.saveSelectedUser}
						leftNegativeButtonText={ ((isCompanyAdmin || isAdministrator) && selectedUserId !== currentUser.firebaseUser.uid) ? "Poista Yrityksestä" : null }
						onLeftNegativeButtonClick={ () => this.removeUser(selectedUserId) }
						close={selectedUserSaveCompleted}
						disabled={savingSelectedUser}
						onClose={() => {
							this.setState({
								savingSelectedUser: false,
								selectedUserSaveCompleted: false,
								selectedUserId: null
							})
						}}
					>
						<table className="table table-light">
							<tbody>
								<tr scope="col">
									<td className="td-icon"><BriefcaseIcon className="icon-medium" /></td>
									<td>{company.info.name}</td>
								</tr>
								{ selectedUser.phone && (
									<tr scope="col">
										<td><PhoneIcon className="icon-medium" /></td>
										<td>{selectedUser.phone}</td>
									</tr>
								)}
								<tr scope="col">
									<td><EnvelopeIcon className="icon-medium" /></td>
									<td>{selectedUser.email}</td>
								</tr>
							</tbody>
						</table>
						{ (isCompanyAdmin || isAdministrator) && (
							<Container tier={3} small>
								<Switch
									label="Admin"
									disabled={selectedUserId === currentUser.firebaseUser.uid}
									selected={selectedUserDialogAdminSwitchSelected}
									onChange={(selected) => this.setState({selectedUserDialogAdminSwitchSelected: selected})}
									bold noPadding />
							</Container>
						)}
					</Dialog>
				)}
			</div>
        )
	}

}

function mapStateToProps(state) {
	return {
		currentUser: state.auth.user,
		company: state.selectedCompany,
		userInfos: state.userInfos.infos,
	}
}

export default connect(mapStateToProps, { setUserInfo })(CompanyContainer);

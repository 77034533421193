import React, { useState } from 'react';
// @ts-ignore
import { ReactComponent as AngleUpIcon } from '../../assets/angle-up.svg'
// @ts-ignore
import LockIcon from '../../assets/lock.svg'
// @ts-ignore
import { ReactComponent as MinusIcon } from '../../assets/minus.svg'
import { dateToString, getIconColor, getIconColorAging } from '../../lib/Helper';
import BorderlessButton from '../../lib/borderless-button/borderless-button';
import RangeSlider from '../../lib/range-slider';
import Switch from '../../lib/switch/switch';
import LocationPointType from '../../models/LocationPointType'

interface MarkerFiltersProps {
    types: Record<string, LocationPointType>;
    filters: Record<string, boolean>;
    onChange: (filters: Record<string, boolean>) => void;
    showRemoved?: { from: number; to: number } | undefined;
    onShowRemoved: (showRemoved: boolean) => void;
    sliderRange?: number[];
    sliderValue?: number[];
    onSliderChange: (sliderValue: number[]) => void;
}

const MarkerFilters: React.FC<MarkerFiltersProps> = ({
    types,
    filters,
    onChange,
    showRemoved,
    onShowRemoved,
    sliderRange,
    sliderValue,
    onSliderChange,
}) => {
    const [isCollapsed, setCollapsed] = useState(false);

    const setFilters = (id: string, filter: boolean, aging: boolean) => {
        const newFilters = { ...filters };
        const _id = aging ? `aging_${id}` : id;
        if (newFilters[_id] && !filter) {
            delete newFilters[_id];
        } else {
            newFilters[_id] = true;
        }
        onChange(newFilters);
    };

    const collapsePressed = () => {
        setCollapsed(!isCollapsed);
    };

    return (
        <div className="map-inner-view map-filters">
            <div className="top-view">
                <BorderlessButton
                    className="collapse-btn"
                    icon={isCollapsed ? <AngleUpIcon className="icon-small" /> : <MinusIcon className='icon-small' /> } // icon-small
                    onClick={collapsePressed}
                    tier={1}
                    shadowType={BorderlessButton.ShadowType.NONE}
                />
            </div>
            <div className="content-view" style={{ display: isCollapsed ? 'none' : 'block' }}>
                {types &&
                    Object.values(types).map((type) => {
                        const selected = filters[type.id] ? false : true;
                        return (
                            <div key={type.id} className="marker-row">
                                <div className="marker" style={{ backgroundColor: getIconColor(type.iconId) }} />
                                <p>{type.iconId === 0 ? 'Huomio' : type.name}</p>
                                <div style={{ flexGrow: 1 }} />
                                {type.isLocked && <img src={LockIcon} style={{ width: '15px', marginRight: '6px' }} />}
                                <Switch selected={selected} onChange={(selected) => setFilters(type.id, !selected, false)} />
                            </div>
                        );
                    })}
                {types &&
                    Object.values(types).map((type) => {
                        if (!type.aging) return null;
                        const selected = filters[`aging_${type.id}`] ? false : true;
                        return (
                            <div key={type.id}>
                                <div style={{ borderTop: 'solid 1px #e1e1e1', height: '6px', background: 'none' }} />
                                <div key={type.id} className="marker-row">
                                    <div className="marker" style={{ backgroundColor: getIconColorAging(type.iconId) }} />
                                    <div>
                                        <p style={{ marginBottom: 0 }}>{type.name}</p>
                                        <p style={{ fontSize: '0.8rem', color: '#989898', marginBottom: 0, marginTop: '-4px' }}>(vanhentunut)</p>
                                    </div>
                                    <div style={{ flexGrow: 1 }} />
                                    <Switch selected={selected} onChange={(selected) => setFilters(type.id, !selected, true)} />
                                </div>
                            </div>
                        );
                    })}
                <div style={{ borderTop: 'solid 1px #e1e1e1', height: '6px', background: 'none' }} />
                <div className="marker-row">
                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, marginBottom: 4 }}>
                        <p style={{ margin: 0 }}>Hae poistetut</p>
                        <div style={{ flexGrow: 1 }} />
                        {showRemoved && showRemoved.from && showRemoved.to && (
                            <p style={{ margin: 0, fontWeight: 300 }}>
                                {dateToString(new Date(showRemoved.from), false)} - {dateToString(new Date(showRemoved.to), false)}
                            </p>
                        )}
                    </div>
                    <Switch selected={!!showRemoved} onChange={(on) => onShowRemoved(on)} />
                </div>
                <div style={{ borderTop: 'solid 1px #e1e1e1', height: '6px', background: 'none' }} />
                <div className="marker-row">
                    <div style={{ flexGrow: 1 }}>
                        <p style={{ margin: 0 }}>Pisteiden ikä</p>
                        <div style={{ padding: '12px 18px', paddingLeft: '6px', paddingBottom: '4px' }}>
                            <RangeSlider
                                onChange={onSliderChange}
                                min={sliderRange ? sliderRange[0] : 0}
                                max={sliderRange ? sliderRange[1] : 100}
                                value={sliderValue}
                            />
                        </div>
                        <div style={{ display: 'flex', marginBottom: '6px' }}>
                            <p style={{ margin: 0, fontWeight: 300 }}>{(sliderValue && sliderValue[0]) || '-'} vrk.</p>
                            <div style={{ flexGrow: 1 }} />
                            <p style={{ margin: 0, paddingRight: '12px', paddingLeft: '4px', fontWeight: 300 }}>{(sliderValue && sliderValue[1]) || '-'} vrk.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarkerFilters;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showMessageDialog } from '../../lib/dialog-generator/dialog-generator';
import Input from '../../lib/input/input';
import Button from '../../lib/button/button';
import './page-forgot-password.css'
import * as firebase from 'firebase';
import { ROUTE_LOGIN } from '../../utils/RouteUtils';

export class ForgotPasswordPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			email: '',
			isLoading: false,
			errorMessage: null
		}

		this.onChange = this.onChange.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value })
	}

	resetPassword() {
		const { email } = this.state
		if (!email) return
		this.setState({ isLoading: true })
		firebase.auth()
			.sendPasswordResetEmail(email).then(() => {
				showMessageDialog(null, "Säköpostiisi on onnistuneesti lähetetty salasanan palauttamis viesti.", "OK")
			})
			.catch((e)=>{
				switch (e.code) {
					case "auth/invalid-email":
						this.setState({errorMessage: "Virheellinen sähköpostiosoite"})
						break
					case "auth/user-not-found":
						this.setState({errorMessage: "Kyseisellä sähköpostilla ei ole luotu käyttäjää"})
						break
					default:
						showMessageDialog(null, "Jotain meni vikaan, yritä myöhemmin uudelleen.", "OK")
				}
				this.setState({ isLoading: false })
			})
	}


	render() {

		const { email, errorMessage, isLoading } = this.state;

		return (
			<div id="forgotPasswordForm">
				<h2 className="title">Unohditko salasanasi?</h2>

				<Input
					placeholder="Sähköpostiosoite"
					name="email"
					type="email"
					value={email}
					onChange={this.onChange}
					autoFocus
					errorMessage={errorMessage}
					disabled={isLoading}
				/>
				<Button
					title="Palauta salasana"
					minWidth="30%" float="right"
					disabled={isLoading}
					onClick={this.resetPassword}
				/>
				<Button
					title="Peruuta" minWidth="30%" marginEnd="8px" float="right"
					type={Button.Type.SECONDARY}
					disabled={isLoading}
					onClick={() => this.props.history.push(ROUTE_LOGIN) }
				/>
			</div>
		)
  	}

}

function mapStateToProps(state) {
	return {
		user: state.auth.user
	}
}

export default connect(mapStateToProps)(ForgotPasswordPage);
import { SET_SELECTED_COMPANY } from './types';
import { SET_SELECTED_PROJECT } from './types';
import { RESET_APP_STATE } from './types';


export function setCompany(company) {
	return {
		type: SET_SELECTED_COMPANY,
		company
	}
}

export function setProject(project) {
	return {
		type: SET_SELECTED_PROJECT,
		project
	}
}

export function resetAppState() {
	return {
		type: RESET_APP_STATE
	}
}
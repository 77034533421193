import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import Button from '../../lib/button/button'
import './page-company-locked.css'
import {signOut} from '../../services/authService'
import {showMessageDialog} from '../../lib/dialog-generator/dialog-generator'
import { ROUTE_JOIN_COMPANY } from '../../utils/RouteUtils';
import { ReactComponent as LockAltIcon } from '../../assets/lock-alt.svg'


export class CompanyLockedPage extends Component {

	leaveCompany = () => {
		this.props.user.leaveCompany()
			.then(() => {
				this.props.history.push(ROUTE_JOIN_COMPANY)
			})
			.catch((err) => {
				console.error(err)
				showMessageDialog(null, "Jotain meni vikaan, yritä myöhemmin uudelleen", "OK")
			})
	}

	render() {
		return (
			<div className="vh-center" style={{height: "80%"}}>
				<div className="company-locked-container">
					<LockAltIcon className="float-left space-right-s" />
					<h2>Yrityksellänne ei ole oikeutta käyttää sovellusta.</h2>
					<Button className="space-right" title="Kirjaudu ulos" onClick={signOut}/>
					<Button title="Vaihda yritystä" onClick={this.leaveCompany}/>
				</div>
			</div>
		)
  	}

}

function mapStateToProps(state) {
	return {
		user: state.auth.user
	}
}

export default withRouter(connect(mapStateToProps)(CompanyLockedPage))

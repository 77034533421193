import { guid } from '../lib/Helper'

export default class LocationPointType {

	/**
	 *
	 * @param {*} id
	 * @param {*} name
	 * @param {*} iconId
	 * @param {*} aging
	 * @param {*} fields
	 * @param {Boolean} isLocked If true point cannot be added to map
	 */
	constructor(id, name, iconId, aging, fields, isLocked) {
		this.id = id
		this.iconId = iconId
		this.name = name
		this.aging = aging
		this.fields = fields ? fields : [LocationPointType.descriptionField()]
		this.isLocked = isLocked
	}

	static newLocationPointType() { //iconId
		return new LocationPointType(guid(), '', null, false)
	}

	static attentionLocationPointType() {
		return new LocationPointType(guid(), "Huomio", 0, false);
	}

	static newField() {
		return new LocationPointType.Field(guid(), '', LocationPointType.Field.DataType.STRING, false, LocationPointType.Field.Type.GET_FROM_DATABASE)
	}

	static descriptionField() {
		return new LocationPointType.Field("lisatiedot", "Lisätiedot", LocationPointType.Field.DataType.ML_STRING, false, LocationPointType.Field.Type.DESCRIPTION)
   }

   static removedType() {
      return new LocationPointType("removedType", "(Poistettu)", -1, false, [])
   }

	static Field = class {

		static Type = Object.freeze({
			GET_FROM_DATABASE: 0,
			DESCRIPTION: 1
		})

		static getDataTypeFromDataTypeString(str) {
			switch (str) {
				case "num":
					return LocationPointType.Field.DataType.NUMBER
				case "ml_str":
					return LocationPointType.Field.DataType.ML_STRING
				case "str":
					return LocationPointType.Field.DataType.STRING
				case "bool":
					return LocationPointType.Field.DataType.BOOLEAN
			}
		}

		static DataType = Object.freeze({
			ML_STRING: "ml_str",
			STRING: "str",
			NUMBER: "num",
			BOOLEAN: "bool"
		})

		constructor(id, title, dataType, required, type) {
			this.id = id
			this.title = title
			this.dataType = dataType
			this.required = required
			this.type = type
		}

   }

}

import { fService } from "../services/firebaseService"
import LocationPointType from "./LocationPointType"
import isEmpty from 'lodash/isEmpty'

export default class NewProject {

	constructor(id, name, description, locationPointTypes) {
		this.id = id
		this.name = name
		this.description = description
		this.locationPointTypes = locationPointTypes
	}

	saveProject(editing, companyBusinessId, callback) {
		const types = {}
		var iconId = 0
		for (const locPointType of this.locationPointTypes) {
			const type = {}
			if (locPointType.aging) type.aging = parseInt(locPointType.aging)
			type.icon = locPointType.iconId === 0 ? locPointType.iconId : ++iconId
			type.name = locPointType.name
			if (locPointType.isLocked != null) type.locked = locPointType.isLocked
			const fields = {}
			for (const field of locPointType.fields) {
				if (field.type == LocationPointType.Field.Type.GET_FROM_DATABASE) {
					const data = {}
					data["required"] = field.required
					data["title"] = field.title
					data["type"] = field.dataType
					fields[field.id] = data
				}
			}
			if (Object.keys(fields).length > 0) type[fService.PATH_DATA] = fields
			types[locPointType.id] = type
		}


		const pathProjectInfo = `${fService.PATH_PROJECTS}/${this.id}/${fService.PATH_INFO}`
		const pathProjectLocationPointTypes = `${fService.PATH_PROJECTS}/${this.id}/${fService.PATH_LOCATION_POINT_TYPES}`
		const pathAnalyticProject = `${fService.PATH_ANALYTICS}/${companyBusinessId}/${fService.PATH_PROJECTS}/${this.id}`

		if (!editing) {
			const pathCompanyProject = `${fService.PATH_COMPANIES}/${companyBusinessId}/${fService.PATH_PROJECTS}/${this.id}`
			const payload = {
				[`${pathProjectInfo}/author`]: fService.uid,
				[`${pathProjectInfo}/name`]: this.name,
				[`${pathProjectInfo}/description`]: this.description,
				[`${pathProjectInfo}/business_id`]: companyBusinessId,
				[`${pathProjectInfo}/date_created`]: fService.ServerValue.TIMESTAMP,
				[pathProjectLocationPointTypes]: types,
				[pathCompanyProject]: true,
				[`${pathAnalyticProject}/date_created`]: fService.ServerValue.TIMESTAMP,
				[`${pathAnalyticProject}/name`]: this.name
			}

			fService.FREF_BASE.update(payload, (error) => {
				if (error) {
					callback(false)
				} else {
					callback(true)
				}
			})
		} else {
			const payload = {
				[`${pathProjectInfo}/name`]: this.name,
				[`${pathProjectInfo}/description`]: this.description,
				[pathProjectLocationPointTypes]: types,
				[`${pathAnalyticProject}/name`]: this.name
			}
			fService.FREF_BASE.update(payload, (error) => {
				if (error) {
					callback(false)
				} else {
					callback(true)
				}
			})
		}

	}

}

/*
val uid = UserData.fireBaseUser?.uid
if (name == null || uid == null) return callback(null)
val companyBusinessId = UserData(context).info.companyBusinessId ?: return callback(null)

val locationPointTypes: HashMap<String, Any> = hashMapOf()
this.locationPointTypes.forEach {
	val type: HashMap<String, Any?> = hashMapOf()
	type[FirebaseService.KEY_AGING] = it.aging
	type[FirebaseService.KEY_ICON] = it.iconId
	type[FirebaseService.KEY_NAME] = it.getName(context)
	val fields: HashMap<String, Any?> = hashMapOf()
	var fromDB = 0
	it.fields.forEach {
		val field = it.value
		if (field.type == LocationPointType.Field.Type.GET_FROM_DATABASE) {
			val data: HashMap<String, Any?> = hashMapOf()
			data[FirebaseService.KEY_REQUIRED] = field.required
			data[FirebaseService.KEY_TITLE] = field.title
			data[FirebaseService.KEY_TYPE] = field.dataType.value
			fields[field.id] = data
		} else {
			++fromDB
		}
	}
	type[FirebaseService.PATH_DATA] = if (fields.size != fromDB) fields else null
	locationPointTypes[it.id] = type
}




*/

import React from "react";
import { Marker } from "react-map-gl/maplibre";
import LocationPoint from '../../models/LocationPoint'
import LocationPointType from "../../models/LocationPointType";
import { isDated } from "../../lib/Helper";

type MapMarkersProps = {
  locationPoints: LocationPoint[]
  locationPointTypes: Record<string, LocationPointType>
  onClickMarker: (point: LocationPoint) => void
}

const MapMarkers: React.FC<MapMarkersProps> = ({ locationPoints, locationPointTypes, onClickMarker }) => {

  const getIcon = (type, dateCreated) => {
    const model = locationPointTypes[type]
    if (!model) return null
    const isOld = model.aging !== false && isDated(model.aging, dateCreated)

    const numberOfDifferentSvgColors = 16
    // FIXME: After all colors are used, light grey is used by default and you can't differentiate different points from that point forward anymore.
    const markerSvgNumber = Math.min(model.iconId, numberOfDifferentSvgColors) // 16 = light gray color
    return {
      url: isOld
        ? require(`../../assets/markers/${markerSvgNumber}-aging.svg`)
        : require(`../../assets/markers/${markerSvgNumber}.svg`),
    }
  }

  return (
    <>
      {(locationPoints || []).map((point) => (
        <Marker
          key={point.id}
          latitude={point.coords[0]}
          longitude={point.coords[1]}
          style={{
            cursor: 'pointer'
          }}
          onClick={(e) => {
            e.originalEvent.stopPropagation()
            onClickMarker?.(point)
          }}
        >
          <img
            alt=""
            src={getIcon(point.type, point.dateCreated)?.url}
            style={{ width: 40, height: 40, objectFit: 'contain' }}
          />
        </Marker>
      ))}
    </>
  )
}

export default MapMarkers

//import { guid } from '../lib/Helper'

const KEY_AUTHOR = "author"
const KEY_DESCRIPTION = "description"
const KEY_DATA = "data"
const KEY_IMAGES = "images"
const KEY_REMOVER = "remover"

export default class LocationPointData {

	constructor(id, author, description, data, images, remover) {
		this.id = id
		this.author = author
		this.description = description
		this.data = data
		this.images = images
		this.remover = remover
	}

	getDict = () => {
		// Images are left out on purpose. Since getDict is only used when saving data to removed location point data and the images are allways removed
		const data = {[KEY_AUTHOR]: this.author}
		if (this.description) data[KEY_DESCRIPTION] = this.description
		if (this.data) data[KEY_DATA] = this.data
		return data
	}


	static objectFromData = (data) => {
		const locPointData = new LocationPointData(data.id, data[KEY_AUTHOR], data[KEY_DESCRIPTION], data[KEY_DATA], data[KEY_IMAGES], data[KEY_REMOVER])
		return(locPointData)
	}

}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SelectableCard from '../../lib/selectable-card';
import EditLocationPointTypeContainer from '../container-edit-location-point-type/container-edit-location-point-type';
import PropTypes from 'prop-types';
import Container from '../../lib/container';
import LoadingSpinner from '../../lib/loading-spinner';
import { guid, trimStartAndEnd } from '../../lib/Helper';
import classnames from "classnames";
// import LocationPointTypeModal from '../LocationPointTypeModal/location-point-type-modal';
import Button from '../../lib/button';
import Dialog from '../../lib/dialog';
import LocationPointType from '../../models/LocationPointType';
import Project from '../../models/Project';
import NewProject from '../../models/NewProject';
import { setProject } from '../../actions/appStateActions';
import './container-create-project.css';
import {showDangerConfirmDialog} from '../../lib/dialog-generator/dialog-generator'
import { ReactComponent as LockAltIcon } from '../../assets/lock-alt.svg'
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'

class CreateProjectContainer extends Component {

	constructor(props) {
		super(props)

		const editingProject = !!this.props.selectedProject

		this.state = {
			name: editingProject ? props.selectedProject.info.name : '',
			description: editingProject ? props.selectedProject.info.description : '',
			editLocationPointTypeWithIdOf: false,
			locationPointTypes: [LocationPointType.attentionLocationPointType()],
			savingLocationPointType: false,
			errorNameRequired: false,
			loadingProjectLocationPointTypes: editingProject ? true: false,
			savingProject: false
		}

		this.newLocationPoint = this.newLocationPoint.bind(this)
		this.onLocationPointTypeModalClose = this.onLocationPointTypeModalClose.bind(this)
		this.deleteLocationPointType = this.deleteLocationPointType.bind(this)
		this.saveLocationPointType = this.saveLocationPointType.bind(this)
		this.saveProject = this.saveProject.bind(this)
		this.onChange = this.onChange.bind(this)

		if (editingProject) this.loadProjectLocationPointTypes()
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
    }

	loadProjectLocationPointTypes() {
		this.props.selectedProject.getLocationPointTypes().then((locationPointTypes) => {
			this.setState({
				loadingProjectLocationPointTypes: false,
				locationPointTypes
			})
		}).catch(console.error)
	}

	saveProject() {
		const { name, description, locationPointTypes } = this.state
		const trimmedName = trimStartAndEnd(name)
		var error = false
		if (!trimmedName) {
			this.setState({errorNameRequired: true})
			error = true
		}
		if (error) return
		this.setState({
			errorNameRequired: false,
			savingProject: true
		})
		const { selectedProject, selectedCompany } = this.props
		const editingProject = !!selectedProject
		const id = editingProject ? selectedProject.id : guid()
		const newProject = new NewProject(id, name, description, locationPointTypes)
		newProject.saveProject(editingProject, selectedCompany.info.businessId, (success) => {
			if (success) {
				if (editingProject) {
					selectedProject.info.name = newProject.name
					selectedProject.info.description = newProject.description
					this.props.history.push('/')
				} else {
					Project.loadProject(newProject.id, selectedCompany.info.businessId).then((project) => {
						if (project.isOwnProject) selectedCompany.ownProjects.push(project)
						else selectedCompany.otherProjects.push(project)
						this.props.setProject(project)
						this.props.history.replace('/')
					})
				}
			} else {
				alert("Jotain meni vikaan. Yritä myöhemmin uudelleen.")
				this.setState({savingProject: false})
			}
		})
	}

	deleteLocationPointType(id) {
		const newLocationPointTypes = []
		for (const locPointType of this.state.locationPointTypes) {
			if (locPointType.id !== id) newLocationPointTypes.push(locPointType)
		}
		this.setState({locationPointTypes: newLocationPointTypes})
	}

	saveLocationPointType(locationPointType) {
		if (!locationPointType) return this.setState({savingLocationPointType: false})
		const newLocationPointTypes = []
		var locPointTypeEdited = false
		for (const locPointType of this.state.locationPointTypes) {
			if (locPointType.id === locationPointType.id) {
				newLocationPointTypes.push(locationPointType)
				locPointTypeEdited = true
			} else {
				newLocationPointTypes.push(locPointType)
			}
		}
		if (!locPointTypeEdited) newLocationPointTypes.push(locationPointType)
		this.setState({
			savingLocationPointType: false,
			locationPointTypes: newLocationPointTypes,
			editLocationPointTypeWithIdOf: false
		})
	}

	newLocationPoint() {
		this.setState({
			editLocationPointTypeWithIdOf: null
		})
	}

	onLocationPointTypeModalClose() {
		this.setState({
			editLocationPointTypeWithIdOf: false
		})
	}

	componentWillReceiveProps(props) {
		const editingProject = !!this.props.selectedProject
		this.setState({
			name: editingProject ? props.selectedProject.info.name : '',
			description: editingProject ? props.selectedProject.info.description : '',
			editLocationPointTypeWithIdOf: false,
			locationPointTypes: [LocationPointType.attentionLocationPointType()],
			savingLocationPointType: false,
			errorNameRequired: false,
			loadingProjectLocationPointTypes: editingProject ? true: false,
			savingProject: false
		})
	}


	render() {

		const { name, description, editLocationPointTypeWithIdOf, locationPointTypes, savingLocationPointType, errorNameRequired, loadingProjectLocationPointTypes, savingProject } = this.state
		const editingProject = !!this.props.selectedProject

		const showDeleteMarkerDialog = (locationPointType) => {
			const onDelete = () => {
				this.deleteLocationPointType(locationPointType.id)
			}
			showDangerConfirmDialog("Halutko varmasti poistaa merkin?", "Jos poistat karttamerkin, niin kaikki kyseiset pisteet häviävät kartalta", "POISTA", "Peruuta", onDelete)
		}

		const locationPointTypeCards = []
        for (const locationPointType of locationPointTypes) {
            const subText = (locationPointType.aging !== false ? `Vanhenee ajan kuluttua: ${locationPointType.aging} vrk` : null)
            locationPointTypeCards.push(
                <SelectableCard
                    key={locationPointType.id}
                    id={locationPointType.id}
                    text={locationPointType.name}
                    subText={subText}
                    tier={2}
                    disabled={locationPointType.iconId === 0}
                    onSelect={(id) => {
                        this.setState({editLocationPointTypeWithIdOf: id})
                    }}
                    deleteText="POISTA"
						  onDelete={(locationPointType.iconId !== 0) ? () => showDeleteMarkerDialog(locationPointType) : undefined}
						  rightIcon={locationPointType.isLocked ? <LockAltIcon className="icon-medium" /> : undefined}
                  />
            )
		}

		var locationPointTypeToEdit = null
		if (editLocationPointTypeWithIdOf) {
			for (const locPointType of locationPointTypes) {
				if (locPointType.id === editLocationPointTypeWithIdOf) {
					locationPointTypeToEdit = locPointType
					break
				}
			}
		}

		const loading = loadingProjectLocationPointTypes || savingProject

		return (
			<Container className='new-project-container' title={editingProject ? undefined : "Uusi Projekti"} disabled={loading} tier={2}>

				<div className="form-group">
					<label htmlFor="newProjectNameInput">Nimi</label>
					<input
						id="newProjectNameInput"
						name="name"
						value={name}
						onChange={this.onChange}
						className={classnames("form-control", {"is-invalid": errorNameRequired})}
						type="text"
						autoComplete="off"
						disabled={loading}/>
				</div>
				<div className="form-group">
					<label htmlFor="newProjectDescriptionInput">Kuvaus</label>
					<textarea
						id="newProjectDescriptionInput"
						rows="8"
						name="description"
						value={description}
						onChange={this.onChange}
						className="form-control"
						type="text"
						autoComplete="off"
						disabled={loading}/>
				</div>
				<br/>
				<Container title="Karttamerkit" className='new-location-points' tier={3}>
					{locationPointTypeCards}
					<SelectableCard key={"newProject"} text={"Uusi merkki"} leftIcon={<PlusIcon className="icon-small" />} highlighted={true} onSelect={this.newLocationPoint} tier={2}/>
				</Container>
				<Dialog
					title="Uusi karttamerkki"
					neutralButtonText="Tallenna Karttamerkki"
					negativeButtonText="Hylkää"
					width="600px"
					closeOnNeutralButtonClick={false}
					onNeutralButtonClick={() => {
						this.setState({
							savingLocationPointType: true
						})
					}}
					show={editLocationPointTypeWithIdOf !== false}
					onClose={this.onLocationPointTypeModalClose}
				>
					<EditLocationPointTypeContainer
						locationPointType={ editLocationPointTypeWithIdOf ? locationPointTypeToEdit : LocationPointType.newLocationPointType()}
						saveLocationPointCallback={ savingLocationPointType ? this.saveLocationPointType : null }
					/>
				</Dialog>
				<br/>
				<Button
					title={editingProject ? "Tallenna Projekti" : "Luo Projekti"}
					float="right"
					minWidth="20%"
					onClick={this.saveProject}
					disabled={loading}/>
				<br/>
				{ loading ? <LoadingSpinner/> : null }
			</Container>
		);

	}

}

function mapStateToProps(state) {
	return {
		stateType: state.state,
		authUser: state.auth.user,
		selectedCompany: state.selectedCompany,
		selectedProject: state.selectedProject
	}
}

export default withRouter(connect(mapStateToProps, { setProject })(CreateProjectContainer))

import { fService } from "../services/firebaseService"

export default class AnalyticsLocationPoint {

	constructor(id, dateCreated, projectId, authorBusinessId) {
		this.id = id
		this.dateCreated = dateCreated
		this.projectId = projectId
		this.authorBusinessId = authorBusinessId
	}

}
import Validator from 'validator'
import isEmpty from 'lodash/isEmpty'
import UserInfo from '../models/UserInfo'
import { fService } from './firebaseService';
import * as firebase from 'firebase';



export function signIn(userData) {

	return new Promise((resolve, reject) => {

		const errors = {};

		const validation = validateUserSignInData(userData);

		if (!isEmpty(validation)) {
			errors.input = validation;
			return reject(errors);
		}

		/* const authData = {
			Email: userData.email,
			Password: userData.password
		}; */
		/* const authDetails = new AuthenticationDetails(authData);
		const cognitoUserData = {
			Email: userData.email,
			Pool: userPool
		}; */



		firebase.auth()
			.signInWithEmailAndPassword(userData.email, userData.password)
			.then((currentUser)=> {
				resolve(currentUser)
			})
			.catch(e => {
				// var errorCode = error.code;
				// var errorMessage = error.message;
				// errors.request = 'Tapahtui jokin virhe!';
				errors.input = { password: 'Väärä salasana' };
				reject(errors);
			})

		/* const cognitoUser = new CognitoUser(cognitoUserData);
		cognitoUser.authenticateUser(authDetails, {
			onSuccess: (result) => {
				dispatch(saveUserSession(result));
				resolve({ requireNewPassword: false });
			},
			onFailure: (err) => {
				console.log(err.code);
				switch(err.code) {
					case 'UserNotFoundException':
					case 'NotAuthorizedException':
						//errors.request = 'Väärä käyttäjätunnus tai salasana!';
						errors.input = { password: 'Väärä salasana' };
						reject(errors);
						break;
					default:
						errors.request = 'Tapahtui jokin virhe!';
						reject(errors);
				}
			}
		}); */

	});
}


export function signUp(signUpData) {
	return new Promise((resolve, reject) => {

		const errors = {
			input: {}
		}

		const validation = validateUserSignUpData(signUpData);

		if (!isEmpty(validation)) {
			errors.input = validation
			return reject(errors)
		}

		firebase.auth()
			.createUserWithEmailAndPassword(signUpData.email, signUpData.password)
			.then(() => {
				saveUser(new UserInfo(fService.uid, signUpData.firstName, signUpData.lastName, signUpData.email, signUpData.phone))
			})
			.catch(error => {
				switch (error.code) {
					case "auth/email-already-in-use":
						errors.input.email = "Kyseisellä sähköpostilla on jo luotu tunnukset"
						break
					default:
						errors.message = "Jotain meni vikaan, yritä myöhemmin uudelleen."
				}
				reject(errors)
				/* when (exception) {
					is FirebaseAuthUserCollisionException -> return reject({userCollisionError: true})
					else -> {
						Log.d(FirebaseService.TAG, (exception.toString()))
						callback.onError()
					}
				} */
			})
	})
}

export function saveUser(userInfo) {
	return new Promise((resolve, reject) => {

		const uid = fService.uid ? fService.uid : reject();

		const uData = {}
		if (userInfo.firstName != null) uData['first_name'] = userInfo.firstName
		if (userInfo.lastName != null) uData['last_name'] = userInfo.lastName
		if (userInfo.companyBusinessId != null) uData['business_id'] = userInfo.companyBusinessId
		if (userInfo.phoneNumber != null) uData['phone'] = userInfo.phoneNumber
		if (userInfo.email != null) uData['email'] = userInfo.email

		fService.FREF_USERS.child(uid).child(fService.PATH_INFO).update(uData, (error) => {
			if (error) {
				reject()
			} else {
				resolve()
			}
		})
	})
}


export function listenToAuthenticationChanges(onAuthStateChanged) {
	return firebase.auth().onAuthStateChanged(firebaseUser => {
		onAuthStateChanged(firebaseUser)
	})
}


export function getFirebaseUser() {
	return firebase.auth().currentUser
}

function validateUserSignInData(userData) {
	const errors = {};
	if (Validator.isEmpty(userData.email)) {
		errors.email = 'Sähköposti vaaditaan'
	}
	if (!Validator.isEmail(userData.email)) {
		errors.email = 'Tarkista sähköpostiosoitteesi'
	}
	if (Validator.isEmpty(userData.password)) {
		errors.password = 'Salasana vaaditaan'
	}
	return errors;
}

function validateUserSignUpData(signUpData) {
	const errors = {}
	if (Validator.isEmpty(signUpData.firstName)) {
		errors.firstName = 'Etunimi vaaditaan'
	}
	if (Validator.isEmpty(signUpData.lastName)) {
		errors.lastName = 'Sukunimi vaaditaan'
	}
	if (!Validator.isEmail(signUpData.email)) {
		errors.email = 'Tarkista sähköpostiosoitteesi'
	}
	if (signUpData.password.length < 8) {
		errors.password = 'Vähintään 8 merkkiä'
	}
	if (signUpData.verifyPassword !== signUpData.password) {
		errors.verifyPassword = 'Salasanat eivät täsmää'
	}
	return errors
}



/* function validatePasswords(pwd, pwdVrfy) {
	let error = null;

	if (!pwd.match(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/g)) {
		error = "Salasanan tulee olla vähintään 8 merkkiä pitkä, siinä täytyy olla pieniä ja isoja kirjaimia sekä numeroita!";
	} else if (pwdVrfy !== pwd) {
		error = "Salasanat eivät täsmää!";
	}

	return error;
} */



export function signOut() {
	firebase.auth().signOut()
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { guid } from '../Helper';
import './dialog.css';
import $ from "jquery";
import classnames from 'classnames';


export default class Dialog extends Component {

   constructor(props) {
      super(props)
      this.state = {
         dialog: undefined,
         closeDialog: false
      }
      this.id = props.locationPointType ? props.locationPointType.id : guid()
   }

   componentDidMount() {
      if (this.props.show) this.initializeAndShowDialog()
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.show && !prevProps.show) {
         this.initializeAndShowDialog()
      } else if (((!this.props.show && prevProps.show) || (!prevProps.close && this.props.close)) && !prevState.closeDialog && this.state.dialog) {
         this.setState({ closeDialog: true })
      }
      if (!prevState.closeDialog && this.state.closeDialog) {
         $(`#dialog-${this.id}`).modal('toggle')
      }
   }


   initializeAndShowDialog() {

      const { onPositiveButtonClick, onNeutralButtonClick, onNegativeButtonClick, onLeftNegativeButtonClick, showExitButton, onClose } = this.props
      const dialogElementId = `#dialog-${this.id}`
      const dialog = $(dialogElementId)

      this.setState({ dialog }, () => {

         dialog.on("show.bs.modal", () => {
            if (onPositiveButtonClick) {
               $(`${dialogElementId} .modal-footer > .btn-positive`).on("click", (e) => {
                  onPositiveButtonClick()
               })
            }
            if (onNeutralButtonClick) {
               $(`${dialogElementId} .modal-footer > .btn-neutral`).on("click", (e) => {
                  onNeutralButtonClick()
               })
            }
            if (onNegativeButtonClick) {
               $(`${dialogElementId} .modal-footer > .btn-negative`).on("click", (e) => {
                  onNegativeButtonClick()
               })
            }

            if (onLeftNegativeButtonClick) {
               $(`${dialogElementId} .modal-footer > .btn-left-negative`).on("click", (e) => {
                  onLeftNegativeButtonClick()
               })
            }
         })
         dialog.on("hide.bs.modal", () => {
            $(`${dialogElementId} .modal-footer > .btn-positive`).off("click")
            $(`${dialogElementId} .modal-footer > .btn-neutral`).off("click")
            $(`${dialogElementId} .modal-footer > .btn-negative`).off("click")
         })

         dialog.on("hidden.bs.modal", () => {
            this.setState({
               dialog: undefined,
               closeDialog: false
            })
            if (onClose) onClose()
         })

         if (showExitButton) {
            dialog.modal({
               "keyboard" : true,
               // "show"     : true
            })
         } else {
            dialog.modal({
               "backdrop" : "static",
               "keyboard" : false,
               // "show"     : true
            })
         }

      })

   }


   render() {
      const { className, title, message, positiveButtonText, neutralButtonText, negativeButtonText, leftNegativeButtonText, showExitButton, primaryButtonNegative, children, width, closeOnPositiveButtonClick, closeOnNeutralButtonClick, disabled } = this.props
      const id = this.id

      return (
         <span>
            { (this.props.show || this.state.dialog) && (
            <div id={"dialog-"+id} className={classnames("custom-dialog modal fade", {[className]: className})} tabIndex="-1" role="dialog">
               { this.state.dialog && (
                  <div className="modal-dialog" role="document" style={{ maxWidth: width }}>
                        <div className="modal-content">
                           { title && (
                              <div className="modal-header">
                                 <h5 className="modal-title">{title}</h5>
                                 { (title && showExitButton) && (
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled={disabled}>
                                       <span aria-hidden="true">&times;</span>
                                    </button>
                                 )}
                              </div>
                           )}

                           <div className="modal-body">
                              { (!title && showExitButton) && (<button type="button" className="close" data-dismiss="modal" disabled={disabled}>&times;</button>) }
                              { message && (<p>{message}</p>)}
                              { children && (children) }
                           </div>
                           <div className="modal-footer">
                              { (leftNegativeButtonText) ? <button type="button" className="btn btn-left-negative btn-danger mr-auto" data-dismiss="modal" disabled={disabled}>{leftNegativeButtonText}</button> : null }
                              { (negativeButtonText) ? <button type="button" className={"btn btn-negative " + (primaryButtonNegative ? "btn-success" : "btn-danger")} data-dismiss="modal" disabled={disabled}>{negativeButtonText}</button> : null }
                              { (neutralButtonText) ? <button type="button" className="btn btn-neutral btn-primary" data-dismiss={closeOnNeutralButtonClick ? "modal" : null} disabled={disabled}>{neutralButtonText}</button> : null }
                              { (positiveButtonText) ? <button type="button" className={"btn btn-positive " + (primaryButtonNegative ? "btn-danger" : "btn-success")} data-dismiss={closeOnPositiveButtonClick ? "modal" : null} disabled={disabled}>{positiveButtonText}</button> : null }
                           </div>
                        </div>
                     </div>
                  )}
               </div>
            )}
         </span>
      )
   }

   static propTypes = {
      className: PropTypes.string,
      title: PropTypes.string,
      message: PropTypes.string,
      positiveButtonText: PropTypes.string,
      neutralButtonText: PropTypes.string,
      negativeButtonText: PropTypes.string,
      leftNegativeButtonText: PropTypes.string,
      onPositiveButtonClick: PropTypes.func,
      onNeutralButtonClick: PropTypes.func,
      onNegativeButtonClick: PropTypes.func,
      onLeftNegativeButtonClick: PropTypes.func,
      showExitButton: PropTypes.bool,
      primaryButtonNegative: PropTypes.bool,
      closeOnPositiveButtonClick: PropTypes.bool,
      closeOnNeutralButtonClick: PropTypes.bool,
      width: PropTypes.string,
      close: PropTypes.any,
      onClose: PropTypes.func,
      show: PropTypes.bool.isRequired,
      disabled: PropTypes.bool,
   }

   static defaultProps = {
      title: '',
      showExitButton: true,
      primaryButtonNegative: false,
      closeOnPositiveButtonClick: true,
      closeOnNeutralButtonClick: true,
      close: false,
      disabled: false,
      show: false
   }

}


/* <button type="button" classNameName="close" data-dismiss="modal" aria-label="Close">
   <span aria-hidden="true">&times;</span>
</button> */
import { format } from 'date-fns'
import { COLOR_BLUE, COLOR_BLUE_AGING, COLOR_BLUE_GRAY, COLOR_BLUE_GRAY_AGING, COLOR_BROWN, COLOR_BROWN_AGING, COLOR_DEEP_ORANGE, COLOR_DEEP_ORANGE_AGING, COLOR_GRAY, COLOR_GRAY_AGING, COLOR_GREEN, COLOR_GREEN_AGING, COLOR_INDIGO, COLOR_INDIGO_AGING, COLOR_LIGHT_BLUE, COLOR_LIGHT_BLUE_AGING, COLOR_LIGHT_GRAY, COLOR_LIGHT_GRAY_AGING, COLOR_LIGHT_GREEN, COLOR_LIGHT_GREEN_AGING, COLOR_LIME, COLOR_LIME_AGING, COLOR_ORANGE, COLOR_ORANGE_AGING, COLOR_PINK, COLOR_PINK_AGING, COLOR_PURPLE, COLOR_PURPLE_AGING, COLOR_RED, COLOR_RED_AGING, COLOR_TEAL, COLOR_TEAL_AGING, COLOR_YELLOW } from '../constants'

export function promiseMerge(promiseFunc, promiseParamsArr, catchOnFirstError = false) {
  return new Promise((resolve, reject) => {
    var promisesToComplete = promiseParamsArr.length
    const results = {},
          errors = {}
    for (const i in promiseParamsArr) {

        promiseFunc.call(this, promiseParamsArr[i]).then(function () {
            if (arguments.length === 0) {
              results[i] = true
            } else if (arguments.length === 1) {
              results[i] = arguments[0]
            } else {
              results[i] = []
              for (const j in arguments) {
                results[i][j] = arguments[j]
              }
            }
            // errors[i] = undefined
            if (--promisesToComplete === 0) resolve(results, errors)
        }).catch(e => {
            if (catchOnFirstError) reject()
            else {
              errors[i] = e
              if (--promisesToComplete === 0) resolve(results, errors)
            }
        })
      }
  })
}

export function objectContainsProperties(obj, properties) {
  for (const i in properties) if (obj[properties[i]] === undefined) return false
  return true
}

export function objectGetMissingProperties(obj, properties) {
  const missingProperties = []
  for (const i in properties) {
    if (obj[properties[i]] === undefined) {
      missingProperties.push(properties[i])
    }
  }
  return missingProperties.length > 0 ? missingProperties : false
}

export function arrayRemoveItem(arr, item) {
  var index = arr.indexOf(item);
  if (index > -1) arr.splice(index, 1);
}

export function isEmpty(obj) {
  if (!obj) return true
  return (Object.keys(obj).length === 0 && obj.constructor === Object)
}

export function sortByProperty(arr, property) {
  if (arr.length === 0) return
  const stringSort = typeof arr[0][property] === "string"
  arr.sort(function(a, b) {
    if (stringSort) {
      a = a[property].toLocaleLowerCase()
      b = b[property].toLocaleLowerCase()
    } else {
      a = a[property]
      b = b[property]
    }
    if (a < b)
      return -1
    if (a > b)
      return 1
    return 0
  });
}

export function sortByPropertysProperty(arr, property, property2) {
  if (arr.length === 0) return
  const stringSort = typeof arr[0][property][property2] === "string"
  arr.sort(function(a, b) {
    if (stringSort) {
      a = a[property][property2].toLocaleLowerCase()
      b = b[property][property2].toLocaleLowerCase()
    } else {
      a = a[property][property2]
      b = b[property][property2]
    }
    if (a < b)
      return -1
    if (a > b)
      return 1
    return 0
  })
}

export function getItemByPropertysValue(arr, property, val) {
  for (const i in arr) {
    if (arr[i][property] === val) return arr[i]
  }
}
export function removeItemsByPropertysValue(arr, property, val) {
  for (const i in arr) {
    if (arr[i][property] === val) arr.splice(i, 1);
  }
}

export function millisecondsToDateString(milliseconds) {
  return new Date(milliseconds).toLocaleDateString('fi-FI')
}

export function dateToString(date, showTime) {
	const offset = date.getTimezoneOffset() - new Date().getTimezoneOffset()
	const dateFix = new Date(date).setMinutes(date.getMinutes() + offset)
	if (!showTime) {
		return format(dateFix, 'd.M.yyyy')
	} else {
		return format(dateFix, 'd.M.yyyy, HH:mm')
	}
}


export function guid() {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function trimStartAndEnd(str) {
  return str.replace(/^\s\s*/, '').replace(/\s\s*$/, '')
}

export function saveFile(url, fileName) {
  var xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = function() {
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
    a.download = fileName; // Set the file name.
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a = null;
  };
  xhr.open('GET', url);
  xhr.send();
}




export const getBeginningOfDate = (date, inMilliseconds) => {
	date.setHours(0)
	date.setMinutes(0)
	date.setSeconds(0)
	date.setMilliseconds(0)

	if (inMilliseconds) return date.getTime()
	return date
}
export const getEndOfDate = (date, inMilliseconds) => {
	date.setHours(23)
	date.setMinutes(59)
	date.setSeconds(59)
	date.setMilliseconds(999)

	if (inMilliseconds) return date.getTime()
	return date
}



export const openUrlInNewTab = (url) => {
  const link = document.createElement('a')
  link.target = '_blank'
  link.href = url
  link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }))
}

export const setFullscreen = (fullscreen) => {
  const elem = document.documentElement
  if (fullscreen) {
    if (elem.requestFullscreen) return elem.requestFullscreen()
    else if (elem.mozRequestFullScreen) return elem.mozRequestFullScreen()
    else if (elem.webkitRequestFullscreen) return elem.webkitRequestFullscreen()
    else if (elem.msRequestFullscreen) return elem.msRequestFullscreen()
  } else {
    if (document.exitFullscreen) return document.exitFullscreen()
    else if (document.mozCancelFullScreen) return document.mozCancelFullScreen()
    else if (document.webkitExitFullscreen) return document.webkitExitFullscreen()
    else if (document.msExitFullscreen) return document.msExitFullscreen()
  }
  return Promise.reject("Not supported")
}


export const isSafari = () => {
	if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) return true
	else return false
}



export const getIconColor = (iconType) => {
	switch (iconType) {
	case 0:
		 return COLOR_YELLOW
	case 1:
		return COLOR_INDIGO
	case 2:
		return COLOR_RED
	case 3:
		return COLOR_TEAL
	case 4:
		return COLOR_ORANGE
	case 5:
		return COLOR_PURPLE
	case 6:
		return COLOR_BLUE
  case 7:
		return COLOR_GREEN
  case 8:
    return COLOR_PINK
   case 9:
     return COLOR_GRAY
   case 10:
     return COLOR_LIGHT_BLUE
   case 11:
     return COLOR_BROWN
   case 12:
     return COLOR_LIGHT_GREEN
   case 13:
    return COLOR_DEEP_ORANGE
  case 14:
    return COLOR_BLUE_GRAY
   case 15:
    return COLOR_LIME
  case 16:
    return COLOR_LIGHT_GRAY
	default:
		return COLOR_LIGHT_GRAY
	}
}

export const getIconColorAging = (iconType) => {
  switch (iconType) {
  case 0:
    return COLOR_YELLOW
  case 1:
    return COLOR_INDIGO_AGING
  case 2:
    return COLOR_RED_AGING
  case 3:
    return COLOR_TEAL_AGING
  case 4:
    return COLOR_ORANGE_AGING
  case 5:
    return COLOR_PURPLE_AGING
  case 6:
    return COLOR_BLUE_AGING
  case 7:
    return COLOR_GREEN_AGING
  case 8:
    return COLOR_PINK_AGING
  case 9:
    return COLOR_GRAY_AGING
  case 10:
    return COLOR_LIGHT_BLUE_AGING
  case 11:
    return COLOR_BROWN_AGING
  case 12:
    return COLOR_LIGHT_GREEN_AGING
  case 13:
    return COLOR_DEEP_ORANGE_AGING
  case 14:
    return COLOR_BLUE_GRAY_AGING
  case 15:
    return COLOR_LIME_AGING
  case 16:
    return COLOR_LIGHT_GRAY_AGING
  default:
    return COLOR_LIGHT_GRAY_AGING
  }
}


export const calculateAge = (date) => {
	const currentDate = new Date()
	return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) ) /(1000 * 60 * 60 * 24));
}

export const isDated = (days, dateCreated) => {
  const interval = 86400 * days * 1000; // interval in ms
  const currentDate = new Date().getTime();
  const comparisonDate = dateCreated + interval;
  return currentDate > comparisonDate;
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { guid } from '../Helper';
import './segmented-controls.css';

export default class SegmentedControls extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: guid(),
        }
    }

	render() {

        const { ids, names, selectedComponent, width, bold, onChange } = this.props

        const selectedComponentId = selectedComponent ? selectedComponent : ids[0]

        return (            
            <div className="segmented-controls btn-group" data-toggle="buttons" style={{width}}>
                {
                    ids.map((id, i) =>
                        <label key={id} value={id} className={classnames("btn btn-primary", {"active": selectedComponentId === id })} style={{width: "inherit", fontWeight: bold ? "bold" : null}} onClick={() => {
                            if (selectedComponent !== id) { onChange(id) }
                        }}>
                            <input type="radio" name={this.state.id} value={id} id={id}/> {names[i]}
                        </label>
                    )
                }
            </div>
        )
        
	}

}

SegmentedControls.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.string).isRequired,
    names: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedComponent: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    width: PropTypes.string,
    bold: PropTypes.bool
}

SegmentedControls.defaultProps = {
    width: "auto",
    bold: false
}

import React, { Component } from 'react';
import classnames from "classnames";
import PropTypes from 'prop-types';
import './input.css';
import { guid } from '../Helper';


export default class Input extends Component {

	constructor(props) {
		super(props)

		this.state = {
			id: guid()
		}
		this.lastVal = props.value
		this.input = null

		this.onChange = this.onChange.bind(this)
	}

	onChange(e) {
		if (this.props.regex) {
			var result = e.target.value.match(this.props.regex);
			if (!result) this.input.value = this.lastVal
			else this.props.onChange(e)
		} else {
			if (this.props.onChange) this.props.onChange(e)
		}
	}
	componentDidMount() {
		const {reference} = this.props
		if (reference) reference(this.input)
	}

	render() {
		const { id } = this.state
		const { title, name, value, placeholder, bold, large, autoFocus, autoComplete, type, error, errorMessage, disabled, hint, reference, rows } = this.props

		return (
			<div className="custom-input form-group" style={{marginBottom: title ? null : "8px"}}>
				{ title && <label htmlFor={id} data-bold={bold}>{title}</label> }
				{ !rows || rows <= 1 ? (
					<input
						ref={((input) => this.input = input)}
						id={id}
						name={name}
						value={value}
						onChange={this.onChange}
						placeholder={placeholder}
						className={classnames("form-control", {"form-control-lg": large}, {"is-invalid": error || errorMessage})}
						type={type}
						autoComplete={autoComplete}
						autoFocus={autoFocus}
						disabled={disabled}/>
				) : (
					<textarea
						ref={((input) => this.input = input)}
						id={id}
						rows={rows}
						name={name}
						value={value}
						onChange={this.onChange}
						placeholder={placeholder}
						className={classnames("form-control", {"form-control-lg": large}, {"is-invalid": error || errorMessage})}
						type={type}
						autoComplete={autoComplete}
						autoFocus={autoFocus}
						disabled={disabled}/>
				)}
				{ hint && <small className="hint">{hint}</small> }
				{ errorMessage && <small className="error-message">{errorMessage}</small> }
			</div>
		)
	}

	static propTypes = {
		title: PropTypes.string, 		// Otsikko yläpuolelle
		bold: PropTypes.bool,			// Title boldina
		hint: PropTypes.string,			// Pieni musta viesti fieldin alapuolelle
		large: PropTypes.bool,			// Hitusen isompi koko
		disabled: PropTypes.bool,		// Kenttä disabloitu
		error: PropTypes.bool,			// Punaiseksi fieldin reunat
		errorMessage: PropTypes.string, // Pieni punainen error viesti fieldin alapuolelle + punaiseksi fieldin reunat
		rows: PropTypes.number,			// Multiline (numer of rows)
		regex: PropTypes.string,		// Hyväksy vain regexin sallimat merkit

		name: PropTypes.string,			// name
		type: PropTypes.string,			// type
		value: PropTypes.string,		// value
		placeholder: PropTypes.string,	// placeholder
		onChange: PropTypes.func,		// onChange
		autoComplete: PropTypes.string, // autoComplete
		autoFocus: PropTypes.bool,		// Autofocus tähän kenttään
		reference: PropTypes.func,		// ref
	}

	static defaultProps = {
		title: '',
		large: false,
		disabled: false,
		error: false,
		errorMessage: null,
		autoComplete: 'off',
	}

}
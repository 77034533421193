import moment from "moment";
import { dateToString } from "../lib/Helper";
import UserInfo from "../models/UserInfo";

// TODO: Handle removed points
// TODO: Add image urls,

/**
 * Export location points as CSV file
 * @param {*} locationPoints
 * @param {*} locationPointTypes
 * @param {*} project
 * @param {boolean} exportRemovedPoints
 */
export const exportPointsToCsv = async (
  locationPoints,
  locationPointTypes,
  project,
  exportRemovedPoints
) => {
  // Imagedownload url should be available until revoked. Google "static firebase storage download url"
  // This function is just an example of url and hence is not working
  // const generateImageUrl = () => {
  //    return `https://firebasestorage.googleapis.com/v0/b/tolppa-76c90.appspot.com/o/0ced7725-74af-2b10-7b87-e3b86f0ed5ed%2Floc_point_images%2F299a374a-bc28-492f-de51-b79209b980ea?alt=media&token=af54302a-8f0b-4c26-a6ee-bfd79732c29f`
  // }

  const locationPointData = exportRemovedPoints
    ? await project.getRemovedLocationPointsData()
    : await project.getLocationPointsData();

  // Filter out location points without type
  const sortedAndFilteredLocationPoints = [...locationPoints].filter(
    ({ type }) => locationPointTypes[type]
  );
  sortedAndFilteredLocationPoints.sort((a, b) => {
    return b.dateCreated - a.dateCreated;
  });

  /** @type {object<string, boolean>} */
  var existingTypes = {};

  sortedAndFilteredLocationPoints.forEach(({ type }) => {
    if (!existingTypes[type]) existingTypes[type] = true;
  });

  let columns = [
    { label: "Tyyppi", key: "type" },
    { label: "Otsikko", key: "title" },
    { label: "Luoja", key: "createdBy" },
    { label: "Luotu", key: "dateCreated" },
    { label: "Latitudi", key: "latitude" },
    { label: "Longitudi", key: "longitude" },
    { label: "Lisätietoja", key: "description" },
    // { label: 'Kuvat', key: 'images' },
  ];
  if (exportRemovedPoints)
    columns.push({ label: "Poistettu", key: "dateRemoved" });

  const fieldTitles = [];
  Object.keys(existingTypes).forEach((key) => {
    const type = locationPointTypes[key];
    type.fields.forEach(({ id, title }) => {
      if (id === "description" || id === "lisatiedot") return;
      if (!fieldTitles.find((item) => item.key === id))
        fieldTitles.push({ label: title, key: id });
    });
  });

  const rows = [];

  const titleRow = [];
  columns = [...columns, ...fieldTitles];
  columns.forEach(({ label, key }) => {
    titleRow.push(label);
  });

  const authorNamesByIds = {};
  const authorIds = [
    ...new Set(
      sortedAndFilteredLocationPoints.map(
        ({ id }) => locationPointData[id].author
      )
    ),
  ];
  await Promise.all(
    authorIds.map((authorId) =>
      UserInfo.getInfo(authorId)
        .then(({ firstName, lastName }) => {
          authorNamesByIds[authorId] = `${firstName} ${lastName}`;
        })
        .catch(console.error)
    )
  );

  sortedAndFilteredLocationPoints.forEach(
    ({ id, title, type, coords, dateCreated, dateRemoved }, index) => {
      let currentRow = [];

      const pointData = locationPointData[id];

      const correctType = locationPointTypes[type];

      if (!correctType) {
        console.log("Point not found: ", id, title, type);
        return;
      }

      columns.forEach(({ key }) => {
        switch (key) {
          case "type":
            if (correctType.name === "Attention")
              currentRow.push(correctType.name);
            else currentRow.push(correctType.name);
            break;
          case "title":
            currentRow.push(title);
            break;
          case "createdBy":
            currentRow.push(
              authorNamesByIds[pointData.author] || pointData.author
            );
            break;
          case "dateCreated":
            currentRow.push(moment(dateCreated).format("ll LT"));
            break;
          case "latitude":
            currentRow.push(coords[0]);
            break;
          case "longitude":
            currentRow.push(coords[1]);
            break;
          case "description":
            currentRow.push(pointData?.description);
            break;
          // case 'images':
          //    if (!pointData?.images) currentRow.push(undefined)
          //    let string = ''
          //    pointData.images.forEach((id) => {
          //       if (!string) string = id
          //       else string += `, ${id}`
          //    })
          //    currentRow.push(pointData.images)
          //    break;
          case "dateRemoved":
            if (dateRemoved)
              currentRow.push(moment(dateRemoved).format("ll LT"));
            else currentRow.push(undefined);
            break;
          default:
            const fieldValue = pointData?.data?.[key] || undefined;
            currentRow.push(fieldValue);
            break;
        }
      });

      rows[index] = currentRow;
    }
  );

  const results = [columns.map((ht) => ht.label)].concat(rows);

  const encoding = "data:text/csv;charset=UTF-8,%ef%bb%bf";
  const csvContent = results.map((row) => row.join(";")).join("\n");
  const encodedUri = encodeURI(csvContent);
  const href = "data:text/csv" + encoding + encodedUri;

  const link = document.createElement("a");
  link.setAttribute("href", href);
  link.setAttribute("download", `tolapp-${dateToString(new Date())}.csv`);
  document.body.appendChild(link);
  link.click();
};

import { fService } from "../services/firebaseService"
import Project from "./Project"
import AnalyticsProject from "./AnalyticsProject"
import AnalyticsLocationPoint from "./AnalyticsLocationPoint"
import {sortByPropertysProperty, guid} from '../lib/Helper'
import sha1 from "sha1";

export default class Company {

	constructor(id, info, projectIdList, rules = undefined) {
		this.id = id
		this.info = info
		this.projectIdList = projectIdList
		this.rules = rules
		this.admins = []
		this.ownProjects = []
		this.otherProjects = []
		this.analyticProjects = []
	}

	getProjectById(projectId) {
		return this.getOwnProjectById(projectId) || this.getOtherProjectById(projectId) || null
	}

	getOtherProjectById(projectId) {
		for (const project of this.otherProjects) {
			if (project.id === projectId) return project
		}
		return false
	}
	getOwnProjectById(projectId) {
		for (const project of this.ownProjects) {
			if (project.id === projectId) return project
		}
		return false
	}

	loadRules() {
		return new Promise((resolve, reject) => {
			if (!this.id) return reject()
			fService.FREF_COMPANY_SECURE.child(this.id).child(fService.PATH_RULES).once('value', snap => {
				const rules = snap.val()
				this.rules = new Company.Rules(
					rules.locked,
					rules.create_project,
					rules.control_panel_access
				)
				resolve()
			})
		})
	}

	getUsers() {
		return new Promise((resolve, reject) => {
			if (!this.id) return reject()
			fService.FREF_COMPANY_SECURE.child(this.id).child(fService.PATH_USERS).once('value', snap => {
				const usersObj = snap.val()
				const users = []
				for (const userId in usersObj) { users.push(userId)	}
				resolve(users)
			})
		})
	}

	loadAdministrators() {
		return new Promise((resolve, reject) => {
			if (!this.id) return reject()
			fService.FREF_COMPANY_SECURE.child(this.id).child(fService.PATH_ADMINS).once('value', snap => {
				const adminsObj = snap.val()
				const admins = []
				for (const adminId in adminsObj) { admins.push(adminId)	}
				this.admins = admins
				resolve()
			})
		})
	}

	removeUser(userId) {
		return new Promise((resolve, reject) => {
			const payload = {
				[`${fService.PATH_COMPANY_SECURE}/${this.id}/${fService.PATH_USERS}/${userId}`]: null,
				[`${fService.PATH_USERS}/${userId}/company_id`]: null,
				[`${fService.PATH_USERS}/${userId}/${fService.PATH_INFO}/business_id`]: null
			}
			fService.FREF_BASE.update(payload, (error) => {
				if (!error) {
					resolve()
				} else reject()
			})
		})
	}

	setAdministrator(userId, bool) {
		return new Promise((resolve, reject) => {
			const payload = {
				[userId]: (bool ? true : null)
			}
			fService.FREF_COMPANY_SECURE.child(this.id).child(fService.PATH_ADMINS).update(payload, (error) => {
				if (!error) {
					resolve()
				} else reject()
			})
		})
	}

	loadProjectsInfo() {
		return new Promise((resolve, reject) => {
			this.ownProjects = []
			this.otherProjects = []
			var projectsToLoad = this.projectIdList.length
			if (projectsToLoad === 0) resolve()
			for (const id of this.projectIdList) {
				Project.loadProject(id, this.info.businessId).then((project) => {
					if (project.isOwnProject) this.ownProjects.push(project)
					else this.otherProjects.push(project)
					if (--projectsToLoad === 0) {
						sortByPropertysProperty(this.ownProjects, 'info', 'name')
						sortByPropertysProperty(this.otherProjects, 'info', 'name')
						resolve()
					}
				})
			}
		})
	}

	loadProjectInfo(id) {
		return new Promise((resolve, reject) => {
			Project.loadProject(id, this.info.businessId).then((project) => {
				if (project.isOwnProject) {
					this.ownProjects.push(project)
					sortByPropertysProperty(this.ownProjects, 'info', 'name')
				} else {
					this.otherProjects.push(project)
					sortByPropertysProperty(this.otherProjects, 'info', 'name')
				}
				resolve(project)
			})
		})
	}

	loadAnalyticProjects() {
		return new Promise((resolve, reject) => {
			fService.FREF_ANALYTICS.child(this.info.businessId).child(fService.PATH_PROJECTS).once('value', snap => {
				const data = snap.val()
				const projects = []
				for (const id in data) {
					const project = data[id]
					projects.push(new AnalyticsProject(id, project.name, project.date_created, project.removed))
				}
				var compare = function(a, b) {
					if (a.removed) {
						if (b.removed) {
							if (a.name < b.name)
								return -1
							if (a.name > b.name)
								return 1
						} else return 1
					} else if (b.removed) {
						return -1
					} else {
						if (a.name < b.name)
							return -1
						if (a.name > b.name)
							return 1
						return 0
					}
				}
				projects.sort(compare)
				this.analyticProjects = projects
				resolve()
			})
		})
	}

	getLocationPointAnalytics(startDateMilliseconds, endDateMilliseconds) {
		return new Promise((resolve, reject) => {
			if (this.projectInfo) return resolve()
			fService.FREF_ANALYTICS.child(this.info.businessId).child(fService.PATH_LOCATION_POINTS).orderByChild('date_created').startAt(startDateMilliseconds).endAt(endDateMilliseconds).once('value', snap => {
				const locPoints = snap.val()
				const analyticLocationPoints = []
				for (const id in locPoints) {
					const locPoint = locPoints[id]
					analyticLocationPoints.push(new AnalyticsLocationPoint(id, locPoint.date_created, locPoint.project_id, locPoint.author_business_id))
				}
				resolve(analyticLocationPoints)
			})
		})
	}

	getProjectAnalytics(startDateMilliseconds, endDateMilliseconds) {
		return new Promise((resolve, reject) => {
			fService.FREF_ANALYTICS.child(this.info.businessId).child(fService.PATH_PROJECTS).orderByChild('date_created').startAt(startDateMilliseconds).endAt(endDateMilliseconds).once('value', snap => {
				const data = snap.val()
				const projects = []
				for (const id in data) {
					const project = data[id]
					projects.push(new AnalyticsProject(id, project.name, project.date_created, project.removed))
				}
				resolve(projects)
			})
		})
	}

	leaveFromProject(projectId, callback) {
		const project = this.getOtherProjectById(projectId)
		if (!project) return callback(false)
		project.kickCompany(this.info.businessId, (success) => {
			if (success) {
				const remainingOtherProjects = []
				for (const project of this.otherProjects) {
					if (project.id !== projectId) remainingOtherProjects.push(project)
				}
				this.otherProjects = remainingOtherProjects
				callback(true)
			} else callback(false)
		})
	}

	deleteProject(projectId, callback) {
		const project = this.getOwnProjectById(projectId)
		if (!project) return callback(false)
		project.deleteLocationPointImages((success) => {
			if (success) {
				project.getInvitedCompanies((invitedCompanies) => {
					var companiesToKick = project.invitedCompanies.length
					const companiesKicked = () => {
						for (const file of project.files) {
							project.deleteFile(file.id)
						}
						const payload = {
							[`${fService.PATH_COMPANIES}/${project.info.businessId}/${fService.PATH_PROJECTS}/${project.id}`]: null,
							[`${fService.PATH_PROJECTS}/${project.id}`]: null,
							[`${fService.PATH_ANALYTICS}/${project.info.businessId}/${fService.PATH_PROJECTS}/${project.id}/removed`]: fService.ServerValue.TIMESTAMP
						}
						fService.FREF_BASE.update(payload, (error) => {
							if (!error) {
								const remainingProjects = []
								for (const proj of this.ownProjects) {
									if (project.id !== proj.id) remainingProjects.push(proj)
								}
								this.ownProjects = remainingProjects
								callback(true)
							} else callback(false)
						})
					}
					if (companiesToKick === 0) companiesKicked()
					for (const businessId of invitedCompanies) {
						project.kickCompany(businessId, (success) => {
							if (success) {
								if (--companiesToKick === 0) companiesKicked()
							} else callback(false)
						})
					}
				})
			} else {
				callback(false)
			}
		})
	}

	editCompany(name, oldPassword, newPassword, rules) {
      var oldPwd = oldPassword
		return new Promise((resolve, reject) => {

			const saveToDatabase = (companyId) => {
				const payload = {
					[`${fService.PATH_COMPANIES}/${this.info.businessId}/${fService.PATH_INFO}/name`]: name					
				}
				if (oldPwd && newPassword) {
					payload[`${fService.PATH_COMPANY_JOIN_KEYS}/${this.info.businessId}/`+sha1(oldPwd)] = null
					payload[`${fService.PATH_COMPANY_JOIN_KEYS}/${this.info.businessId}/`+sha1(newPassword)] = companyId
				}
				if (rules) {
					payload[`${fService.PATH_COMPANY_SECURE}/${companyId}/rules/locked`] = rules.locked
					payload[`${fService.PATH_COMPANY_SECURE}/${companyId}/rules/create_project`] = rules.createProject
					payload[`${fService.PATH_COMPANY_SECURE}/${companyId}/rules/control_panel_access`] = rules.controlPanelAccess
            }
				fService.FREF_BASE.update(payload, (error) => {
					if (!error) resolve()
					else reject()
				})
			}
         
         if (newPassword) {
            if (oldPwd == "") {
               fService.FREF_BASE.child(fService.PATH_COMPANY_JOIN_KEYS).child(this.info.businessId).on("value", (snapshot) => {
                  oldPwd = Object.keys(snapshot.val())[0]
                  saveToDatabase(this.id)
              });
            } else {
               fService.FREF_BASE.child(fService.PATH_COMPANY_JOIN_KEYS).child(this.info.businessId).child(sha1(oldPassword)).once('value', snap => {
                  const companyId = snap.val()
                  if (!companyId) return reject({wrongPassword: true})
                  else saveToDatabase(companyId)
               })
            }
			} else {
				saveToDatabase(this.id)
			}
			

		})
	}

	static getInfo(businessId) {
		return new Promise((resolve, reject) => {
			fService.FREF_COMPANIES.child(businessId).child(fService.PATH_INFO).once('value', snap => {
				const info = snap.val()
				if (info) resolve(new Company.Info(businessId, info.name))
				else reject()
			})
		})
	}

	static getCompany(id, businessId) {
		return new Promise((resolve, reject) => {
			fService.FREF_COMPANIES.child(businessId).once('value', snap => {
				const data = snap.val()
				const projects = []
				for (const projectId in data.projects) {
					projects.push(projectId)
				}
				resolve(new Company(id, new Company.Info(businessId, data.info.name), projects))
			})
		})
	}

	static createCompany(businessId, name, password, rules, callback) {
		return new Promise((resolve, reject) => {
			const id = guid()
			fService.FREF_BASE.update({
				[`${fService.PATH_COMPANIES}/${businessId}/${fService.PATH_INFO}/name`]: name,
				[`${fService.PATH_COMPANY_JOIN_KEYS}/${businessId}/`+sha1(password)]: id,
				[`${fService.PATH_COMPANY_SECURE}/${id}/business_id`]: businessId,
				[`${fService.PATH_COMPANY_SECURE}/${id}/rules/locked`]: rules.locked,
				[`${fService.PATH_COMPANY_SECURE}/${id}/rules/create_project`]: rules.createProject,
				[`${fService.PATH_COMPANY_SECURE}/${id}/rules/control_panel_access`]: rules.controlPanelAccess,
			}, (error) => {
				if (!error) callback(new Company(id, new Company.Info(businessId, name), [], rules))
				else callback(null)
			})
		})
	}

}


Company.Info = class {

	constructor(businessId, name) {
		this.businessId = businessId
		this.name = name
	}

}

Company.Rules = class {

	constructor(locked, createProject, controlPanelAccess) {
		this.locked = !!locked
		this.createProject = !!createProject
		this.controlPanelAccess = !!controlPanelAccess
	}

}
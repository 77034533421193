export const COLOR_PRIMARY = "#FF7D00"


// COLOR HEX CODES
export const COLOR_RED = "#E53935"
export const COLOR_RED_AGING = "#B71C1C"

export const COLOR_TEAL = "#26A69A"
export const COLOR_TEAL_AGING = "#00695C"

export const COLOR_DEEP_PURPLE = "#673AB7"
export const COLOR_DEEP_PURPLE_AGING = "#311B92"

export const COLOR_INDIGO = "#3F51B5"
export const COLOR_INDIGO_AGING = "#1A237E"

export const COLOR_ORANGE = "#FF9800"
export const COLOR_ORANGE_AGING = "#E65100"

export const COLOR_BLUE = "#1E88E5"
export const COLOR_BLUE_AGING = "#0D47A1"

export const COLOR_PURPLE = "#9C27B0"
export const COLOR_PURPLE_AGING = "#4A148C"


export const COLOR_GREEN = "#4CAF50"
export const COLOR_GREEN_AGING = "#2E7D32"

export const COLOR_PINK = "#FF4081"
export const COLOR_PINK_AGING = "#880E4F"

export const COLOR_GRAY = "#757575"
export const COLOR_GRAY_AGING = "#212121"

export const COLOR_LIGHT_BLUE = "#B3E5FC"
export const COLOR_LIGHT_BLUE_AGING = "#29B6F6"

export const COLOR_BROWN = "#795548"
export const COLOR_BROWN_AGING = "#3E2723"

export const COLOR_LIGHT_GREEN = "#8BC34A"
export const COLOR_LIGHT_GREEN_AGING = "#558B2F"


export const COLOR_DEEP_ORANGE = "#FF8A65"
export const COLOR_DEEP_ORANGE_AGING = "#F4511E"

export const COLOR_BLUE_GRAY = "#607D8B"
export const COLOR_BLUE_GRAY_AGING = "#263238"

export const COLOR_LIME = "#EEFF41"
export const COLOR_LIME_AGING = "#AEEA00"

export const COLOR_LIGHT_GRAY = "#FAFAFA"
export const COLOR_LIGHT_GRAY_AGING = "#BDBDBD"




export const COLOR_YELLOW = "#FFEB3B"

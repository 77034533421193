import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Container from '../../lib/container';
import { guid, trimStartAndEnd } from '../../lib/Helper';
import Switch from "../../lib/switch";
import classnames from "classnames";
import SelectableCard from "../../lib/selectable-card";
import LocationPointType from "../../models/LocationPointType";
import EditLocationPointTypeFieldContainer from "../container-edit-location-point-type-field/container-edit-location-point-type-field";
import './container-edit-location-point-type.css';

export default class EditLocationPointTypeContainer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id: props.locationPointType.id,
            iconId: props.locationPointType.iconId,
            name: props.locationPointType.name,
            aging: props.locationPointType.aging,
            isLocked: props.locationPointType.isLocked,
            fields: props.locationPointType.fields ? props.locationPointType.fields : [],
            selectedFieldId: null,
            errorNameRequired: false,
            errorWrongAgingValue: false,
        }

        this.removeField = this.removeField.bind(this)
        this.saveField = this.saveField.bind(this)
        this.onChange = this.onChange.bind(this)
    }

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
    }

    removeField(id) {
        const newFields = []
        for (const field of this.state.fields) {
            if (field.id !== id) {
                newFields.push(field);
            }
        }
        this.setState({
            selectedFieldId: null,
            fields: newFields
        })
    }

    saveField(field) {
        const newFields = []
        var fieldUpdated = false;
        for (const f of this.state.fields) {
            if (f.id === field.id) {
                fieldUpdated = true
                f.title = field.title
                f.dataType = field.dataType
                f.required = field.required
                f.type = field.type
            }
            newFields.push(f)
        }
        if (!fieldUpdated) newFields.push(field)
        this.setState({
            fields: newFields,
            selectedFieldId: false
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.saveLocationPointCallback && this.props.saveLocationPointCallback !== prevProps.saveLocationPointCallback) {
            const { id, iconId, name, aging, fields, isLocked } = this.state

            const trimmedName = trimStartAndEnd(name)
            var error = false
            const newState = {
                errorNameRequired: false,
                errorWrongAgingValue: false
            }
            if (!trimmedName) {
                newState.errorNameRequired = true
                error = true
            }
            if (aging !== false && aging <= 0) {
                newState.errorWrongAgingValue = true
                error = true
            }
            this.setState(newState)
            if (error) return this.props.saveLocationPointCallback(null)
            const locationPointType = new LocationPointType(id, trimmedName, iconId, aging, fields, isLocked)
            this.props.saveLocationPointCallback(locationPointType)
        }
    }

	render() {

        const { id, aging, fields, selectedFieldId, errorNameRequired, errorWrongAgingValue, name, isLocked } = this.state
        const { locationPointType } = this.props

        var selectedField;
        const fieldCards = []
        for (const field of fields) {
            if (field.id === selectedFieldId) selectedField = field
            const subText = (
                field.type === LocationPointType.Field.Type.GET_FROM_DATABASE ?
                    (field.dataType === LocationPointType.Field.DataType.ML_STRING || field.dataType === LocationPointType.Field.DataType.STRING ? "Tekstikenttä" : "Numerokenttä" ) +
                    (field.required ? " (Vaaditaan)" : "")
                : null
            )

            fieldCards.push(
                <SelectableCard
                    key={field.id}
                    id={field.id}
                    text={field.title}
                    subText={subText}
                    selected={selectedFieldId === field.id}
                    tier={2}
                    small={true}
                    disabled={field.type !== LocationPointType.Field.Type.GET_FROM_DATABASE}
                    onSelect={(id) => {
                        this.setState({selectedFieldId: id})
                    }}
                    // deleteText="POISTA"
                    onDelete={field.type === LocationPointType.Field.Type.GET_FROM_DATABASE ? this.removeField : null}
                    />
            )
        }

		return (
            <div className="container-edit-location-point-type">
                <div className="form-group">
                    <label htmlFor="newLocationPointTypeName"><b>Pisteen Nimi</b></label>
                    <input
                        id="newLocationPointTypeName"
                        name="name"
                        value={name}
                        onChange={this.onChange}
                        className={classnames("form-control", {"is-invalid": errorNameRequired})}
                        type="text"
                        placeholder=""
                        autoComplete="off"/>
                </div>
                <div className="form-group">
                    <Switch
                        label="Vanheneva"
                        info="Vanheneva merkki vaihtaa väriä tummemmaksi kun valittu aika on kulunut pisteen laittamisesta kartalle."
                        bold={true}
                        selected={aging !== false}
                        onChange={(selected) => {
                            this.setState(selected ? {aging: 14, errorWrongAgingValue: false} : {aging: false} )
                        }}
                    />
                    <div style={{display: aging !== false ? "flex" : "none"}}>
                        <p id="daysTillOldLabel" htmlFor="daysTillOld">Vuorokautta</p>
                        <input
                            id="daysTillOld"
                            name="aging"
                            onChange={aging !== false ? this.onChange : null}
                            className={aging !== false ? classnames("form-control", {"is-invalid": errorWrongAgingValue}) : "form-control"}
                            value={aging}
                            onChange={this.onChange}
                            type="number"
                            min="1"
                            step="1"/>
                    </div>
                </div>
                <div className="form-group">
                    <Switch
                        label="Lukittu"
                        info="Uusia pisteitä ei voi luoda, kun piste on lukittu. Vanhemmat pisteet näkyvät kuitenkin kartalla."
                        bold={true}
                        selected={!!isLocked}
                        onChange={(selected) => {
                            this.setState({isLocked: selected})
                        }}
                    />
                </div>
                <div className="form-group">
                    <Container id="newLocationPointTypeFieldsCard" title="Kentät" tier={2} small={true}>

                        { fieldCards.map((component, i) => { return component }) }

                        <br/>
                        <EditLocationPointTypeFieldContainer locationPointTypeField={selectedField} onCancel={()=>{this.setState({selectedFieldId: false})}} onSave={this.saveField}/>

                    </Container>
                </div>
            </div>
        )

	}


    static propTypes = {
        locationPointType: PropTypes.object.isRequired,
        saveLocationPointCallback: PropTypes.func
    }

}

import { SET_SELECTED_PROJECT } from '../actions/types';
import { RESET_APP_STATE } from '../actions/types';

const initialState = null


export default (state = initialState, action = {}) => {
	switch (action.type) {

		case SET_SELECTED_PROJECT:
			return action.project

		case RESET_APP_STATE:
			return null

		default:
			return state

	}
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signUp } from '../../services/authService';
import Input from '../../lib/input/input';
import Button from '../../lib/button/button';
import './page-sign-up.css'
import { ROUTE_LOGIN } from '../../utils/RouteUtils';


export class SignUpPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			firstName: '',
			lastName: '',
			phone: '',
			email: '',
			password: '',
			verifyPassword: '',
			errors: this.noErrorsObj(),
			isLoading: false,
			changePassword: true,
		}

		this.onChange = this.onChange.bind(this);
		this.onSignUp = this.onSignUp.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	noErrorsObj() {
		return {
			message: null,
			input: {}
		}
	}

	onSignUp() {
		this.setState({
			isLoading: true,
			errors: this.noErrorsObj()
		})
		signUp(this.state)
			.catch(errors => {
				this.setState({
					isLoading: false,
					errors: errors
				})
			})
	}

	render() {

		const { firstName, lastName, phone, email, password, verifyPassword, errors, isLoading, changePassword } = this.state;

		const inputErrors = errors.input
		const errorMessage = errors.message

		return (
			<div id="signUpForm">
				<h2 id="signUpTitle">Rekisteröidy</h2>

				<Input
					title="Etunimi"
					name="firstName"
					value={firstName}
					onChange={this.onChange}
					disabled={isLoading}
					error={!!inputErrors.firstName}/>
				<Input
					title="Sukunimi"
					name="lastName"
					value={lastName}
					onChange={this.onChange}
					disabled={isLoading}
					error={!!inputErrors.lastName}/>
				<Input
					title="Puhelinnumero"
					name="phone"
					value={phone}
					onChange={this.onChange}
					type="number"
					disabled={isLoading}/>
				<Input
					title="Sähköposti"
					name="email"
					type="email"
					value={email}
					onChange={this.onChange}
					disabled={isLoading}
					errorMessage={inputErrors.email}/>
				<Input
					title="Salasana"
					name="password"
					value={password}
					onChange={this.onChange}
					disabled={isLoading}
					type="password"
					errorMessage={inputErrors.password}/>
				<Input
					title="Vahvista salasana"
					name="verifyPassword"
					value={verifyPassword}
					onChange={this.onChange}
					disabled={isLoading}
					type="password"
					errorMessage={inputErrors.verifyPassword}/>
				{ errorMessage && (<p id="errorMessage">{errorMessage}</p>)}
				<br/>
				<Button
					title="Rekisteröidy"
					float="right" minWidth="30%"
					disabled={isLoading}
					onClick={this.onSignUp}
				/>
				<Button
					title="Peruuta"
					type={Button.Type.SECONDARY}
					marginEnd="8px" float="right" minWidth="30%"
					disabled={isLoading}
					onClick={() => this.props.history.push(ROUTE_LOGIN) }
				/>
			</div>
		)
  	}

}

export default connect(null)(SignUpPage);
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCompanyInfo } from '../../actions/companyInfosActions';
import { ReactComponent as InfoCircleIcon } from '../../assets/info.svg';
import BorderlessButton from '../../lib/borderless-button/borderless-button';
import Container from '../../lib/container';
import Dialog from '../../lib/dialog';
import LoadingSpinner from '../../lib/loading-spinner';
import Company from '../../models/Company';
import './container-month-analytics.css';


class MonthAnalyticsContainer extends Component {

	constructor(props) {
		super(props)

		const date = new Date()
		const beginDate = this.props.beginMonth ? new Date(this.props.beginMonth.getFullYear(), this.props.beginMonth.getMonth(), 1) : new Date(date.getFullYear(), date.getMonth(), 1)
		const lastDayOfMonth = new Date(beginDate.getFullYear(), beginDate.getMonth() + 1, 0, 23, 59, 59, 999)
		const endDate = (lastDayOfMonth.getFullYear() === date.getFullYear() && lastDayOfMonth.getMonth() === date.getMonth()) ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999) : lastDayOfMonth

		this.state = {
			analyticsLoaded: false,
			analyticLocationPointsCount: null,
			analyticLocationPointsPerAuthorCount: {},
			projectsCreated: 0,
			showInfoDialog: false,
			beginDate,
			endDate
		}

		this.mounted = true

		this.loadAnalytics(props)
   }

   // componentDidUpdate(prevprops, prevState) {
   //    console.log("Did update: this.state.showInfoDialog: " + this.state.showInfoDialog)
   //    console.log("prevState: " + prevState.showInfoDialog)
   // }

	componentWillReceiveProps(props) {
		this.setState({
			analyticsLoaded: false,
			analyticLocationPoints: [],
			projectsCreated: 0,
		})
		this.loadAnalytics(props)
	}

	loadAnalytics(props) {
		const { beginDate, endDate } = this.state
		const { company, isAdministrator } = props

		var locationPointsCountLoaded = false
		var projectCountLoaded = false
		var projectsCreated = 0
		var locationPoints
		const complete = () => {
			if (locationPointsCountLoaded && projectCountLoaded && this.mounted) {

				const authorLocPointCount = {}
				const onComplete = () => {
					for (const locPoint of locationPoints) {
						const author = (locPoint.authorBusinessId ? locPoint.authorBusinessId : -1)
						if (author === -1 && !authorLocPointCount[author]) authorLocPointCount[author] = {
							name: 'Tuntematon',
							count: 0
						}
						++authorLocPointCount[author].count
					}
					this.setState({
						analyticsLoaded: true,
						analyticLocationPointsCount: locationPoints.length,
						analyticLocationPointsPerAuthorCount: authorLocPointCount,
						projectsCreated
					})
				}

				const authors = []
				for (const locPoint of locationPoints) {
					if (locPoint.authorBusinessId) {
						if (authors.indexOf() === -1) {
							authors.push(locPoint.authorBusinessId)
						}
					}
				}
				var authorsToLoad = authors.length
				if (authorsToLoad > 0) {
					for (const businessId of authors) {
						this.getCompanyInfo(businessId, (companyInfo) => {
							authorLocPointCount[companyInfo.businessId] = {
								name: companyInfo.name,
								count: 0
							}
							if (--authorsToLoad === 0) onComplete()
						})
					}
				} else {
					onComplete()
				}
			}
		}
		if (isAdministrator) {
			company.getProjectAnalytics(beginDate.getTime(), endDate.getTime()).then((analyticProjects) => {
				projectsCreated = analyticProjects.length
				projectCountLoaded = true
				complete()
			})
		} else {
			for (const project of company.analyticProjects) {
				if (project.dateCreated >= beginDate.getTime() && project.dateCreated <= endDate.getTime()) {
					++projectsCreated
				}
			}
			projectCountLoaded = true
		}
		company.getLocationPointAnalytics(beginDate.getTime(), endDate.getTime()).then((locPoints) => {
			locationPoints = locPoints
			locationPointsCountLoaded = true
			complete()
		})
	}

	getCompanyInfo(businessId, callback) {
		if (this.props.companyInfos[businessId]) {
			callback(this.props.companyInfos[businessId])
		} else {
			Company.getInfo(businessId).then((companyInfo) => {
				if (!this.mounted) return
				callback(companyInfo)
				this.props.setCompanyInfo(businessId, companyInfo)
			})
		}
	}

	componentWillUnmount() {
		this.mounted = false
	}

	render() {

		const { analyticsLoaded, analyticLocationPointsCount, analyticLocationPointsPerAuthorCount, projectsCreated, beginDate, endDate, showInfoDialog } = this.state

		const options = {
			// day: 'numeric',
			month: 'long',
			year: 'numeric'
		}

		const beginDateString = beginDate.toLocaleDateString("fi-FI", options)
		const endDateString = endDate.toLocaleDateString("fi-FI", options)

		const title = (beginDate.getFullYear() === endDate.getFullYear() && beginDate.getMonth() === endDate.getMonth()) ? beginDateString : beginDateString + ' - ' + endDateString

		return (
			<Container id={this.props.id} className='month-analytics-container' title={title} onClose={this.props.onClose} tier={3}>
				{
					!analyticsLoaded ? <LoadingSpinner/>
					:
					<span>
						<p className="inline-block">Pisteitä luotu yhteensä: {analyticLocationPointsCount}</p>
						{(analyticLocationPointsCount > 0) &&
							<BorderlessButton
								className="info-btn"
								onClick={()=>{ this.setState({showInfoDialog: true}) }}
								icon={<InfoCircleIcon className="icon-medium" />} type={BorderlessButton.Type.PRIMARY}
								tier={2}
								shadowType={BorderlessButton.ShadowType.NONE}/>
							}
						<p>Projekteja luotu: {projectsCreated}</p>
					</span>
				}
				{/* <p className="period-title">{ "Laskutus ajalta:"}</p> */}
				{/* <p className="period-date">{'(' + beginDateString + ' - '}</p> */}
				{/* <p className="period-date">{ endDateString }</p> */}
				<Dialog
					title="Lisätietoa"
					neutralButtonText="OK"
					show={showInfoDialog}
					onClose={() => this.setState({ showInfoDialog: false }) }
				>
					<table className="table table-striped">
						<thead>
							<tr>
								<th scope="col">Yritys</th>
								<th scope="col">Luodut pisteet</th>
							</tr>
						</thead>
						<tbody>
							{
								Object.keys(analyticLocationPointsPerAuthorCount).map((id) => {
									return (
										<tr key={id} scope="col">
											<td>{analyticLocationPointsPerAuthorCount[id].name}</td>
											<td>{analyticLocationPointsPerAuthorCount[id].count}</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				</Dialog>
			</Container>
		)
	}

}

MonthAnalyticsContainer.propTypes = {
	onClose: PropTypes.func.isRequired,
	beginMonth: PropTypes.object,
	endMonth: PropTypes.object
}

/* MonthAnalyticsContainer.defaultProps = {
	endDate:
}; */


function mapStateToProps(state) {
	return {
		isAdministrator: state.auth.user.isAdministrator,
		company: state.selectedCompany,
		companyInfos: state.companyInfos.infos
	}
}

export default connect(mapStateToProps, { setCompanyInfo })(MonthAnalyticsContainer);

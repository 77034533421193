import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCompany, setProject } from '../../actions/appStateActions';
import { setCompanyInfo } from '../../actions/companyInfosActions';
import { setUserInfo } from '../../actions/userInfosActions';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import Button from '../../lib/button';
import Container from '../../lib/container';
import Dialog from '../../lib/dialog';
import { showConfirmDialog, showDangerConfirmDialog, showMessageDialog } from '../../lib/dialog-generator/dialog-generator';
import { getItemByPropertysValue, millisecondsToDateString, openUrlInNewTab, sortByProperty, trimStartAndEnd } from '../../lib/Helper';
import Input from '../../lib/input/input';
import LoadingSpinner from '../../lib/loading-spinner';
import SelectableCard from '../../lib/selectable-card';
import Company from '../../models/Company';
import Project from '../../models/Project';
import UserInfo from '../../models/UserInfo';
import { ROUTE_EDIT_PROJECT, ROUTE_MAP } from '../../utils/RouteUtils';
import ContainerProjectAnalytics from '../container-project-analytics/container-project-analytics';
import './container-project.css';

class ProjectContainer extends Component {

	constructor(props) {
		super(props)

		this.state = {
			projectLoaded: false,
			loadingProject: false,
			loadingAuthorInfo: false,
			authorInfoLoaded: false,
			loadingInvitedCompanies: false,
			invitedCompaniesLoaded: false,
			invitedCompanies: [],
			loadingFiles: false,
			filesLoaded: false,
			authorInfo: null,
			showInviteCompanyDialog: false,
			errorInviteCompanyInput: false,
			inviteBusinessId: '',
			invitingCompany: false,
			kickingCompany: false,
			deletingProject: false,
			showFileUploadProgressDialog: false,
			showGiveFileNameDialog: false,
			fileToUplod: null,
			fileUploadProgressPercentage: 0,
			fileName: '',
			deletingFile: false
		}

		this.mounted = true

		this.onChange = this.onChange.bind(this)
		this.fileClicked = this.fileClicked.bind(this)
		this.loadAuthorInfo = this.loadAuthorInfo.bind(this)
		this.uploadFile = this.uploadFile.bind(this)
		this.getCompanyInfo = this.getCompanyInfo.bind(this)
		this.kickCompanyOut = this.kickCompanyOut.bind(this)
		this.showInviteCompanyDialog = this.showInviteCompanyDialog.bind(this)
		this.inviteCompany = this.inviteCompany.bind(this)
		this.deleteProject = this.deleteProject.bind(this)
		this.leaveProject = this.leaveProject.bind(this)
		this.prepareToUploadFile = this.prepareToUploadFile.bind(this)
		this.deleteFile = this.deleteFile.bind(this)
		this.editProject = this.editProject.bind(this)
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
    }

	editProject() {
		this.props.history.push(ROUTE_EDIT_PROJECT)
	}

	fileClicked(id) {
		for (const file of this.props.project.files) {
			// if (file.id === id) return saveFile(file.url, file.name)
			if (file.id === id) openUrlInNewTab(file.url)
		}
	}

	prepareToUploadFile(file) {
		this.setState({
			fileToUplod: file,
			showGiveFileNameDialog: true
		})
	}

	uploadFile() {
		const { fileToUplod, fileName } = this.state
		var trimmedFileName = trimStartAndEnd(fileName)

		if (!trimmedFileName) trimmedFileName = fileToUplod.name
		else {
			const fileEnding = fileToUplod.name.split('.').pop()
			if (fileEnding !== trimmedFileName) trimmedFileName += "."+fileEnding
		}
		this.setState({
			showFileUploadProgressDialog: true,
			fileUploadProgressPercentage: 0,
		})

		this.props.project.uploadFile(fileToUplod, trimmedFileName, (percentage) => {
			this.setState({fileUploadProgressPercentage: percentage})
		}, (success) => {
			this.setState({
				fileName: '',
				fileToUplod: null,
				showFileUploadProgressDialog: false,
			})
			if (!success) {
				showMessageDialog(null, "Jotain meni vikaan, yritä myöhemmin uudelleen.", "OK")
			}
		})
	}

	deleteFile(fileId) {
		const { project } = this.props
		showConfirmDialog(
			getItemByPropertysValue(project.files, "id", fileId).name,
			"Haluatko varmasti poistaa tämän tiedoston?",
			"Poista",
			"Peruuta",
			() => {
				this.setState({deletingFile: true})
				project.deleteFile(fileId, () => {
					const remainingFiles = []
					for (const file of project.files) {
						if (file.id !== fileId) remainingFiles.push(file)
					}
					project.files = remainingFiles
					this.setState({deletingFile: false})
				})
			}
		)
	}

	showInviteCompanyDialog() {
		this.setState({
			showInviteCompanyDialog: true,
			errorInviteCompanyInput: false
		})
	}

	inviteCompany() {
		const trimmedBusinessId = trimStartAndEnd(this.state.inviteBusinessId)
		if (!trimmedBusinessId) {
			return this.setState({errorInviteCompanyInput: true})
		}
		this.setState({
			errorInviteCompanyInput: false,
			invitingCompany: true
		})
		const err = () => {
			this.setState({
				errorInviteCompanyInput: true,
				invitingCompany: false
			})
		}
		for (const company of this.state.invitedCompanies) {
			if (company.businessId === trimmedBusinessId) {
				return err()
			}
		}
		Company.getInfo(trimmedBusinessId).then((info) => {
			this.props.project.inviteCompany(trimmedBusinessId, (success) => {
				if (!this.mounted) return
				if (success) {
					this.state.invitedCompanies.push(info)
					this.setState({
						invitingCompany: false,
						inviteBusinessId: '',
						showInviteCompanyDialog: false
					})
				} else err()
			})
		}).catch(err)

	}

	kickCompanyOut(businessId) {
		this.setState({kickingCompany: businessId})
		this.props.project.kickCompany(businessId, (success) => {
			if (success) {
				const remainingInvitedCompanies = []
				for (const company of this.state.invitedCompanies) {
					if (company.businessId !== businessId) remainingInvitedCompanies.push(businessId)
				}
				this.setState({
					kickingCompany: false,
					invitedCompanies: remainingInvitedCompanies
				})
			} else {
				this.setState({kickingCompany: false})
			}
		})
	}

	leaveProject() {
		const { isAdministrator, company} = this.props
		showDangerConfirmDialog( null,
			(isAdministrator ?
				`Oletko varma että ${company.info.name} tahtoo poistua tästä projektista?`
				: "Tahtooko yrityksesi varmasti poistua tästä projektista?"
			),
			"KYLLÄ",
			"Peruuta",
			() => {
				const { company, project } = this.props
				company.leaveFromProject(project.id, (success) => {
					if (success) {
						this.props.setProject(null)
						this.props.history.replace('/')
					}
					else showMessageDialog(null, "Jotain meni vikaan, yritä myöhemmin uudelleen.", "OK")
				})
			}
		)
	}

	deleteProject() {
		showDangerConfirmDialog( null,
			"Haluatko varmasti poistaa projektin?",
			"POISTA",
			"Peruuta",
			() => {
				const { company, project } = this.props
				this.setState({deletingProject: true})
				company.deleteProject(project.id, (success) => {
					this.setState({deletingProject: false})
					if (success) {
						this.props.setProject(null)
						this.props.history.replace('/')
					}
					else showMessageDialog(null, "Jotain meni vikaan, yritä myöhemmin uudelleen.", "OK")
				})
			}
		)
	}
	loadProjectData() {
		const { project } = this.props
		// console.log(project)
		if (project instanceof Project) {
			this.setState({projectLoaded: true, loadingProject: false})
			project.refreshData()
			/* project.loadInfoAndAnalyticsProject().then(() => {
				this.setState({projectLoaded: true, loadingProject: false})
			}) */
		} else {
			if (!project.removed) {
				project.getProjectInfo().then(() => {
					if (!this.mounted) return
					// this.loadAuthorInfo(project.info ? project.info.author : project.projectInfo.author)
					this.setState({projectLoaded: true, loadingProject: false})
				})
			} else this.setState({projectLoaded: true, loadingProject: false})
		}
	}

	loadAuthorInfo(authorId) {
		if (this.props.userInfos[authorId]) {
			return this.setState({authorInfoLoaded: true, authorInfo: this.props.userInfos[authorId]})
		}
		UserInfo.getInfo(authorId).then((userInfo) => {
			this.props.setUserInfo(authorId, userInfo)
			if (!this.mounted) return
			this.setState({authorInfoLoaded: true, authorInfo: userInfo})
		})
	}

	getCompanyInfo(businessId, callback) {
		if (this.props.companyInfos[businessId]) {
			callback(this.props.companyInfos[businessId])
		} else {
			Company.getInfo(businessId).then((companyInfo) => {
				if (!this.mounted) return
				callback(companyInfo)
				this.props.setCompanyInfo(businessId, companyInfo)
			})
		}
	}

	componentWillUpdate(nextProps, nextState) {
		if (nextProps.project !== this.props.project) {
			this.setState({
				projectLoaded: false,
				loadingProject: false,
				loadingAuthorInfo: false,
				authorInfoLoaded: false,
				authorInfo: null,
				companyInfo: null,
				companyInfoLoaded: null,
				loadingCompanyInfo: null,
				loadingInvitedCompanies: false,
				invitedCompaniesLoaded: false,
				invitedCompanies: [],
				loadingFiles: false,
				filesLoaded: false,
				showInviteCompanyDialog: false,
				errorInviteCompanyInput: false,
				inviteBusinessId: '',
				invitingCompany: false,
				kickingCompany: false,
				deletingProject: false,
				showFileUploadProgressDialog: false,
				showGiveFileNameDialog: false,
				fileToUplod: null,
				fileUploadProgressPercentage: 0,
				fileName: '',
				deletingFile: false,
			})
		}
	}

	componentDidMount() {
		this.loadProjectData()
	}

	componentDidUpdate(prevProps, prevState) {
		const { project, isAdministrator } = this.props
		if (project && prevProps.project === project) {

			const { projectLoaded, loadingProject } = this.state
			if (!projectLoaded && !loadingProject) {
				this.setState({loadingProject: true})
				this.loadProjectData()
			} else if (projectLoaded) {
				if (!project.removed) {
					// if (!isAdministrator) {
						if (!this.state.filesLoaded && !this.state.loadingFiles) {
							var filesToLoad = project.files.length
							if (filesToLoad !== 0) this.setState({loadingFiles: true})
							else this.setState({filesLoaded: true})
							// else this.setState({filesLoaded: true})
							for (const file of project.files) {
								file.loadData(project.id).then(() => {
									if (--filesToLoad === 0) {
										if (!this.mounted) return
										this.setState({filesLoaded: true})
									}
								})
							}
						}
						if (!this.state.invitedCompaniesLoaded && !this.state.loadingInvitedCompanies) {
							this.setState({loadingInvitedCompanies: true})
							project.getInvitedCompanies((companies) => {
								var companiesToGet = companies.length
								if (companiesToGet === 0) {
									this.setState({
										loadingInvitedCompanies: false,
										invitedCompaniesLoaded: true
									})
								}
								const companyInfos = []
								for (const businessId of companies) {
									this.getCompanyInfo(businessId, (companyInfo) => {
										companyInfos.push(companyInfo)
										if (--companiesToGet === 0) {
											sortByProperty(companyInfos, 'name')
											this.setState({
												invitedCompanies: companyInfos,
												loadingInvitedCompanies: false,
												invitedCompaniesLoaded: true
											})
										}
									})
								}
							})
						}
					// }
					if (!this.state.companyInfoLoaded && !this.state.loadingCompanyInfo) {
						const isAnalyticsProject = !(project instanceof Project)
						const info = !isAnalyticsProject ? project.info : project.projectInfo
						this.setState({loadingCompanyInfo: true})
						this.getCompanyInfo(info.businessId, (companyInfo) => {
							this.setState({
								companyInfoLoaded: true,
								companyInfo
							})
						})
					}
					if (!this.state.authorInfoLoaded && !this.state.loadingAuthorInfo) {
						this.setState({loadingAuthorInfo: true})
						const isAnalyticsProject = !(project instanceof Project)
						const info = !isAnalyticsProject ? project.info : project.projectInfo
						if (info) this.loadAuthorInfo(info.author)
					}
				}
			}

		}

	}

	componentWillUnmount() {
		this.mounted = false
	}

	render() {

		const { project, isAdministrator } = this.props
		const { projectLoaded, authorInfoLoaded, authorInfo, companyInfo, inviteBusinessId, filesLoaded, invitedCompanies, invitedCompaniesLoaded, companyInfoLoaded, showInviteCompanyDialog,
			errorInviteCompanyInput, invitingCompany, kickingCompany, deletingProject, fileName, showFileUploadProgressDialog, showGiveFileNameDialog, deletingFile } = this.state

		if (!project) return null
		const isAnalyticsProject = !(project instanceof Project)

		if (!projectLoaded || deletingProject) {
			return <LoadingSpinner/>
		}

		const loading = companyInfoLoaded && invitedCompaniesLoaded && filesLoaded

		const info = !isAnalyticsProject ? project.info : !project.removed ? project.projectInfo : project
		if (!info) return null

		var filesCard = null
		var invitedCompaniesCard = null
		if (!isAnalyticsProject) {
			const fileCards = []
			if (project.files.length !== 0) {
				if (filesLoaded) {
					for (const file of project.files) {
                  if (!file.url || !file.name) continue
						fileCards.push(
							<SelectableCard
								key={file.id}
								id={file.id}
								text={file.name}
								disabled={deletingFile}
								onDelete={this.deleteFile}
								onSelect={this.fileClicked}
								tier={2}/>
						)
					}
				}
			}
			filesCard =
				<Container className="files-card" title="Tiedostot" tier={3}>
					{ filesLoaded ? fileCards : null }
					{ filesLoaded ? <SelectableCard key={"newFile"} leftIcon={<PlusIcon className="icon-small" />} text="Uusi Tiedosto" highlighted={true} type={SelectableCard.Type.FILE_UPLOAD} maxFileSize={30} onFileUpload={this.prepareToUploadFile} tier={2}/> : null }
					{ !filesLoaded || deletingFile ? <LoadingSpinner/> : null }
				</Container>

			if (project.isOwnProject) {
				const invitedCompanyCards = []
				if (invitedCompaniesLoaded) {
					invitedCompanies.map((invitedCompany) => {
						invitedCompanyCards.push(
							<SelectableCard
								key={invitedCompany.businessId}
								id={invitedCompany.businessId}
								text={invitedCompany.name}
								tier={2}
								deleteText="POISTA"
								disabled={kickingCompany === invitedCompany.businessId}
								onDelete={this.kickCompanyOut}/>
						)
					})
				}
				invitedCompaniesCard =
					<Container className="invited-companies-card" title="Projektissa mukana olevat yritykset" tier={3}>
						{ invitedCompaniesLoaded ? invitedCompanyCards : <LoadingSpinner/> }
						{ invitedCompaniesLoaded ? <SelectableCard key={"newFile"} leftIcon={<PlusIcon className="icon-small" />} text="Kutsu yritys" highlighted={true} onSelect={this.showInviteCompanyDialog} tier={2}/> : null }
					</Container>
			}

		}


		return (
			<span>
				<Container className='project-container' tier={2} title={ project.info ? project.info.name : project.name } subTitle={ info.description && info.description }>
					{ !isAdministrator && !project.isOwnProject && <p className="text">Yritys: { companyInfo && companyInfo.name }</p> }
					<p className="text">Luotu: { millisecondsToDateString(info.dateCreated) }</p>
					{ !project.removed && <p className="text">Luoja: { authorInfo && authorInfo.firstName + " " + authorInfo.lastName }</p> }
					{ project.removed && <p className="text">Poistettu: { millisecondsToDateString(project.removed) }</p> }
					{ filesCard }
					{ invitedCompaniesCard && <br/> }
					{ invitedCompaniesCard }
					<br/>
					{ !project.isOwnProject && <Button title="Poistu projektista" type={Button.Type.WARNING} style={{ marginLeft: '16px' }} minWidth="20%" disabled={!loading} onClick={this.leaveProject}/> }
					{ project.isOwnProject && <Button title="Poista Projekti" type={Button.Type.DANGER} float="right" style={{ marginLeft: '16px' }} minWidth="20%" disabled={!loading} onClick={this.deleteProject}/> }
					{ project.isOwnProject && <Button title="Muokkaa Projektia" style={{ marginLeft: '16px' }} float="right" minWidth="20%" disabled={!loading} onClick={this.editProject}/> }
					<Button title="Kartta" float="right" minWidth="124px" disabled={!loading} onClick={() => this.props.history.push(ROUTE_MAP)}/>
					<Dialog
						title="Kutsu toinen yritys liittymään projektiin."
						neutralButtonText="Kutsu"
						negativeButtonText="Peruuta"
						closeOnNeutralButtonClick={false}
						onNeutralButtonClick={this.inviteCompany}
						disabled={invitingCompany}
						show={showInviteCompanyDialog}
						onClose={() =>
							this.setState({
								inviteBusinessId: '',
								showInviteCompanyDialog: false
							})
						}
					>
						<Input
							title="Y-tunnus"
							name="inviteBusinessId"
							value={inviteBusinessId}
							onChange={this.onChange}
							autoComplete="off"
							errorMessage={errorInviteCompanyInput ? "Tarkasta Y-tunnus" : null}
							disabled={invitingCompany}/>
					</Dialog>
					<Dialog
						title="Lähetetään tiedostoa"
						show={showFileUploadProgressDialog}
						// onClose={() => {this.setState({showFileUploadProgressDialog: false})}}
						showExitButton={false}
					>
						<div className="progress" style={{height: "24px"}}>
							<div className="progress-bar bg-success" role="progressbar" style={{width:`${this.state.fileUploadProgressPercentage}%`}}></div>
						</div>
					</Dialog>
					<Dialog
						title="Anna tiedostolle nimi."
						neutralButtonText="Tallenna tiedosto"
						negativeButtonText="Peruuta"
						onNeutralButtonClick={this.uploadFile}
						show={showGiveFileNameDialog}
						onClose={() =>
							this.setState({
								showGiveFileNameDialog: false,
								fileToUplod: null,
								fileName: ''
							})
						}
					>
						<div className="form-group">
							<input
								name="fileName"
								value={fileName}
								onChange={this.onChange}
								className="form-control"
								type="text"
								autoComplete="off"/>
						</div>
					</Dialog>
				</Container>
				<br/>
				<ContainerProjectAnalytics />
				<br/>
			</span>
		)


	}

}

function mapStateToProps(state) {
	return {
		project: state.selectedProject,
		company: state.selectedCompany,
		userInfos: state.userInfos.infos,
		companyInfos: state.companyInfos.infos,
		isAdministrator: state.auth.user.isAdministrator,
	}
}

export default withRouter(connect(mapStateToProps, { setUserInfo, setCompanyInfo, setCompany, setProject })(ProjectContainer))

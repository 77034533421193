import React from 'react';
import {
  getIconColor
} from "../../lib/Helper";
import LocationPointType from '../../models/LocationPointType';
import './NewPointInfo.scss'

type NewPointInfoProps = {
  types: Record<string, LocationPointType>
  onTypeSelected: (id: string) => void
}

const NewPointInfo: React.FC<NewPointInfoProps> = ({ types, onTypeSelected }) => {
  return (
    <div>
      {!!types &&
        Object.values(types).map((type) => {
          if (type.isLocked) return null;
          return (
            <div
              key={type.id}
              className="new-marker-row"
              onClick={() => onTypeSelected(type.id)}
            >
              <div
                className="marker"
                style={{ backgroundColor: getIconColor(type.iconId) }}
              />
              <p>{type.iconId === 0 ? "Huomio" : type.name}</p>
              <div style={{ flexGrow: 1 }} />
            </div>
          )
        })}
    </div>
  );
};

export default NewPointInfo

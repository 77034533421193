import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './borderless-button.css';
import classNames from 'classnames'


export default class BorderlessButton extends Component {

	render() {
		const { id, className, title, icon, tier, type, shadowType, onClick, disabled, bold, marginRight, fontSize } = this.props
		var { style } = this.props
		if (!style) style = {}
		if (marginRight) style.marginRight = marginRight

		return (
			<div
				id={id}
				className={classNames("borderless-button", {[className]: className})}
				onClick={onClick}
				data-tier={tier}
				data-type={type}
				data-shadow-type={shadowType}
				data-disabled={disabled}
				data-bold={bold}
				style={style}
				>
			{
			<>
				{ title && <p className="title" style={ { paddingRight: icon ? '8px' : 0, fontSize: fontSize  } }>{title}</p> }
				{ icon && icon }
			</>
			}
			</div>
		)
	}

	static Type = Object.freeze({
		DEFAULT: "default",
		PRIMARY: "primary",
		DANGER: "danger",
		WHITE: "white",
	})

	static ShadowType = Object.freeze({
		NONE: "none",
		MATERIAL: "material",
		TEXT_SHADOW: "text_shadow"
	})

	static propTypes = {
		id: PropTypes.string,
		title: PropTypes.string,
		icon: PropTypes.any,
		onClick: PropTypes.func.isRequired,
		tier: PropTypes.number,
		type: PropTypes.string,
		shadowType: PropTypes.string,
		marginRight: PropTypes.string,
		disabled: PropTypes.bool,
		bold: PropTypes.bool,
		style: PropTypes.object,
	}

	static defaultProps = {
		tier: 1,
		type: BorderlessButton.Type.DEFAULT,
		shadowType: BorderlessButton.ShadowType.MATERIAL,
		disabled: false,
		bold: false,
	}

}

import "bootstrap/dist/css/bootstrap.min.css";
import firebase from "firebase";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import App from "./App";
import "./index.css";
import rootReducer from "./reducers/rootReducer";
import { unregister } from "./registerServiceWorker";

// Initialize Bootstrap
window.$ = window.jQuery = require("jquery");
window.Tether = require("tether");
window.Popper = require("popper.js");
require("bootstrap"); // Needed only for Bootstrap JavaScript features

// Initialize Firebase
var config = {
  apiKey: "AIzaSyDmZRGSpcEBdxskpcotASFEjIWDEH_83fU",
  authDomain: "tolppa-76c90.firebaseapp.com",
  databaseURL: "https://tolppa-76c90.firebaseio.com",
  projectId: "tolppa-76c90",
  storageBucket: "tolppa-76c90.appspot.com",
  messagingSenderId: "886376625792",
};
firebase.initializeApp(config);

// Initialize App
// const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

unregister();

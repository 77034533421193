import React, { Component } from 'react';
import { guid } from '../Helper';
import PropTypes from 'prop-types';
import './switch.css';
const $ = require('jquery');


export default class Switch extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id: guid(),
            selected: props.selected
        }
        this.switchElement = null
    }

    componentWillReceiveProps(props) {
        this.setState({
            selected: props.selected
        })
    }

	render() {

        const { label, info, bold, onChange, disabled, noPadding } = this.props
        const { id, selected } = this.state

        return (
            <div className="material-switch" data-no-padding={noPadding} onClick={() => {this.switchElement.click()}} disabled={disabled}>
                <div>
                    { bold ? <b>{label}</b> : label }
                    <div className="switch pull-right">
                        <input id={id}
                            ref={input => this.switchElement = input}
                            type="checkbox"
                            onChange={(e) => {
                                if (onChange) onChange(!selected)
                                this.setState({ selected: !selected })
                            }}
                            checked={selected}/>
                        <label htmlFor={id} className="label-primary"/>
                    </div>
                </div>
                { info ? <small className="text-muted">{info}</small> : null }
            </div>
        )

	}


    static propTypes = {
        label: PropTypes.string,
        info: PropTypes.string,
        bold: PropTypes.bool,
        disabled: PropTypes.bool,
        selected: PropTypes.bool,
        onChange: PropTypes.func,
        noPadding: PropTypes.bool
    }

    static defaultProps = {
        selected: false,
        bold: false,
        disabled: false,
        label: ''
    }

}
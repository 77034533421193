import React, { Component } from 'react';
import './loading-spinner.css';


export default class LoadingSpinner extends Component {

	render() {
		const {small} = this.props
		if (small) return <div className="loading-spinner-small"><br/><div className="spinner-small"/></div>
		return <div className="loading-spinner"><br/><div className="spinner"/></div>
	}

}
import { guid } from '../../lib/Helper';
import './dialog.css';
const $ = require('jquery');

export function showConfirmDialog(title, message, confirmButtonText, cancelButtonText, onConfirm, onCancel) {
    _showDialog(title, message, null, confirmButtonText, cancelButtonText, null, onConfirm, onCancel)
}

export function showDangerConfirmDialog(title, message, confirmButtonText, cancelButtonText, onConfirm, onCancel) {
    _showDialog(title, message, confirmButtonText, cancelButtonText, null, onConfirm, onCancel, null, true, true, false)
}

export function showMessageDialog(title, message, confirmButtonText) {
    _showDialog(title, message, null, confirmButtonText, null, null, null, null)
}

function _showDialog(title, message, positiveButtonText, neutralButtonText, negativeButtonText, onPositiveButtonPressed, onNeutralButtonPressed, onNegativeButtonPressed, showExitButton = true, primaryButtonNegative = false, grayNegativeButton = true) {

    const id = guid()

    var htmlString = ''
    htmlString +=
            `<div id=${id} class="dialog-generator modal fade" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">`
    htmlString +=   (title || showExitButton) ?
                        `<div class="modal-header">
                            <h5 class="modal-title">${title ? title : ''}</h5>` : ''
    htmlString +=       (showExitButton) ?
                            `<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>` : ''
    htmlString +=   (title || showExitButton) ?
                        `</div>` : ''

    htmlString +=  `<div class="modal-body">`
/*     htmlString += (!title && showExitButton) ?
                        `<button type="button" class="close" data-dismiss="modal">&times;</button>` : '' */
    htmlString +=       `<p>${message}</p>
                    </div>
                    <div class="modal-footer">`
    htmlString += (negativeButtonText) ? `<button type="button" class="btn btn-negative  ` + (primaryButtonNegative ? "btn-success" : (!grayNegativeButton ? "btn-danger" : "btn-default")) + `" data-dismiss="modal">${negativeButtonText}</button>` : ''
    htmlString += (neutralButtonText) ? `<button type="button" class="btn btn-neutral btn-primary" data-dismiss="modal">${neutralButtonText}</button>` : ''
    htmlString += (positiveButtonText) ? `<button type="button" class="btn btn-positive ` + (primaryButtonNegative ? (!grayNegativeButton ? "btn-danger" : "btn-default") : "btn-success") + `" data-dismiss="modal">${positiveButtonText}</button>` : ''
    htmlString +=  `</div>
                </div>
            </div>
        </div>`

    const span = document.createElement('span')
    span.innerHTML = htmlString.trim()
    document.body.appendChild(span)

    const dialog = $(`#${id}`)

    dialog.on("show.bs.modal", function() {    // wire up the OK button to dismiss the modal when shown
        $(`#${id} .modal-footer > .btn-positive`).on("click", function(e) {
            if (onPositiveButtonPressed) onPositiveButtonPressed()
        })
        $(`#${id} .modal-footer > .btn-neutral`).on("click", function(e) {
            if (onNeutralButtonPressed) onNeutralButtonPressed()            
        })
        $(`#${id} .modal-footer > .btn-negative`).on("click", function(e) {
            if (onNegativeButtonPressed) onNegativeButtonPressed()            
        })
    })
    dialog.on("hide.bs.modal", function() {
        $(`#${id} .modal-footer > .btn-positive`).off("click")
        $(`#${id} .modal-footer > .btn-neutral`).off("click")
        $(`#${id} .modal-footer > .btn-negative`).off("click")
    })
    
    dialog.on("hidden.bs.modal", function() {
        span.remove()
    })
    
    if (showExitButton) {
        dialog.modal({
            "keyboard" : true,
            "show"     : true
        })
    } else {
        dialog.modal({
            "backdrop" : "static",
            "keyboard" : false,
            "show"     : true
        })
    }
    

}
import * as firebase from 'firebase';

export const fService = {
    get SREF_BASE() { return firebase.storage().ref() },

    get FREF_BASE() { return firebase.database().ref(); },
    get FREF_ADMINISTRATORS() { return firebase.database().ref("administrators") },
    get FREF_ANALYTICS() { return firebase.database().ref("analytics") },
    get FREF_USERS() { return firebase.database().ref("users") },
    get FREF_COMPANY_SECURE() { return firebase.database().ref("company_secure") },
    get FREF_COMPANIES() { return firebase.database().ref("companies") },
    get FREF_PROJECTS() { return firebase.database().ref("projects") },

    get PATH_ANALYTICS() { return "analytics" },
    get PATH_INFO() { return "info" },
    get PATH_COMPANIES() { return "companies" },
    get PATH_PROJECTS() { return "projects" },
    get PATH_INVITED_COMPANIES() { return "invited_companies" },
    get PATH_LOCATION_POINTS() { return "location_points" },
    get PATH_LOCATION_POINT_TYPES() { return "location_point_types" },
    get PATH_LOCATION_POINT_DATA() { return "location_point_data" },
    get PATH_REMOVED_LOCATION_POINTS() { return "removed_location_points" },
    get PATH_REMOVED_LOCATION_POINT_DATA() { return "removed_location_point_data" },
    get PATH_LOCATION_POINT_IMAGES() { return "loc_point_images" },
    get PATH_USERS() { return "users" },
    get PATH_ADMINS() { return "admins" },
    get PATH_RULES() { return "rules" },
    get PATH_USERS() { return "users" },
    get PATH_IMAGES() { return "images" },
    get PATH_FILES() { return "files" },
    get PATH_DATA() { return "data" },
    get PATH_COMPANY_SECURE() { return "company_secure" },
    get PATH_COMPANY_JOIN_KEYS() { return "company_join_keys" },

	 get ServerValue() { return firebase.database.ServerValue },

    get uid() { return (firebase.auth().currentUser) ? firebase.auth().currentUser.uid : null }
}